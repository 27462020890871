import { Component } from 'react'
import { string, func, arrayOf, shape } from 'prop-types'
import CreatableSelect from 'react-select/creatable'

import './MultiValueInput.scss'

const components = {
  DropdownIndicator: null,
}

const createOption = label => ({
  label,
  value: label,
})

export default class MultiValueInput extends Component {
  static propTypes = {
    placeholder: string,
    label: string,
    onChange: func,
    value: arrayOf(shape({ label: string, value: string })),
  }

  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
    }
  }

  handleChange = value => {
    this.props.onChange(value)
  }
  handleInputChange = inputValue => {
    this.setState({ inputValue })
  }
  handleKeyDown = event => {
    const { inputValue } = this.state
    const { onChange, value } = this.props
    let newValue = value
    if ((event.key === 'Enter' || event.key === 'Tab') && inputValue.length) {
      newValue = [...value, createOption(inputValue)]
      this.setState({
        inputValue: '',
      })
      onChange(newValue)
      event.preventDefault()
    }
  }
  render() {
    const { inputValue } = this.state
    const { label, placeholder, value } = this.props
    return (
      <div className='multiValueInput'>
        {label && <label className='label'>{label}</label>}
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          placeholder={placeholder}
          value={value}
          classNamePrefix='multi-select'
        />
      </div>
    )
  }
}

import { useRef, useCallback } from 'react'

// calling the returned function will execute the callback on next tick
// calling the return function multiple times before the next tick will only execute the callback once on next tick
export function useNextTick(callback: (...args: any) => any) {
  const timeoutRef = useRef<NodeJS.Timeout>()

  const callCB = useCallback(
    (...args: any) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null
        callback(...args)
      }, 0)
    },
    [callback]
  )
  return callCB
}

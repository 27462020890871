/* eslint-disable import/no-mutable-exports */
import { proxify, MemoryStorage } from './memoryStorage'

let TingLocalStorage: Storage
try {
  TingLocalStorage = localStorage
} catch (err) {
  const instanceLocalStorage = new MemoryStorage()

  TingLocalStorage = proxify(instanceLocalStorage)
}
export { TingLocalStorage }

import { createAction } from 'redux-act'

export type HistoryStateMountPayload = { id: number; initialValue: any }
export const historyStateMount = createAction<HistoryStateMountPayload>('historyStateMount')

export type HistoryStateUnmountPayload = { id: number }
export const historyStateUnmount = createAction<HistoryStateUnmountPayload>('historyStateUnmount')

export type HistoryStatePopPayload = { id: number }
export const historyStatePop = createAction<HistoryStatePopPayload>('historyStatePop')

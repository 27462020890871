import { FC } from 'react'

import { QueryPages } from '@@ting/services/api/query-hooks'
import { VideoPreview, InfiniteList } from '@@ting/molecules'
import { IVideoDetails } from '@@ting/models'
import { useMobile } from '@@ting/utils/hooks'
import InfiniteVideoListSkeleton from './InfiniteVideoListSkeleton'

import './infiniteVideoList.scss'

const VideoPreviewItem = (video: IVideoDetails) => {
  const { id } = video
  return (
    <div className='preview-item' key={id}>
      <VideoPreview video={video} />
    </div>
  )
}

type InfiniteVideoListProps = {
  videos: QueryPages<IVideoDetails>
  isVideosLoading: boolean
  isFetchingNextPage: boolean
  videosHasNextPage: boolean
  fetchVideosNextPage: () => any
}

export const InfiniteVideoList: FC<InfiniteVideoListProps> = props => {
  const { videos, isVideosLoading, videosHasNextPage, isFetchingNextPage, fetchVideosNextPage } = props
  const isMobile = useMobile()
  return (
    <InfiniteList
      data={videos}
      isLoading={isVideosLoading}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={videosHasNextPage}
      LoadingComponent={<InfiniteVideoListSkeleton isMobile={isMobile} />}
      className='infinite-video__row'
      fetchNextPage={fetchVideosNextPage}
      itemRenderer={VideoPreviewItem}
    />
  )
}

import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { NavLink } from 'react-router-dom'
import i18next from 'i18next'
import { Popover, HrLine, UserAvatarWithRef, useSnackBar } from '@ting/ting-ui-components'

import { logOutSideEffect } from '@@ting/services/authorization/sideEffects'
import { Avatar } from '@@ting/models'
import { fileURL } from '@@ting/utils'
import './UserDropdown.scss'
import { useTranslation } from 'react-i18next'
import { useGetUserInfo } from '@@ting/services/api/query-hooks'
import Routes from '@@ting/enums/routes'
import routeGenerator from '@@ting/helpers'
import {
  ChannelsOutlinedIcon,
  InfoOutlinedIcon,
  LogOutOutlinedIcon,
  SettingsOutlinedIcon,
  WalletOutlinedIcon,
  WebOutlinedIcon,
} from '@ting/ting-ui-components/dist/icons'

type Props = {
  className?: string
  avatar?: Avatar
}

export const UserDropdown: FC<Props> = ({ avatar, className }) => {
  const dispatch = useDispatch()
  const showSnackBar = useSnackBar()
  const [isMounted, setIsMounted] = useState(false)
  const userAvatarRef = useRef()
  const { t } = useTranslation('common')
  const { data: user } = useGetUserInfo()

  // check how many render (One render only)
  const handleLogout = async () => {
    await logOutSideEffect(dispatch)
  }
  const handleLnguage = (lang: string) => {
    i18next.changeLanguage(lang).then(() => {
      showSnackBar({ message: t('successChangeLang') })
    })
  }

  useEffect(() => {
    setIsMounted(true)
  }, [])
  const userProps = { coreID: user?.corepassId, userName: user.username }
  const menuItems = [
    {
      type: 'link',
      title: 'profile',
      icon: <UserAvatarWithRef size='xs' image={avatar && fileURL(avatar.path)} alt='' {...userProps} />,
      link: routeGenerator(Routes.User.accounts, { name: user.username }),
      hasSeparator: false,
      hasNestedList: false,
    },
    {
      type: 'link',
      title: 'myChannels',
      icon: <ChannelsOutlinedIcon />,
      link: routeGenerator(Routes.User.accounts, { name: user.username }, { tab: 'channels' }),
      hasSeparator: false,
      hasNestedList: true,
    },
    {
      type: 'link',
      title: 'wallet',
      icon: <WalletOutlinedIcon />,
      link: Routes.Common.earnings,
      hasSeparator: true,
      hasNestedList: false,
    },
    {
      type: 'link',
      title: 'settings',
      icon: <SettingsOutlinedIcon />,
      link: Routes.User.settings,
      hasSeparator: false,
      hasNestedList: false,
    },
    {
      type: 'button',
      title: `language : ${t(i18next.language)}`,
      icon: <WebOutlinedIcon />,
      func: () => handleLnguage('en'),
      hasSeparator: false,
      hasNestedList: false,
    },
    {
      type: 'link',
      title: 'privacyPolicy',
      icon: <InfoOutlinedIcon />,
      link: routeGenerator(Routes.User.settings, null, {
        tab: 'privacy',
      }),
      hasSeparator: true,
      hasNestedList: false,
    },
    {
      type: 'button',
      title: 'logout',
      icon: <LogOutOutlinedIcon />,
      func: handleLogout,
      hasSeparator: false,
      hasNestedList: false,
    },
  ]
  return (
    <div className={cn('user-dropdown', className)}>
      <UserAvatarWithRef ref={userAvatarRef} size={32} image={avatar ? fileURL(avatar.path) : ''} {...userProps} />
      {isMounted && (
        <Popover
          arrow
          target={userAvatarRef.current}
          className='user-dropdown-popover'
          offset={[-60, 15]}
          placement='bottom'
        >
          <div className='user-dropdown-menu'>
            {menuItems.map(({ type, title, link, icon, func, hasSeparator }) => (
              <>
                {type === 'link' ? (
                  <NavLink key={title} className='user-dropdown-menu-item' activeClassName='active' to={link} exact>
                    <div className={cn('user-dropdown-menu-item-icons')}>{icon}</div>
                    {t(title)}
                  </NavLink>
                ) : (
                  <button type='button' className='user-dropdown-menu-item' onClick={func}>
                    <div className={cn('user-dropdown-menu-item-icons')}>{icon}</div>
                    {t(title)}
                  </button>
                )}
                {hasSeparator && <HrLine className='line-divider' />}
              </>
            ))}
          </div>
        </Popover>
      )}
    </div>
  )
}

import { createAction } from 'redux-act'

import { IUser } from '@@ting/models'
import { IChatRecord, PollAnswerMessage } from '@ting/ting-ui-components'

export const setCinemaMode = createAction<boolean>('setCinemaMode')
export const liveMessageSent = createAction<IChatRecord>('liveMessageSent')
export const receivedLiveChatMessage = createAction<IChatRecord>('receivedLiveChatMessage')
export const setActivePoll = createAction<IChatRecord>('setActivePoll')
export const updateActivePollTimer = createAction<number>('updateActivePollTimer')
export const addActivePollAnswer = createAction<{ pollAnswer: PollAnswerMessage; user?: IUser }>('addActivePollAnswer')

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { axiosInstance } from '@@ting/services/api'
import { authorizer } from '@@ting/services/authorization/Authorizer'
import { getSessionId } from '@@ting/utils/SessionId'

let previousRequestInterceptor: number = null
let previousResponseInterceptor: number = null

export const setInterceptors = () => {
  // Set a request interceptor to send Bearer token if user is signed-in
  if (previousRequestInterceptor) {
    axiosInstance.interceptors.request.eject(previousRequestInterceptor)
  }
  previousRequestInterceptor = axiosInstance.interceptors.request.use(config => {
    if (authorizer.accessToken) {
      config.headers.Authorization = `Bearer ${authorizer.accessToken}`
    }
    if (!config.url.includes('kyc/isverified')) {
      config.headers.SessionId = getSessionId()
    }
    return config
  })

  // Set a response interceptor in case of 401 errors that might be related to token expiration
  // Try to refresh the token and retry the request. if it keeps failing on retry attempt, give up and
  // throw the error back to the requester
  if (previousResponseInterceptor) {
    axiosInstance.interceptors.response.eject(previousResponseInterceptor)
  }
  previousResponseInterceptor = axiosInstance.interceptors.response.use(
    response =>
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      response,
    async error =>
      new Promise((resolve, reject) => {
        const originalRequest = error.config
        // If the request failed because of a network issue
        if (!error.response) {
          reject(error)
        } else if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true

          authorizer
            .silentSignin()
            .then(() => {
              const { accessToken } = authorizer
              originalRequest.headers.Authorization = `Bearer ${accessToken}`
              resolve(axiosInstance(originalRequest))
            })
            .catch(err => {
              reject(err)
            })
        } else {
          reject(error)
        }
      })
  )
}

import { CancelTokenSource } from 'axios'
import { axiosInstance } from '@@ting/services/api'
import { Option } from '@ting/ting-ui-components'
import { appendFormDataIfTruthy } from '@@ting/helpers/utils'
import { VideoPrivacy } from '@@ting/models'
import { getTagsForQuery } from '@@ting/redux/selectors'
import { FilterParam } from '@@ting/utils/actions'

export type Sort = 'name' | '-duration' | '-createdAt' | '-publishedAt' | '-views' | '-likes' | '-trending' | '-hot'

type GetVideoParams = {
  videoUUID: string
}

const getVideoDetails = ({ videoUUID }: GetVideoParams) =>
  axiosInstance({
    url: `/videos/${videoUUID}`,
    method: 'GET',
  }).then(res => res.data)

type GetVideoDescriptionParams = {
  videoId: string | number
}

const getVideoDescription = ({ videoId }: GetVideoDescriptionParams) =>
  axiosInstance({
    url: `/videos/${videoId}/description`,
    method: 'GET',
  }).then(res => res.data)

type AddVideoViewParams = {
  videoId: string | number
}

const addVideoView = ({ videoId }: AddVideoViewParams) =>
  axiosInstance({
    url: `/videos/${videoId}/views`,
    method: 'POST',
  }).then(res => res.data)

type SetUserWatchingVideoParams = {
  videoUUID: string
  currentTime: string | number
}

const setUserWatchingVideo = ({ videoUUID, currentTime }: SetUserWatchingVideoParams) =>
  axiosInstance({
    url: `/videos/${videoUUID}/watching`,
    method: 'PUT',
    data: { currentTime },
  }).then(res => res.data)

type SetUserWatchingDurationParams = {
  videoUUID: string
  watchingDuration: number
  viewSessionId?: string
  watchingPercentage?: number
}

const setUserWatchingDuration = ({
  videoUUID,
  watchingDuration,
  viewSessionId,
  watchingPercentage,
}: SetUserWatchingDurationParams) =>
  axiosInstance({
    url: `/videos/${videoUUID}/watching`,
    method: 'PUT',
    data: { watchingDuration, viewSessionId, watchingPercentage },
  }).then(res => res.data)

type AddVideoCommentParams = {
  videoId: string | number
  text: string
  videoChannelId?: number
}

const addVideoComment = ({ videoId, text, videoChannelId }: AddVideoCommentParams) =>
  axiosInstance({
    url: `/videos/${videoId}/comment-threads`,
    method: 'POST',
    data: { text, videoChannelId },
  }).then(res => res.data)

type LikeVideoCommentParams = {
  videoId: string | number
  commentId: string | number
}

const likeVideoComment = ({ videoId, commentId }: LikeVideoCommentParams) =>
  axiosInstance({
    url: `/videos/${videoId}/comments/${commentId}/rate`,
    method: 'PUT',
    data: {
      rating: 'like',
    },
  }).then(res => res.data)

type DisLikeVideoCommentParams = {
  videoId: string | number
  commentId: string | number
}

const dislikeVideoComment = ({ videoId, commentId }: DisLikeVideoCommentParams) =>
  axiosInstance({
    url: `/videos/${videoId}/comments/${commentId}/rate`,
    method: 'PUT',
    data: {
      rating: 'dislike',
    },
  }).then(res => res.data)

type GetVideoCommentsParams = {
  videoId: string | number
  sort: '-createdAt' | '-totalReplies'
  count?: number
  start?: number
}

const getVideoComments = ({ videoId, sort, count = 10, start = 0 }: GetVideoCommentsParams) =>
  axiosInstance({
    url: `/videos/${videoId}/comment-threads`,
    method: 'GET',
    params: {
      sort,
      count,
      start,
    },
  }).then(res => res.data)

type AddVideoCommentReplyParams = {
  videoId: string | number
  commentId: string | number
  text: string
}

const addVideoCommentReply = ({ videoId, commentId, text }: AddVideoCommentReplyParams) =>
  axiosInstance({
    url: `/videos/${videoId}/comments/${commentId}`,
    method: 'POST',
    data: { text },
  }).then(res => res.data)

type GetVideoCommentRepliesParams = {
  videoId: string | number
  commentId: string | number
}

const getVideoCommentReplies = ({ videoId, commentId }: GetVideoCommentRepliesParams) =>
  axiosInstance({
    url: `/videos/${videoId}/comment-threads/${commentId}`,
    method: 'GET',
  }).then(res => res.data)

export type GetVideosParam = {
  sort?: Sort
  count?: number
  filterParams?: FilterParam
  stream?: any
  start?: number
}

// eslint-disable-next-line complexity
const getVideos = ({ start, sort, count = 10, filterParams, stream }: GetVideosParam) => {
  const params: any = {
    sort,
    start,
    count,
    stream,
  }

  if (filterParams) {
    if (filterParams.chosenCategory) {
      params.categoryOneOf = filterParams.chosenCategory.toString() === '0' ? null : filterParams.chosenCategory
    }
    if (filterParams.durationFilter && filterParams.durationFilter.value) {
      params.durationMax = filterParams.durationFilter.value
    }
    if (Array.isArray(filterParams.languageFilter) && filterParams.languageFilter.length > 0) {
      params.languageOneOf = filterParams.languageFilter.map((item: Option) => item.value as string)
    }
    if (filterParams.tagsFilter.length) {
      params.tagsOneOf = getTagsForQuery(filterParams.tagsFilter)
    }
  }

  return axiosInstance({
    url: '/videos',
    method: 'GET',
    params,
  }).then(res => res.data)
}

export type GetChannelVideosParams = {
  skipCount?: number
  start?: 0
  channelHandle?: string
  categoryOneOf?: string | string[]
  count?: number
  sort?: Sort
  nsfw?: boolean
  tagsOneOf?: string | string[]
  tagsAllOf?: string | string[]
  filter?: any
  licenceOneOf?: string | string[]
  languageOneOf?: string | string[]
  withPrivateVideos?: boolean
  videosEndDate?: Date
  videosStartDate?: Date
}

const getChannelVideos = ({
  channelHandle,
  categoryOneOf,
  tagsOneOf,
  tagsAllOf,
  licenceOneOf,
  languageOneOf,
  nsfw,
  filter,
  skipCount = 0,
  start = 0,
  count = 100, // Temp
  sort,
  withPrivateVideos = true,
  videosEndDate,
  videosStartDate,
}: GetChannelVideosParams) =>
  axiosInstance({
    url: `/video-channels/${channelHandle}/videos`,
    method: 'GET',
    params: {
      categoryOneOf,
      tagsOneOf,
      tagsAllOf,
      licenceOneOf,
      languageOneOf,
      nsfw,
      filter,
      skipCount,
      start,
      count,
      sort,
      withPrivateVideos,
      videosEndDate,
      videosStartDate,
    },
  }).then(res => res.data)

export type UploadVideoParams = {
  videofile: File
  channelId: string | number
  name: string
  onUploadProgress: (...args: any) => void
  cancelToken: CancelTokenSource
}

const uploadVideo = ({ videofile, channelId, name, onUploadProgress, cancelToken }: UploadVideoParams) => {
  const formData = new FormData()
  appendFormDataIfTruthy(formData, 'videofile', videofile)
  appendFormDataIfTruthy(formData, 'channelId', channelId)
  appendFormDataIfTruthy(formData, 'name', name)

  console.log('%cvideos.ts line:255 formData', 'color: #007acc;', formData)

  return axiosInstance({
    url: '/videos/upload',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    onUploadProgress,
    cancelToken: cancelToken.token,
  }).then(res => res.data)
}

export type ImportVideoParams = {
  targetUrl: string
  channelId: string | number
  name?: string
  cancelToken: CancelTokenSource
}

const importVideo = ({ targetUrl, channelId, name, cancelToken }: ImportVideoParams) => {
  const formData = new FormData()
  appendFormDataIfTruthy(formData, 'targetUrl', targetUrl)
  appendFormDataIfTruthy(formData, 'channelId', channelId)
  if (name) {
    appendFormDataIfTruthy(formData, 'name', name)
  }

  const liveStreamPattern = /.*\.m3u8$/i
  const importAPI = liveStreamPattern.test(targetUrl) ? 'import-livestream' : 'imports'

  return axiosInstance({
    url: `/videos/${importAPI}`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    cancelToken: cancelToken.token,
  }).then(res => res.data)
}

type UpdateVideoParams = {
  videoId: string | number
  channelId: string | number
  name?: string | number
  description?: string
  thumbnailfile?: File
  previewfile?: File
  tags?: string[]
  category?: string | number
  nsfw?: boolean
  privacy?: VideoPrivacy
  licence?: string | number
  language?: string | number
  waitTranscoding?: boolean
  commentsEnabled?: boolean
  downloadEnabled?: boolean
  secondaryThumbnailfile?: File
}
const updateVideo = ({
  videoId,
  channelId,
  name,
  description,
  thumbnailfile,
  previewfile,
  tags,
  category,
  language,
  licence,
  privacy,
  nsfw,
  waitTranscoding,
  commentsEnabled,
  downloadEnabled,
  secondaryThumbnailfile,
}: UpdateVideoParams) => {
  const formData = new FormData()
  appendFormDataIfTruthy(formData, 'channelId', channelId)
  appendFormDataIfTruthy(formData, 'name', name)
  appendFormDataIfTruthy(formData, 'description', description)
  appendFormDataIfTruthy(formData, 'thumbnailfile', thumbnailfile)
  appendFormDataIfTruthy(formData, 'previewfile', previewfile)
  if (tags) {
    tags.forEach((tag, i) => appendFormDataIfTruthy(formData, `tags[${i}]`, tag))
  }
  appendFormDataIfTruthy(formData, 'category', category)
  appendFormDataIfTruthy(formData, 'language', language)
  appendFormDataIfTruthy(formData, 'licence', licence)
  appendFormDataIfTruthy(formData, 'privacy', privacy)
  appendFormDataIfTruthy(formData, 'nsfw', nsfw)
  appendFormDataIfTruthy(formData, 'waitTranscoding', waitTranscoding)
  appendFormDataIfTruthy(formData, 'commentsEnabled', commentsEnabled)
  appendFormDataIfTruthy(formData, 'downloadEnabled', downloadEnabled)
  appendFormDataIfTruthy(formData, 'secondaryThumbnailfile', secondaryThumbnailfile)

  return axiosInstance({
    url: `/videos/${videoId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  }).then(res => res.data)
}

type DeleteVideoParams = {
  videoId: string | number
}

const deleteVideo = ({ videoId }: DeleteVideoParams) =>
  axiosInstance({
    url: `/videos/${videoId}`,
    method: 'DELETE',
  }).then(res => res.data)

export type Search = {
  query: string
  language: string
  tags: string
  categories: string
  start?: number
  endDate?: string
  startDate?: string
  count?: number
  sort?: string
  durationMax?: string
}

function getSearchedVideo({
  query,
  start = 0,
  count = 15,
  language,
  tags,
  categories,
  startDate,
  endDate,
  durationMax,
  sort,
}: Search) {
  return axiosInstance
    .get('/search/videos', {
      params: {
        search: query,
        start,
        count,
        languageOneOf: language,
        tagsOneOf: tags,
        categoryOneOf: categories,
        startDate,
        endDate,
        durationMax,
        sort,
      },
    })
    .then(response => response.data)
}

function getSearchedVideoChannel(search: Search) {
  return axiosInstance
    .get('/search/video-channels', {
      params: {
        search: search.query,
      },
    })
    .then(response => response.data)
}

function getSearchedProfile(search: Search) {
  return axiosInstance
    .get('/search/accounts', {
      params: {
        search: search.query,
      },
    })
    .then(response => response.data)
}

type LikeVideoParam = {
  videoUUID: string
}

const likeVideo = ({ videoUUID }: LikeVideoParam) =>
  axiosInstance({
    url: `/videos/${videoUUID}/rate`,
    method: 'PUT',
    data: {
      rating: 'like',
    },
  }).then(res => res.data)

type DislikeVideoParam = LikeVideoParam
const disLikeVideo = ({ videoUUID }: DislikeVideoParam) =>
  axiosInstance({
    url: `/videos/${videoUUID}/rate`,
    method: 'PUT',
    data: {
      rating: 'dislike',
    },
  }).then(res => res.data)

type GetVideoRating = {
  videoId: number | string
}

const getVideoRating = ({ videoId }: GetVideoRating) =>
  axiosInstance({
    url: `/users/me/videos/${videoId}/rating`,
    method: 'GET',
  }).then(res => res.data)

type GetSimilarVideosParams = {
  tags?: string[]
  start?: number
  count?: number
  sort?: Sort
  searchTarget?: 'local' | 'search-index'
}

export const getSimilarVideos = ({
  tags,
  start = 0,
  count = 15,
  sort,
  searchTarget = 'local',
}: GetSimilarVideosParams) =>
  axiosInstance({
    url: '/search/videos',
    method: 'GET',
    params: {
      tagsOneOf: tags,
      start,
      count,
      sort,
      searchTarget,
    },
  }).then(res =>
    // TODO: this is temporary to fetch all videos if we didn't any other similar video
    res.data && res.data.total > 1 ? res.data : getVideos({ start, count, sort })
  )

type GetSubscriptionsVideo = {
  sort?: Sort
  start?: number
  count?: number
  channelIdOneOf?: string[]
}

const getSubscriptionVideos = ({ sort = '-createdAt', start = 0, count = 15, channelIdOneOf }: GetSubscriptionsVideo) =>
  axiosInstance({
    url: '/users/me/subscriptions/videos',
    method: 'GET',
    params: {
      sort,
      start,
      count,
      channelIdOneOf,
    },
  }).then(res => res.data)

export type GetLiveVideosParams = {
  filterParams?: FilterParam
  count?: number
}

export const getLiveVideos = ({ count = 15, filterParams }: GetLiveVideosParams) =>
  axiosInstance({
    url: '/videos',
    method: 'GET',
    params: {
      count,
      stream: true,
      filterParams,
    },
  }).then(res => res.data)

const getVideoCaptions = (videoId: string | number) =>
  axiosInstance({
    url: `/videos/${videoId}/captions`,
    method: 'GET',
  }).then(res => res.data)

const getVideoFullDescription = (descriptionPath: string) =>
  axiosInstance({
    url: descriptionPath.split('/').slice(3).join('/'),
  }).then(res => res.data)

const sendChatMessage = (text: string, time: string, videoId: string | number) =>
  axiosInstance({
    url: '/videos/newLive/chat',
    method: 'POST',
    data: { text, time, videoId },
  }).then(res => res.data)

const getVideoChatRecord = (videoId: string | number) =>
  axiosInstance({
    url: `/videos/newLive/chat/${videoId}`,
    method: 'GET',
  }).then(res => res.data)

export const VideosService = {
  getVideoDetails,
  getVideoDescription,
  addVideoView,
  setUserWatchingVideo,
  setUserWatchingDuration,
  addVideoComment,
  likeVideoComment,
  dislikeVideoComment,
  getVideoComments,
  addVideoCommentReply,
  getVideoCommentReplies,
  getVideos,
  getChannelVideos,
  uploadVideo,
  updateVideo,
  importVideo,
  deleteVideo,
  getSearchedVideo,
  getSearchedVideoChannel,
  getSearchedProfile,
  likeVideo,
  disLikeVideo,
  getVideoRating,
  getSimilarVideos,
  getSubscriptionVideos,
  getLiveVideos,
  getVideoFullDescription,
  getVideoCaptions,
  sendChatMessage,
  getVideoChatRecord,
}

import { createReducer } from 'redux-act'

import { getStoredTheater } from '@@ting/utils/player/ting-player-local-storage'
import { ChatRecord, IUser } from '@@ting/models'
import { PollAnswerMessage, PollQuestionMessage } from '@ting/ting-ui-components'
import {
  liveMessageSent,
  setCinemaMode,
  receivedLiveChatMessage,
  setActivePoll,
  updateActivePollTimer,
  addActivePollAnswer,
} from './actions'

export type PollsType = (PollQuestionMessage & {
  userSelectedOptions: string[]
  answers: Array<PollAnswerMessage>
})[]
export type StateType = {
  cinemaMode: boolean
  liveMessages: ChatRecord[]
  polls: PollsType
  activePoll: PollQuestionMessage | null
  activePollTime: number
}

const initialState: StateType = {
  cinemaMode: getStoredTheater(),
  liveMessages: [],
  polls: [],
  activePoll: null,
  activePollTime: 0,
}

const handleSetCinemaMode = (state: StateType, mode: boolean) => ({
  ...state,
  cinemaMode: mode,
})

const handleLiveMessageSent = (state: StateType, sentMessage: ChatRecord): StateType => ({
  ...state,
  liveMessages: [...state.liveMessages, sentMessage],
})

const handleReceivedLiveChatMessage = (state: StateType, payload: ChatRecord) => {
  if (!state.liveMessages.find(l => l.id === payload.id)) {
    return {
      ...state,
      liveMessages: [...state.liveMessages, payload],
    }
  }

  return state
}
const handleSetActivePoll = (state: StateType, payload: PollQuestionMessage) => ({
  ...state,
  polls: [...state.polls, { ...payload, userSelectedOptions: [], answers: [] }],
  activePoll: payload,
})
const handleUpdateActivePollTimer = (state: StateType, payload: number) => ({
  ...state,
  activePollTime: payload,
  activePoll: payload === 0 ? null : state.activePoll,
})
const handleAddActivePollAnswer = (state: StateType, payload: { pollAnswer: PollAnswerMessage; user: IUser }) => {
  const { pollAnswer, user } = payload
  const pollIdx = state.polls.findIndex(p => p.id === pollAnswer.content.pollId)
  const poll = state.polls[pollIdx]
  if (poll) {
    const submittedUserAnswerIdx = poll.answers.findIndex(a => a.from.corepassId === pollAnswer.from.corepassId)
    let newPollAnswers: PollAnswerMessage[] = []
    let userSelectedOptions: string[] = []
    if (submittedUserAnswerIdx !== -1) {
      newPollAnswers = [
        ...poll.answers.slice(0, submittedUserAnswerIdx),
        pollAnswer,
        ...poll.answers.slice(submittedUserAnswerIdx + 1),
      ]
    } else {
      newPollAnswers = newPollAnswers.concat(pollAnswer)
    }
    if (user && pollAnswer.from.corepassId === user.corepassId) {
      userSelectedOptions = pollAnswer.content.answerIds
    }
    poll.answers = newPollAnswers
    poll.userSelectedOptions = userSelectedOptions
    return {
      ...state,
      polls: [...state.polls.slice(0, pollIdx), poll, ...state.polls.slice(pollIdx + 1)],
    }
  }
  return state
}

const actions = {
  [setCinemaMode as any]: handleSetCinemaMode,
  [liveMessageSent as any]: handleLiveMessageSent,
  [receivedLiveChatMessage as any]: handleReceivedLiveChatMessage,
  [setActivePoll as any]: handleSetActivePoll,
  [updateActivePollTimer as any]: handleUpdateActivePollTimer,
  [addActivePollAnswer as any]: handleAddActivePollAnswer,
}

const reducer = createReducer(actions, initialState)

export default reducer

import { useMutation, useQuery, UseQueryResult } from 'react-query'
import { queryClient } from '@@ting/index'
import { PlaylistService } from '@@ting/services/api'
import { BasicQueryObjectType } from '@@ting/services/api/query-hooks/types'
import { ResultList, VideoExistInPlaylist, VideoPlaylist, VideoPlaylistElement } from '@@ting/models'

export const useGetUserPlaylists = (accountHandle: string, queryObject: BasicQueryObjectType) =>
  useQuery(
    [PlaylistService.getUserPlaylists.name, accountHandle, queryObject],
    () => PlaylistService.getUserPlaylists({ accountHandle, ...queryObject }),
    { enabled: !!accountHandle, refetchOnMount: 'always' }
  )

export const useGetChannelsPlaylists = (
  channelHandle: string,
  queryObject: BasicQueryObjectType
): UseQueryResult<ResultList<VideoPlaylist>, unknown> =>
  useQuery([PlaylistService.getChannelPlaylists.name, channelHandle, queryObject], () =>
    PlaylistService.getChannelPlaylists({ channelHandle, ...queryObject })
  )

export const useGetPlaylist = (playlistUUID: string) =>
  useQuery([PlaylistService.getPlaylist.name, playlistUUID], () => PlaylistService.getPlaylist({ playlistUUID }), {
    enabled: !!playlistUUID,
  })

export const useDeletePlaylist = () =>
  useMutation([PlaylistService.deletePlaylist.name], PlaylistService.deletePlaylist, {
    onSuccess: () => {
      queryClient.invalidateQueries(PlaylistService.getUserPlaylists.name)
    },
  })

export const useUpdatePlaylist = () =>
  useMutation([PlaylistService.updatePlaylist.name], PlaylistService.updatePlaylist, {
    onSuccess: () => {
      queryClient.invalidateQueries(PlaylistService.getUserPlaylists.name)
    },
  })

export const useCreateNewPlaylist = () =>
  useMutation([PlaylistService.createNewPlaylist.name], PlaylistService.createNewPlaylist, {
    onSuccess: () => {
      queryClient.invalidateQueries(PlaylistService.getUserPlaylists.name)
      queryClient.invalidateQueries(PlaylistService.getChannelPlaylists.name)
      queryClient.invalidateQueries(PlaylistService.getVideoPlaylistOfVideo.name)
    },
  })

export const useGetPlaylistVideos = (playlistUUID: string): UseQueryResult<ResultList<VideoPlaylistElement>, unknown> =>
  useQuery([PlaylistService.getPlaylistVideos.name, playlistUUID], () =>
    PlaylistService.getPlaylistVideos({ playlistUUID })
  )

export const useAddVideoToPlaylist = () =>
  useMutation([PlaylistService.addVideoToPlaylist.name], PlaylistService.addVideoToPlaylist, {
    onSuccess: () => {
      queryClient.invalidateQueries(PlaylistService.getPlaylistVideos.name)
    },
  })

export const useDeleteVideoFromPlaylist = () =>
  useMutation([PlaylistService.deleteVideoFromPlaylist.name], PlaylistService.deleteVideoFromPlaylist, {
    onSuccess: () => {
      queryClient.invalidateQueries(PlaylistService.getPlaylistVideos.name)
    },
  })

export const useReorderPlaylist = () =>
  useMutation([PlaylistService.reorderPlaylist.name], PlaylistService.reorderPlaylist, {
    onSuccess: () => {
      queryClient.invalidateQueries(PlaylistService.getPlaylistVideos.name)
    },
  })

export const useBatchReorderPlaylist = () =>
  useMutation([PlaylistService.batchReorderPlaylist.name], PlaylistService.batchReorderPlaylist, {
    onSuccess: () => {
      queryClient.invalidateQueries(PlaylistService.getPlaylistVideos.name)
    },
  })

export const useGetVideoPlaylistOfVideo = (
  videoId: string | number
): UseQueryResult<ResultList<VideoExistInPlaylist>, unknown> =>
  useQuery([PlaylistService.getVideoPlaylistOfVideo.name, videoId], () =>
    PlaylistService.getVideoPlaylistOfVideo({ videoId })
  )

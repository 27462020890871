import { FC } from 'react'
import { useGetLiveVideos } from '@@ting/services/api/query-hooks'
import { CarouselSkeleton } from '@@ting/molecules/Skeleton'
import VideoCarousel from '@@ting/molecules/VideoCarousel'

const LiveVideosContainer: FC = () => {
  const { data: videos, isLoading: videosIsLoading, isError: videosIsError } = useGetLiveVideos({})

  if (videosIsLoading) {
    return <CarouselSkeleton isMobile={false} />
  }

  return <VideoCarousel title='Now Live' videos={videosIsError ? [] : videos.data} />
}

export default LiveVideosContainer

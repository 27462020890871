import React, { FC } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useGetUserInfo } from '@@ting/services/api/query-hooks'
import { loginSideEffect } from '../services/authorization/sideEffects'

interface IProps extends RouteProps {
  component: React.ComponentType<any>
}
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute: FC<IProps> = ({ component: Component, ...rest }) => {
  const { data: user, isLoading } = useGetUserInfo()

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoading) {
          return <div>loading</div>
        }
        if (!user) {
          loginSideEffect()
          return <div>loading</div>
        }

        return <Component {...props} />
      }}
    />
  )
}

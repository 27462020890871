import { Component } from 'react'

import { Text, Heading } from '@ting/ting-ui-components'

type Props = {
  autoReload?: boolean
  children: React.ReactNode
}

type State = {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  constructor(props: Props | Readonly<Props>) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch() {
    const { autoReload = process.env.NODE_ENV === 'production' } = this.props
    if (autoReload) {
      setTimeout(() => {
        window.location.assign('/')
      }, 5000)
    }
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (!hasError) {
      return children
    }

    return (
      <div className='text-center w-100 p-3'>
        <Heading type='h3'>Whoa!</Heading>
        <Text size='medium' type='sub'>
          Looks like something's wrong
        </Text>
      </div>
    )
  }
}

export default ErrorBoundary

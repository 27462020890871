import { createReducer } from 'redux-act'
import { LOCATION_CHANGE, LocationChangePayload } from 'connected-react-router'
import { Location } from 'history'

import {
  historyStateMount,
  HistoryStateMountPayload,
  historyStateUnmount,
  HistoryStateUnmountPayload,
  historyStatePop,
  HistoryStatePopPayload,
} from '@@ting/redux/actions'

export type State = {
  history: {
    locationKey: string
    garbage: boolean
    change: {
      id: number
      from: any
      to: any
    }
  }[]
  index: number
  // values are mapped by a unique id which is incremented by 1 by each usage of useHistoryState hook
  values: { [key: number]: any }
}

const initialState: State = {
  history: [{ locationKey: undefined, garbage: false, change: null }],
  index: 0,
  values: {},
}

type LocationState = {
  historyStatePush?: {
    id: number
    value: any
  }
}

const handleHistoryStateMount = (state: State, payload: HistoryStateMountPayload) => {
  const { history, index, values } = state

  // Append initial value that was passed to useHistoryState hook to the current values stored in state
  const newValues = {
    ...values,
    [payload.id]: payload.initialValue,
  }

  const newState: State = {
    history,
    index,
    values: newValues,
  }
  return newState
}

const handleHistoryStateUnmount = (state: State, payload: HistoryStateUnmountPayload) => {
  const { history, index, values } = state

  const newHistory = history.map(historyItem =>
    historyItem.change?.id === payload.id ? { ...historyItem, garbage: true, change: null } : historyItem
  )

  // Delete the 'value' with relevant id from values object, immutable way
  const { [payload.id]: removedValue, ...newValues } = values

  const newState: State = {
    history: newHistory,
    index,
    values: newValues,
  }
  return newState
}

const handleHistoryStatePop = (state: State, payload: HistoryStatePopPayload) => {
  const { history, index, values } = state

  // Find last history item index with popped id that has a non null 'change' field
  const lastChangeIndex = history.reduce(
    (lastIndex, historyItem, currentIndex) =>
      historyItem.change?.id === payload.id && currentIndex <= index ? currentIndex : lastIndex,
    -1
  )

  // Don't care about any history records newer than last item that has a change field
  const newHistory = history.map((historyItem, itemIndex) => {
    if (lastChangeIndex <= itemIndex && historyItem.change?.id === payload.id) {
      return {
        ...historyItem,
        garbage: true,
        change: null,
      }
    }
    return historyItem
  })

  // Revert the change for this history item in values object (if any)
  const newValues =
    lastChangeIndex !== -1
      ? {
          ...values,
          [payload.id]: history[lastChangeIndex].change.from,
        }
      : values

  const newState: State = {
    history: newHistory,
    index,
    values: newValues,
  }
  return newState
}

const handleLocationPush = (state: State, payload: Location<LocationState>) => {
  const { history, index, values } = state
  const newPush = payload.state?.historyStatePush

  const newHistoryItem = {
    locationKey: payload.key,
    garbage: false,
    change: newPush
      ? {
          id: newPush.id,
          from: values[newPush.id],
          to: newPush.value,
        }
      : null,
  }
  const newHistory = [...history.slice(0, index + 1), newHistoryItem]

  const newIndex = index + 1

  const newValues = newPush
    ? {
        ...values,
        [newPush.id]: newPush.value,
      }
    : values

  const newState: State = {
    history: newHistory,
    index: newIndex,
    values: newValues,
  }
  return newState
}

const handleLocationReplace = (state: State, payload: Location<LocationState>) => {
  const { history, index, values } = state

  const newHistoryItem = {
    ...history[index],
    locationKey: payload.key,
  }
  const newHistory = [...history.slice(0, index), newHistoryItem, ...history.slice(index + 1)]

  const newState: State = {
    history: newHistory,
    index,
    values,
  }
  return newState
}

// Invoked when user hits back button on browser/phone
const handleLocationPop = (state: State, payload: Location<LocationState>) => {
  const { history, index, values } = state

  const targetIndex = history.findIndex(({ locationKey }) => payload.key === locationKey)
  const newHistory =
    targetIndex === -1
      ? [
          {
            locationKey: payload.key,
            garbage: false,
            change: null,
          },
          ...history,
        ]
      : history

  const currentIndex = targetIndex === -1 ? index + 1 : index
  const newIndex = targetIndex === -1 ? 0 : targetIndex

  const newValues =
    currentIndex < newIndex
      ? newHistory
          .slice(currentIndex + 1, newIndex + 1)
          .filter(({ change }) => !!change)
          .reduce((previousValues, { change: { id, to } }) => ({ ...previousValues, [id]: to }), values)
      : newHistory
          .slice(newIndex + 1, currentIndex + 1)
          .filter(({ change }) => !!change)
          .reverse()
          .reduce((previousValues, { change: { id, from } }) => ({ ...previousValues, [id]: from }), values)

  const newState: State = {
    history: newHistory,
    index: newIndex,
    values: newValues,
  }
  return newState
}

const handleLocationChange = (state: State, payload: LocationChangePayload<LocationState>) => {
  if (payload.location.key === state.history[state.index].locationKey) {
    return state
  }

  switch (payload.action) {
    case 'PUSH':
      return handleLocationPush(state, payload.location)
    case 'REPLACE':
      return handleLocationReplace(state, payload.location)
    case 'POP':
      return handleLocationPop(state, payload.location)
    default:
      return state
  }
}

const actions = {
  [historyStateMount.toString()]: handleHistoryStateMount,
  [historyStateUnmount.toString()]: handleHistoryStateUnmount,
  [historyStatePop.toString()]: handleHistoryStatePop,
  [LOCATION_CHANGE]: handleLocationChange,
}

export default createReducer(actions, initialState)

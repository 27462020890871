import { FC, useState } from 'react'
import cn from 'classnames'
import { Checkbox } from '@ting/ting-ui-components'
import { Action } from 'redux-act'
import { HistoryStatePopPayload } from '@@ting/redux/actions'
import { useFilterParams } from '@@ting/utils/actions'
import { timeFrameOptions } from '../../config'
import { FilterPage, SelectValue } from '../../types'

import './mobileFiltersTimeframe.scss'

type Props = {
  className?: string
  title?: string
  setPage: { push: (newValue: FilterPage) => void; pop: () => Action<HistoryStatePopPayload, any> }
}

const TrendingMobileFiltersTimeFrame: FC<Props> = props => {
  const { className = '', title, setPage } = props
  const { filterParams, filterParamsActions } = useFilterParams()
  const [selectTimeFrame, setSelectTimeFrame] = useState<SelectValue>(filterParams.durationFilter || null)

  const handleDone = () => {
    filterParamsActions.changeDuration(selectTimeFrame)

    setPage.pop()
  }

  return (
    <>
      <div className={cn('tmf__container', className)}>
        <header className='tmf__header tmf__header-language'>
          <div className='tmf__header-meta'>
            <span className='tmf__cancel' onClick={() => setPage.pop()}>
              cancel
            </span>
            <span className='tmf__title'>{title}</span>
            <span className='tmf__done' onClick={handleDone}>
              Done
            </span>
          </div>
        </header>
        <div className='tmf__content'>
          {timeFrameOptions.map(time => (
            <Checkbox
              id={time.value.toString()}
              bigSize
              key={time.value}
              name='timeFrameOptions'
              onChange={() => setSelectTimeFrame({ ...time, value: time.value.toString() })}
              checked={selectTimeFrame && time.value.toString() === selectTimeFrame.value}
            >
              {time.label}
            </Checkbox>
          ))}
        </div>
      </div>
    </>
  )
}

export default TrendingMobileFiltersTimeFrame

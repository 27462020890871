import React, { FC } from 'react'
import cn from 'classnames'
import { ChevronIcon } from '@ting/ting-ui-components'
import { useHistory } from 'react-router-dom'
import './MenuItem.scss'

/**
 * @interface MenuItem Component IProps
 */
interface IMenuItemProps extends React.HTMLProps<HTMLDivElement> {
  title: string
  forwardIcon?: boolean
  backIcon?: boolean
  isHeaderItem?: boolean
  onClick?: () => void
  link?: string
  smallFont?: boolean
}

/**
 * @param props: IMenuItemProps
 */
const MenuItem: FC<IMenuItemProps> = (props: IMenuItemProps) => {
  const {
    title,
    forwardIcon = false,
    backIcon = false,
    isHeaderItem,
    onClick,
    link,
    smallFont,
    className = '',
    ...rest
  } = props
  const history = useHistory()

  const handleClick = () => {
    if (link) {
      history.push(link)
    }
    if (onClick) {
      onClick()
    }
  }

  return (
    <div
      className={cn(
        'menuItem__container',
        className,
        isHeaderItem && 'menuItem__header',
        smallFont && 'menuItem__small-font'
      )}
      onClick={handleClick}
      {...rest}>
      {backIcon && <ChevronIcon className='menuItem__back-arrow' width={12} height={6} />}
      <span className='menuItem__title'>{title}</span>
      {forwardIcon && <ChevronIcon className='menuItem__forward-arrow' width={12} height={6} />}
    </div>
  )
}

export default MenuItem

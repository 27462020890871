import { FC, ComponentPropsWithoutRef } from 'react'
import { Field } from 'formik'
import { FieldError, SelectBox, SelectValue } from '@ting/ting-ui-components'

export type Value = {
  value: string | number
  label: string | number
}

type Props = {
  className?: string
  name: string
  value: SelectValue
  setFieldValue: (name: string, value: SelectValue) => void
} & ComponentPropsWithoutRef<typeof SelectBox>

export const SelectField: FC<Props> = ({ className, name, value, setFieldValue, isSearchable, ...rest }) => {
  const handleChange = (newValue: SelectValue) => {
    setFieldValue(name, newValue)
  }

  return (
    <>
      <Field name={name}>
        {() => (
          <SelectBox
            className={className}
            onChange={handleChange}
            value={value}
            isSearchable={isSearchable}
            {...rest}
          />
        )}
      </Field>
      <FieldError name={name} />
    </>
  )
}

import { FC } from 'react'

import { useHistoryState } from '@@ting/utils/hooks'
import TrendingMobileFiltersMain from '../MobileFiltersMain'
import TrendingMobileFiltersLanguages from '../MobileFiltersLanguages'
import TrendingMobileFiltersTimeframe from '../MobileFiltersTimeFrame'
import { SelectValue, FilterPage } from '../../types'

type Props = {
  languagesList: SelectValue[]
  onClose: () => void
}

export const TrendingMobileFiltersRouter: FC<Props> = props => {
  const { languagesList, onClose } = props
  const [page, setPage] = useHistoryState<FilterPage>(FilterPage.MAIN)

  return (
    <div className='tmf__router-container'>
      {page === FilterPage.MAIN && <TrendingMobileFiltersMain title='Filters' setPage={setPage} onClose={onClose} />}
      {(page === FilterPage.LANGUAGES || page === FilterPage.SELECTED_LANGUAGES) && (
        <TrendingMobileFiltersLanguages title='Language' page={page} setPage={setPage} languagesList={languagesList} />
      )}
      {page === FilterPage.TIME_DURATION && <TrendingMobileFiltersTimeframe title='Time Frames' setPage={setPage} />}
    </div>
  )
}

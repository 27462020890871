import { Dispatch } from 'react'
import { useMutation, useQuery, useInfiniteQuery } from 'react-query'
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types'

import { queryClient } from '@@ting/index'
import { AuthService, UserService, VideoChannelsService } from '@@ting/services/api'
import { getNextPageParam } from '@@ting/utils/getNextPageParams'
import { BasicQueryObjectType, GetMyVideoHistoryObjectType } from '@@ting/services/api/query-hooks/types'
import { signUpSideEffect } from '@@ting/services/SignUpService/sideEffects'
import { authorizer } from '@@ting/services/authorization/Authorizer'
import { ResultList, UserFollowing } from '@@ting/models'
import { ChannelSubscribersQueryObject } from './channels'

export const useGetUsersList = (search?: string) =>
  useQuery(UserService.getUsersList.name, () => UserService.getUsersList(search))

export const useGetUserInfo = (options?: UseQueryOptions) =>
  useQuery(UserService.getUserInfo.name, () => UserService.getUserInfo(), options as any)

export const useGetUserInfoWhenNeed = (options?: UseQueryOptions) =>
  useGetUserInfo({ options, enabled: !!authorizer.accessToken } as any)

export const useGetOtherUserInfo = (userId: number, enabled = true) =>
  useQuery([UserService.getOtherUserInfo.name, userId], () => UserService.getOtherUserInfo(userId), {
    enabled,
  })

export const useGetMyVideos = (queryObject: BasicQueryObjectType) =>
  useQuery([UserService.getMyVideos.name, queryObject], () => UserService.getMyVideos(queryObject))

export const useUpdateUserInfo = () =>
  useMutation(UserService.updateUserInfo.name, UserService.updateUserInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries(UserService.getUserInfo.name)
    },
  })

export const useUploadUserAvatar = () => useMutation([UserService.uploadUserAvatar.name], UserService.uploadUserAvatar)

export const useGetPublicAccount = (name: string) =>
  useQuery([UserService.getPublicAccount.name, name], () => UserService.getPublicAccount(name))

export const useMutateDeleteFromMyVideoHistory = () =>
  useMutation(UserService.deleteFromMyVideoHistory, {
    onSuccess: () => {
      queryClient.invalidateQueries(UserService.getMyVideoHistory.name)
    },
  })

export const useMutateClearMyVideoHistory = () =>
  useMutation(UserService.clearMyVideoHistory, {
    onSuccess: () => {
      queryClient.invalidateQueries(UserService.getMyVideoHistory.name)
    },
  })

export const useGetMyFollowing = (queryObject: ChannelSubscribersQueryObject) =>
  useQuery([UserService.getMyFollowing.name, queryObject], () => UserService.getMyFollowing(queryObject))

export const useUnfollowAChannel = () =>
  useMutation([UserService.unfollowAChannel.name], UserService.unfollowAChannel, {
    onSuccess: () => {
      queryClient.invalidateQueries(UserService.isFollowingAChannel.name)
      queryClient.invalidateQueries(VideoChannelsService.getChannelSubscribers.name)
    },
  })

export const useFollowToAChannel = () =>
  useMutation([UserService.followAChannel.name], UserService.followAChannel, {
    onSuccess: () => {
      queryClient.invalidateQueries(UserService.isFollowingAChannel.name)
      queryClient.invalidateQueries(VideoChannelsService.getChannelSubscribers.name)
    },
  })

export const useUploadCoverImage = () => useMutation([UserService.uploadCoverImage.name], UserService.uploadCoverImage)

export const useDeleteMyUser = () => useMutation([UserService.deleteMyUser.name], () => UserService.deleteMyUser())

export const useGetBlacklistAccounts = (search?: string) =>
  useQuery(UserService.getBlacklistAccounts.name, () => UserService.getBlacklistAccounts(search))

export const useDeleteBlacklistAccount = () =>
  useMutation(UserService.deleteBlacklistAccount.name, UserService.deleteBlacklistAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(UserService.getBlacklistAccounts.name)
    },
  })

export const useAddBlacklistAccount = () =>
  useMutation(UserService.addBlacklistAccount.name, UserService.addBlacklistAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(UserService.getBlacklistAccounts.name)
    },
  })

export const useIsFollowingAChannel = (uris: string[]) =>
  useQuery([UserService.isFollowingAChannel.name, uris], () => UserService.isFollowingAChannel(uris), {
    enabled: !!uris,
  })

export const useGetUserFollowingList = (
  accountHandle: string,
  options?: UseQueryOptions,
  search?: string
): UseQueryResult<ResultList<UserFollowing>> =>
  useQuery(
    [UserService.getUserFollowingList.name, accountHandle, search],
    () => UserService.getUserFollowingList(accountHandle, search),
    { ...options, enabled: !!accountHandle } as any
  )

export const useGetUserFollowersList = (accountHandle: string, search?: string) =>
  useQuery([UserService.getUserFollowersList.name, accountHandle, search], () =>
    UserService.getUserFollowersList(accountHandle, search)
  )

export const useFollowAccount = () =>
  useMutation(UserService.followAccount.name, UserService.followAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(UserService.isFollowAccountExist.name)
      queryClient.invalidateQueries(UserService.getUserFollowingList.name)
      queryClient.invalidateQueries(UserService.getUserFollowersList.name)
    },
  })

export const useUnfollowAccount = () =>
  useMutation(UserService.unfollowAccount.name, UserService.unfollowAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(UserService.getUserFollowingList.name)
      queryClient.invalidateQueries(UserService.isFollowAccountExist.name)
      queryClient.invalidateQueries(UserService.getUserFollowersList.name)
    },
  })

export const useIsFollowAccountExist = (accountHandle: string) =>
  useQuery(
    [UserService.isFollowAccountExist.name, accountHandle],
    () => UserService.isFollowAccountExist(accountHandle),
    { enabled: !!accountHandle }
  )

export const useGetMyVideoHistory = (queryObject: GetMyVideoHistoryObjectType) =>
  useInfiniteQuery(
    [UserService.getMyVideoHistory.name, queryObject],
    ({ pageParam = 0 }) => UserService.getMyVideoHistory({ ...queryObject, start: pageParam }),
    { getNextPageParam }
  )
export const useSignUp = (dispatch: Dispatch<any>) =>
  useMutation(AuthService.signUp.name, AuthService.signUp, {
    onSuccess: () => {
      queryClient.invalidateQueries(UserService.getUserInfo.name)
    },
    onError: (error: any, context: unknown) => {
      signUpSideEffect(dispatch, context, error)
    },
  })

export const useCorePassVerifiedFields = (coreId: string, options?: UseQueryOptions) =>
  useQuery(
    UserService.getCorePassVerifiedFields.name,
    () => UserService.getCorePassVerifiedFields(coreId),
    options as any
  )

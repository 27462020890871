import { FC } from 'react'
import { Skeleton } from '@ting/ting-ui-components'
import './VideoCardSkeleton.scss'

export const VideoCardSkeleton: FC = () => (
  <div className='video-card-skeleton'>
    <Skeleton variant='rect' className='video-card-skeleton__thumbnail' />

    <div className='video-card-skeleton__description'>
      <Skeleton variant='rect' className='video-card-skeleton__profile' height={40} width={40} />
      <div className='video-card-skeleton__detail'>
        <Skeleton variant='text' className='video-card-skeleton__title' height={11} />
        <Skeleton variant='text' width='50%' className='video-card-skeleton__desc_skeleton' height={8} />
      </div>
    </div>
  </div>
)

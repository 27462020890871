import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { PATH as SIGNUP_PATH } from '@@ting/services/SignUpService/constants'
import cn from 'classnames'
import { ArrowLeftIcon, Button, MenuThinIcon, TagType, Text } from '@ting/ting-ui-components'
import routes from '@@ting/enums/routes'

import { selectAuthorizationInitiated } from '@@ting/redux'
import { loginSideEffect } from '@@ting/services/authorization/sideEffects'
import { useGetUserInfoWhenNeed } from '@@ting/services/api/query-hooks'
import { useConfig } from '@@ting/utils/actions'
import { useMobile } from '@@ting/utils/hooks'
import { NotificationsOutlinedIcon, ObsOutlinedIcon, SearchOutlinedIcon } from '@ting/ting-ui-components/dist/icons'
import { HeaderLogo, MobileMenu, SearchBar, UploadVideo, UserDropdown } from './components'
import { HeaderSkeleton } from './HeaderSkeleton'

import './Header.scss'

type Props = {
  className?: string
}

export const Header: FC<Props> = ({ className }) => {
  const isMobile = useMobile()
  const location = useLocation()
  const { t } = useTranslation('common')
  const { configActions } = useConfig()
  const [isSearchMode, setIsSearchMode] = useState(false)

  const authInitialized = useSelector(selectAuthorizationInitiated)
  const { data: user, isLoading: userIsLoading, error: userError } = useGetUserInfoWhenNeed()
  const history = useHistory()

  useEffect(() => {
    if ((userError as any)?.response?.status === 404) {
      history.push(SIGNUP_PATH)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userError])

  useEffect(() => {
    if (location.pathname !== routes.Common.search) {
      setIsSearchMode(false)
    }
  }, [location.pathname, location.search])

  const handleLogin = () => {
    loginSideEffect()
  }

  if (userIsLoading) return <HeaderSkeleton />
  if (isSearchMode) {
    return (
      <div className={cn('header', className)}>
        <Button
          onClick={() => setIsSearchMode(false)}
          v2
          startIcon={<ArrowLeftIcon color='white' width={20} height={20} />}
          variant='ghost'
          type='neutral'
        />
        <SearchBar className='header-search-bar' />
      </div>
    )
  }

  return (
    <div className={cn('header', className)}>
      <div className='ml-0 d-flex align-items-center'>
        {isMobile && <MobileMenu className='header-mobile-menu' />}
        {!isMobile && (
          <Button className='header-menu-button' theme='transparent' onClick={configActions.toggleSidebar}>
            <MenuThinIcon />
          </Button>
        )}
        <HeaderLogo tag={TagType.AnchorLink} />
      </div>
      {!isMobile && <SearchBar className='header-search-bar' />}
      {user && (
        <div className='header-options'>
          {isMobile && (
            <SearchOutlinedIcon
              onClick={() => setIsSearchMode(true)}
              className='header__search-icon'
              color='white'
              width={24}
              height={24}
            />
          )}
          <Button className='' theme='transparent' disabled hidden>
            <ObsOutlinedIcon className='header-options-icon' />
          </Button>
          <UploadVideo className='header-upload-video' />
          <Button theme='transparent' disabled>
            <NotificationsOutlinedIcon className='header-options-icon' />
          </Button>
          <UserDropdown className='header-user-dropdown' avatar={user.account?.avatar} />
        </div>
      )}

      {!user && (
        <div>
          {isMobile && (
            <SearchOutlinedIcon
              onClick={() => setIsSearchMode(true)}
              className='header__search-icon'
              color='white'
              width={24}
              height={24}
            />
          )}
          <Button
            className='header-sign-in'
            v2
            type='brand'
            variant='secondary'
            size='medium'
            onClick={handleLogin}
            disabled={authInitialized}
          >
            <Text color='currentColor' size='body-small'>
              {t('signIn')}
            </Text>
          </Button>
        </div>
      )}
    </div>
  )
}

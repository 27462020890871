/* eslint-disable complexity */
import { useState, FC } from 'react'
import cn from 'classnames'
import { SortIcon, Button, FilterIcon } from '@ting/ting-ui-components'
import { FilterParam, useFilterParams } from '@@ting/utils/actions'
import { useMobile } from '@@ting/utils/hooks'
import { DesktopFilter } from './DesktopFilter'
import { MobileFilter } from './MobileFilter'
import { SelectValue } from './types'

import './filter.scss'

const sortIcon = <SortIcon />

export const getFilterCount = (filterParams: FilterParam) => {
  let filterCount = 0
  if (filterParams.languageFilter) {
    if (Array.isArray(filterParams.languageFilter)) {
      if (filterParams.languageFilter.length > 0) {
        filterCount += 1
      }
    } else {
      filterCount += 1
    }
  }
  if (filterParams.durationFilter && filterParams.durationFilter.value) {
    filterCount += 1
  }
  if (filterParams.tagsFilter.length) {
    filterCount += 1
  }
  return filterCount
}

type Props = {
  languagesList: SelectValue[]
  isLoading?: boolean
}
const Filter: FC<Props> = props => {
  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const isMobile = useMobile()
  const { filterParams } = useFilterParams()
  const { languagesList, isLoading } = props

  const handleOpenFilter = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const filterCount = getFilterCount(filterParams)

  if (isMobile) {
    return (
      <>
        <Button
          icon={<FilterIcon width={24} height={24} />}
          className='trending-mobile-filter'
          theme='outline'
          size='large'
          onClick={handleOpenFilter}
          disabled={isLoading}
        >
          {filterCount > 0 && <i>{filterCount}</i>}
        </Button>
        {filterIsOpen && <MobileFilter onClose={handleOpenFilter} languagesList={languagesList} />}
      </>
    )
  }

  return (
    <div className='filter'>
      <div className='filter_btn'>
        <Button
          icon={filterCount ? null : sortIcon}
          theme='outline'
          size='large'
          onClick={handleOpenFilter}
          className={cn(filterIsOpen && 'active')}
          disabled={isLoading}
        >
          {filterCount > 0 && <i>{filterCount}</i>} Filters
        </Button>
      </div>
      {filterIsOpen && <DesktopFilter languagesList={languagesList} />}
    </div>
  )
}

export default Filter

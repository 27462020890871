import { FC, Ref, useRef, useImperativeHandle, useEffect } from 'react'
import cn from 'classnames'
import SlickSlider, { Settings } from 'react-slick'

type Props = Settings & {
  containerClassName?: string
  wheelThreshold?: number
  slickRef?: Ref<SlickSlider>
  arrowStick?: boolean
  middleKeyScroll?: boolean
}

export const Slider: FC<Props> = ({
  containerClassName,
  wheelThreshold,
  slickRef,
  arrowStick,
  middleKeyScroll = false,
  ...settings
}) => {
  const sliderRef = useRef<SlickSlider>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const wheelDeltaRef = useRef(0)

  useImperativeHandle(slickRef, () => sliderRef.current)

  useEffect(() => {
    const container = containerRef.current
    const sliderElem = sliderRef.current
    let sizeChangelistener: any
    if (!container || !wheelThreshold) {
      return null
    }
    if (sliderElem && arrowStick) {
      const handleLeftArrowPosition = () => {
        if (!(sliderElem as any).innerSlider) return
        const sliderTrackRef = (sliderElem as any).innerSlider.list.parentNode
        const postitionSlider = sliderTrackRef.getBoundingClientRect()
        const arrowsElements = sliderTrackRef.querySelectorAll('.slick-arrow')
        if (arrowsElements[0]) {
          arrowsElements[0].style.left = `-${postitionSlider.x}px`
        }
      }
      handleLeftArrowPosition()
      sizeChangelistener = window.addEventListener('resize', handleLeftArrowPosition)
    }

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault()
      const slider = sliderRef.current
      if (!slider) {
        return
      }

      const delta = event.deltaY || event.deltaX
      const accumulatedDelta = wheelDeltaRef.current + delta
      const direction = accumulatedDelta / wheelThreshold
      if (direction > 1) {
        slider.slickNext()
        wheelDeltaRef.current = 0
      } else if (direction < -1) {
        slider.slickPrev()
        wheelDeltaRef.current = 0
      } else {
        wheelDeltaRef.current = accumulatedDelta
      }
    }

    if (middleKeyScroll) container.addEventListener('wheel', handleWheel)
    return () => {
      if (middleKeyScroll) container.removeEventListener('wheel', handleWheel)
      if (sizeChangelistener) {
        window.removeEventListener('size', sizeChangelistener)
      }
    }
  }, [wheelThreshold, arrowStick, middleKeyScroll])

  return (
    <div className={cn('slick-container', containerClassName)} ref={containerRef}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SlickSlider ref={sliderRef} {...settings} />
    </div>
  )
}

Slider.defaultProps = {
  wheelThreshold: 50,
}

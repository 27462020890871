import { useQuery, useInfiniteQuery, useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import {
  VideoChannelsService,
  VideosService,
  PlaylistService,
  Sort,
  CreateChannelType,
  UploadChannelCoverType,
} from '@@ting/services/api'
import { getNextPageParam } from '@@ting/utils/getNextPageParams'
import { queryClient } from '@@ting/index'
import {
  GetMyChannelsQueryObjectType,
  GetChannelVideosQueryObjectType,
  GetChannelPlaylistsQueryObjectType,
} from '@@ting/services/api/query-hooks/types'
import { FilterParam } from '@@ting/utils/actions'

export const useGetMyChannels = (accountName: string, queryObject: GetMyChannelsQueryObjectType = {}) =>
  useInfiniteQuery(
    [VideoChannelsService.getVideoChannelsOfUser.name, accountName, queryObject],
    ({ pageParam = 0 }) =>
      VideoChannelsService.getVideoChannelsOfUser({ accountName, start: pageParam, ...queryObject }),
    { enabled: !!accountName, getNextPageParam, refetchOnMount: 'always' }
  )

export const useGetChannelVideos = (channelHandle: string, queryObject: GetChannelVideosQueryObjectType) =>
  useInfiniteQuery(
    [VideosService.getChannelVideos.name, channelHandle, queryObject],
    ({ pageParam = 0 }) => VideosService.getChannelVideos({ channelHandle, start: pageParam, ...queryObject }),
    { getNextPageParam, refetchOnMount: 'always' }
  )

export const useGetChannelPlaylists = (channelHandle: string, queryObject: GetChannelPlaylistsQueryObjectType = {}) =>
  useInfiniteQuery(
    [PlaylistService.getChannelPlaylists.name, channelHandle, queryObject],
    ({ pageParam = 0 }) => PlaylistService.getChannelPlaylists({ channelHandle, start: pageParam, ...queryObject }),
    { enabled: !!channelHandle }
  )

export const useGetChannelDetails = (channelHandle: string) =>
  useQuery([VideoChannelsService.getVideoChannelDetails, channelHandle], () =>
    VideoChannelsService.getVideoChannelDetails({ channelHandle })
  )

export type ChannelSubscribersQueryObject = {
  search?: string
  filter?: string
  count?: number
}
export const useInfiniteChannelSubscribers = (queryObject: ChannelSubscribersQueryObject) =>
  useInfiniteQuery(
    [VideoChannelsService.getChannelSubscribers.name, queryObject],
    ({ pageParam = 0 }) => VideoChannelsService.getChannelSubscribers(pageParam, queryObject),
    {
      getNextPageParam,
    }
  )

export const useUpdateChannel = () =>
  useMutation([VideoChannelsService.updateChannel.name], VideoChannelsService.updateChannel, {
    onSuccess: () => {
      queryClient.invalidateQueries(VideoChannelsService.getVideoChannelsOfUser.name)
    },
  })

export const useDeleteChannel = () =>
  useMutation([VideoChannelsService.deleteChannel.name], VideoChannelsService.deleteChannel, {
    onSuccess: () => {
      queryClient.invalidateQueries(VideoChannelsService.getVideoChannelsOfUser.name)
    },
  })

export const useUploadChannelAvatar = () =>
  useMutation([VideoChannelsService.uploadChannelAvatar.name], VideoChannelsService.uploadChannelAvatar, {
    onSuccess: () => {
      queryClient.invalidateQueries(VideoChannelsService.getVideoChannelDetails.name)
    },
  })

export const useUploadChannelCover = () =>
  useMutation<AxiosResponse, AxiosError, UploadChannelCoverType>(
    [VideoChannelsService.uploadChannelCoverImage.name],
    VideoChannelsService.uploadChannelCoverImage,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VideoChannelsService.getVideoChannelDetails.name)
      },
    }
  )

export const useCreateChannel = () =>
  useMutation<AxiosResponse, AxiosError, CreateChannelType, unknown>(
    [VideoChannelsService.createChannel.name],
    VideoChannelsService.createChannel,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VideoChannelsService.getVideoChannelsOfUser.name)
      },
    }
  )
// TODO add sort parameter
export const useGetPopularChannels = (start?: number, count?: number, sort?: Sort, filterParams?: FilterParam) =>
  useQuery([VideoChannelsService.getChannels.name, start, count, sort, filterParams], () =>
    VideoChannelsService.getChannels({ start, count, sort, filterParams })
  )

import { lazy, Suspense, FC } from 'react'
import { Loading } from '@ting/ting-ui-components'

export const lazyComponent: (module: () => Promise<any>, loading?: boolean) => FC = (lazyModule, loading) => {
  const LazyComponent = lazy(lazyModule)
  return props => (
    <Suspense fallback={loading ? <Loading /> : () => {}}>
      <LazyComponent {...props} />
    </Suspense>
  )
}

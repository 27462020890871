import { FC } from 'react'
import cn from 'classnames'
import { Skeleton } from '@ting/ting-ui-components'

type ProfileCardSkeletonProps = {
  className?: string
}

export const ProfileCardSkeleton: FC<ProfileCardSkeletonProps> = ({ className }) => (
  <div className={cn(className, 'd-flex m-2')}>
    <Skeleton variant='circle' />
    <div className='m-2' style={{ width: '80%' }}>
      <Skeleton className='mb-2' />
      <Skeleton width='50%' />
    </div>
  </div>
)

import { FC } from 'react'
import { VideoCardSkeleton } from '@@ting/molecules/Skeleton'
import './infiniteVideoList.scss'

type InfiniteVideoListSkeletonProps = {
  isMobile: boolean
}
const InfiniteVideoListSkeleton: FC<InfiniteVideoListSkeletonProps> = ({ isMobile }) => (
  <>
    {[...Array(isMobile ? 2 : 16).keys()].map((item: number) => (
      <VideoCardSkeleton key={item} />
    ))}
  </>
)
export default InfiniteVideoListSkeleton

export const timeFrameOptions = [
  {
    value: 0,
    label: 'Any',
  },
  {
    value: 240,
    label: 'Short(>4min)',
  },
  {
    value: 6000,
    label: 'Medium(>10min)',
  },
  {
    value: 60000,
    label: 'Large(>1hour)',
  },
]

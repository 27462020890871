import { useTranslation } from 'react-i18next'
import { IPlayList } from '@@ting/models'
import { PlayListUpdateQuery, useUpdatePlaylist } from '@@ting/services/api/query-hooks'
import { useSnackBar } from '@ting/ting-ui-components'

export const useEditPlaylist = () => {
  const editPlaylistMutation = useUpdatePlaylist()
  const showSnackBar = useSnackBar()
  const { t } = useTranslation('playlist')

  const handleEditPlaylist = async (playList: IPlayList) => {
    const data: PlayListUpdateQuery = {
      id: playList.id.toString(),
      displayName: playList.displayName,
      privacy: playList.privacy.id.toString(),
      videoChannelId: playList.videoChannel ? playList.videoChannel?.id.toString() : null,
    }
    let message = ''
    try {
      await editPlaylistMutation.mutateAsync(data)
      message = t('editPlaylistSuccessfully', { playListName: playList.displayName })
    } catch (error) {
      message = t('failEditingPlaylist')
    }
    showSnackBar({ message })
  }

  return { editPlaylist: handleEditPlaylist }
}

import { createReducer } from 'redux-act'
import { authInitiated, authFinished } from './actions'

const initialState = {
  authInProcess: false,
}

const handleAuthInitialized = state => ({
  ...state,
  authInProcess: true,
})
const handleAuthFinished = state => ({
  ...state,
  authInProcess: false,
})

const actions = {
  [authInitiated]: handleAuthInitialized,
  [authFinished]: handleAuthFinished,
}

const reducer = createReducer(actions, initialState)

export default reducer

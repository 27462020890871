import { FC, useRef } from 'react'
import cn from 'classnames'
import { ArrowLeftIcon, Checkbox } from '@ting/ting-ui-components'
import { Action } from 'redux-act'
import { HistoryStatePopPayload } from '@@ting/redux/actions'
import { FilterPage, SelectValue } from '../../../types'
import './mobileFiltersSelectedLanguages.scss'

type Props = {
  className?: string
  title?: string
  setPage: { push: (newValue: FilterPage) => void; pop: () => Action<HistoryStatePopPayload, any> }
  selectedLanguages: SelectValue[]
  handleCheck: (arg: SelectValue) => void
}

const TrendingMobileFiltersSelectedLanguages: FC<Props> = props => {
  const { className = '', title, setPage, selectedLanguages, handleCheck } = props
  const keepSelectedLanguages = useRef<SelectValue[]>(selectedLanguages)

  const handleBack = () => {
    setPage.pop()
  }

  return (
    <div className={cn('tmf__container', className)}>
      <header className='tmf__header'>
        <ArrowLeftIcon width={20} height={20} className='tmf__back' onClick={handleBack} />
        <span className='tmf__title'>{title}</span>
      </header>
      <div className='tmf__content'>
        {keepSelectedLanguages.current.map(lan => (
          <Checkbox
            id={lan.value}
            name={lan.value}
            bigSize
            key={lan.value}
            onChange={() => handleCheck({ label: lan.label, value: lan.value })}
            checked={Boolean(selectedLanguages.find(item => item.value === lan.value))}
          >
            {lan.label}
          </Checkbox>
        ))}
      </div>
    </div>
  )
}
export default TrendingMobileFiltersSelectedLanguages

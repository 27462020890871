import { axiosInstance } from '@@ting/services/api'
import {
  BasicQueryObjectType,
  PlaylistBatchReorderQuery,
  PlayListCreateQuery,
  PlaylistReorderQuery,
  PlayListUpdateQuery,
  PlaylistVideoDeleteQuery,
  PlayListVideosQuery,
} from './query-hooks'

type GetCommonDataType = BasicQueryObjectType & {
  accountHandle: string
}
const getUserPlaylists = (args: GetCommonDataType) => {
  const { accountHandle, start = 0, count = 15, sort = '-updatedAt' } = args
  return axiosInstance({
    method: 'GET',
    url: `/accounts/${accountHandle}/video-playlists`,
    params: { start, count, sort },
  }).then(res => res.data)
}

type GetChannelPlaylistsType = BasicQueryObjectType & {
  channelHandle: string
}
export const getChannelPlaylists = (args: GetChannelPlaylistsType): Promise<any> => {
  const { channelHandle, start = 0, count = 15, sort = '-updatedAt' } = args
  return axiosInstance({
    method: 'GET',
    url: `/video-channels/${channelHandle}/video-playlists`,
    params: { start, count, sort },
  }).then(res => res.data)
}

type GetPlaylistType = {
  playlistUUID: string
}
const getPlaylist = (args: GetPlaylistType) => {
  const { playlistUUID } = args
  return axiosInstance({
    method: 'GET',
    url: `/video-playlists/${playlistUUID}`,
  }).then(res => res.data)
}

type DeletePlaylistType = {
  playlistId: number
}
const deletePlaylist = (args: DeletePlaylistType) => {
  const { playlistId } = args
  return axiosInstance({
    method: 'DELETE',
    url: `/video-playlists/${playlistId}`,
  }).then(res => res.data)
}

const updatePlaylist = (args: PlayListUpdateQuery) => {
  const formData = new FormData()
  const { id, displayName, privacy = null, videoChannelId = null } = args
  formData.append('displayName', displayName)
  if (privacy) {
    formData.append('privacy', privacy)
  }
  if (videoChannelId) {
    formData.append('videoChannelId', videoChannelId)
  }
  return axiosInstance({
    method: 'PUT',
    url: `/video-playlists/${id}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  }).then(res => res.data)
}

const createNewPlaylist = (args: PlayListCreateQuery) => {
  const { displayName, description = null, privacy = null, thumbnailfile = null, videoChannelId = null } = args
  const formData = new FormData()
  formData.append('displayName', displayName)
  if (description) {
    formData.append('description', description)
  }
  if (privacy) {
    formData.append('privacy', privacy.value)
  }
  if (thumbnailfile) {
    formData.append('thumbnailfile', thumbnailfile)
  }
  if (videoChannelId) {
    formData.append('videoChannelId', videoChannelId)
  }

  return axiosInstance({
    method: 'POST',
    url: '/video-playlists',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(res => res.data)
}

const getPlaylistVideos = (args: GetPlaylistType) => {
  const { playlistUUID } = args
  return axiosInstance({
    method: 'GET',
    url: `/video-playlists/${playlistUUID}/videos`,
  }).then(res => res.data)
}

const addVideoToPlaylist = (args: PlayListVideosQuery) => {
  const { uuid, videoId, startTimestamp = null, stopTimestamp = null } = args
  return axiosInstance({
    method: 'POST',
    url: `/video-playlists/${uuid}/videos`,
    data: {
      videoId: [videoId],
      ...(startTimestamp && { startTimestamp }),
      ...(stopTimestamp && { stopTimestamp }),
    },
  }).then(res => res.data)
}

const deleteVideoFromPlaylist = (args: PlaylistVideoDeleteQuery) => {
  const { uuid, playlistElementId } = args
  return axiosInstance({
    method: 'DELETE',
    url: `/video-playlists/${uuid}/videos/${playlistElementId}`,
  }).then(res => res.data)
}

const reorderPlaylist = (args: PlaylistReorderQuery) => {
  const { id, insertAfterPosition, startPosition, reorderLength = null } = args
  return axiosInstance({
    url: `/video-playlists/${id}/videos/reorder`,
    method: 'POST',
    data: {
      insertAfterPosition,
      startPosition,
      reorderLength,
    },
  }).then(res => res.data)
}

const batchReorderPlaylist = (args: PlaylistBatchReorderQuery) => {
  const { id, startPositions, insertAfterPosition } = args
  return axiosInstance({
    url: `/video-playlists/${id}/videos/reorderBatch`,
    method: 'POST',
    data: {
      startPositions,
      insertAfterPosition,
    },
  }).then(res => res.data)
}

type GetVideoPlaylistOfVideoType = { videoId: string | number }
const getVideoPlaylistOfVideo = (args: GetVideoPlaylistOfVideoType) => {
  const { videoId } = args
  return axiosInstance({
    method: 'GET',
    url: `/users/me/video-playlists/videos-exist?videoIds=${videoId}`,
  }).then(res => res.data)
}

export const PlaylistService = {
  getUserPlaylists,
  getChannelPlaylists,
  getPlaylist,
  deletePlaylist,
  updatePlaylist,
  createNewPlaylist,
  getPlaylistVideos,
  addVideoToPlaylist,
  deleteVideoFromPlaylist,
  reorderPlaylist,
  batchReorderPlaylist,
  getVideoPlaylistOfVideo,
}

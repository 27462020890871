import { FC, useState, useEffect, useRef, useCallback, memo, SyntheticEvent } from 'react'
import cn from 'classnames'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'

import { Button, Popover, Text, CloseCrossIcon } from '@ting/ting-ui-components'
import { useForceUpdate } from '@@ting/utils/hooks'
import { TingLocalStorage } from '@@ting/utils/storage'
import Routes from '@@ting/enums/routes'
import routeGenerator from '@@ting/helpers'

import './SearchBar.scss'
import { RewindClockOutlinedIcon, SearchOutlinedIcon } from '@ting/ting-ui-components/dist/icons'

const SEARCH_HISTORY = 'searchHistory'
const MAX_HISTORY_SIZE = 50

type Props = {
  className?: string
}

type FormValues = {
  query: string
}

const initialFormValues: FormValues = {
  query: '',
}

export const SearchBar: FC<Props> = ({ className }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const forceUpdate = useForceUpdate()
  const containerRef = useRef<HTMLDivElement>()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const query = location.search.split('?query=')[1]
    initialFormValues.query = query ? decodeURIComponent(query) : ''
  }, [location])

  useEffect(() => {
    window.addEventListener('resize', forceUpdate)
    return () => {
      window.removeEventListener('resize', forceUpdate)
    }
  }, [forceUpdate])

  const onSearchSubmit = useCallback(
    (query: string) => {
      addQueryToHistory(query.trim())
      forceUpdate()
      history.push(
        routeGenerator(Routes.Common.search, null, {
          search_query: query,
        })
      )
    },
    [forceUpdate, history]
  )

  const onInputSubmit = useCallback(
    ({ query }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      if (query) {
        setSubmitting(false)
        onSearchSubmit(query)
        setIsPopoverOpen(false)
      }
    },
    [onSearchSubmit]
  )

  const removeQuery = useCallback(
    (query: string) => {
      removeQueryFromHistory(query)
      forceUpdate()
    },
    [forceUpdate]
  )

  const handleOnFocus = () => {
    setIsPopoverOpen(true)
  }

  const handleBlur = () => {
    setIsPopoverOpen(false)
  }

  const recentHistory = readQueriesFromHistory()
  return (
    <div className={cn('search-bar', className)} ref={containerRef}>
      <Formik enableReinitialize onSubmit={onInputSubmit} initialValues={initialFormValues}>
        <Form className='search-bar-form' autoComplete='off'>
          <Field
            type='text'
            className='search-bar-input'
            name='query'
            placeholder='Search'
            onFocus={handleOnFocus}
            onBlur={handleBlur}
          />
          <Button
            type='submit'
            className='search-bar-submit'
            theme='transparent'
            icon={<SearchOutlinedIcon height={24} width={24} />}
          />
        </Form>
      </Formik>
      <Popover
        className='search-popover-wrapper '
        target={containerRef.current}
        offset={[0, 1]}
        isOpen={recentHistory.length > 0 && isPopoverOpen}
      >
        <div className='recent-history'>
          <div className='history-items'>
            {recentHistory.map(query => (
              <SearchItem key={query} value={query} onDelete={removeQuery} onSubmit={onSearchSubmit} />
            ))}
          </div>
        </div>
      </Popover>
    </div>
  )
}

type SearchItemProps = {
  value: string
  onDelete: (query: string) => void
  onSubmit: (query: string) => void
}
const SearchItem: FC<SearchItemProps> = memo(({ value, onDelete, onSubmit }) => (
  <div
    className='search-item'
    onClick={() => {
      onSubmit(value)
    }}
  >
    <div>
      <RewindClockOutlinedIcon className='search-item__icon' height={24} width={24} />
      <Text type='sub' size='small' className='search-item-text'>
        {value}
      </Text>
    </div>
    <Button
      theme='transparent'
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation()
        onDelete(value)
      }}
    >
      <CloseCrossIcon width={12} height={12} />
    </Button>
  </div>
))

// TODO special characters problem, like space
function addQueryToHistory(query: string) {
  const history = TingLocalStorage.getItem(SEARCH_HISTORY)
  let parsedHistory: string[] = history ? JSON.parse(history) : []
  if (parsedHistory.find(item => item === query)) return
  if (parsedHistory.length >= MAX_HISTORY_SIZE) {
    parsedHistory = parsedHistory.slice(1)
  }
  parsedHistory.push(query)
  TingLocalStorage.setItem(SEARCH_HISTORY, JSON.stringify(parsedHistory))
}

function readQueriesFromHistory(): string[] {
  const history = TingLocalStorage.getItem(SEARCH_HISTORY)
  return history ? JSON.parse(history) : []
}

function removeQueryFromHistory(query: string) {
  const history = TingLocalStorage.getItem(SEARCH_HISTORY)
  let parsedHistory: string[] = history ? JSON.parse(history) : []
  parsedHistory = parsedHistory.filter(q => q !== query)
  localStorage.setItem(SEARCH_HISTORY, JSON.stringify(parsedHistory))
}

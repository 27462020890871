import { FC, useRef, useState } from 'react'
import cn from 'classnames'
import { format as formatDate } from 'date-fns'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

// Atoms
import { PlaylistIcon, Text, Tag, Link, RoundImage, ThreeDotsIcon, Modal } from '@ting/ting-ui-components'
import { ContextMenu, CreateEditPlaylistFormType, CreatePlaylistModal } from '@@ting/molecules'
import defaultChannelAvatar from '@@ting/assets/img/tempChannelImage.png'
import { IPlayList } from '@@ting/models'
import { useMobile } from '@@ting/utils/hooks'
import configs from '@@ting/configs'
import Routes from '@@ting/enums/routes'
import routeGenerator from '@@ting/helpers'

// Styles / Assets
import './PlaylistListItem.scss'

type Props = {
  className?: string
  playlist: IPlayList
  playlistDetailsLink: string
  showChannelInfo: boolean
  onDeletePlaylist: (playList: IPlayList) => Promise<void>
  onEditPlaylist: (playList: IPlayList) => Promise<void>
}
export const PlaylistListItem: FC<Props> = props => {
  const { className = '', playlist, playlistDetailsLink, showChannelInfo, onDeletePlaylist, onEditPlaylist } = props
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const contextRef = useRef<HTMLDivElement>()
  const [editPlaylistModalOpen, setEditPlaylistModalOpen] = useState(false)
  const togglePlaylistModalOpen = () => {
    setEditPlaylistModalOpen(!editPlaylistModalOpen)
  }
  const contextMenuItems = [
    { label: 'Edit playlist', onClick: () => setEditPlaylistModalOpen(true) },
    {
      label: 'Delete playlist',
      onClick: () => onDeletePlaylist(playlist),
    },
  ]

  const redirectToDetails = (e: any) => {
    if (!contextRef.current?.contains(e.target)) {
      dispatch(push(playlistDetailsLink))
    }
  }

  const lastUpdate = formatDate(new Date(playlist.updatedAt), 'do LLLL yyyy')

  const handleLinkClick: React.MouseEventHandler<HTMLDivElement> = e => {
    dispatch(
      push(
        routeGenerator(
          Routes.Channel.channelsListID,
          {
            id: playlist.videoChannel.name,
          },
          null
        )
      )
    )
    e.stopPropagation()
  }
  const handleSubmit = (playListData: CreateEditPlaylistFormType) => {
    onEditPlaylist({
      ...playlist,
      ...{
        privacy: {
          id: Number(playListData.privacy.value),
          label: playListData.privacy.label as 'Private' | 'Public',
        },
        displayName: playListData.displayName,
      },
    })
    togglePlaylistModalOpen()
  }
  return (
    <>
      <Modal onClose={togglePlaylistModalOpen} isOpen={editPlaylistModalOpen} scrollable>
        <CreatePlaylistModal onSubmit={handleSubmit} data={playlist} />
      </Modal>
      <div className={cn('playlist-list-item', className)} onClick={redirectToDetails}>
        <div
          className='playlist-poster-container'
          style={{ backgroundImage: `url(${configs.app.fileServer}${playlist.thumbnailPath})` }}
        >
          {isMobile && (
            <Tag size='large' className='playlist-poster-container__tag'>
              {playlist.privacy.label}
            </Tag>
          )}
          <div className='playlist-visibility'>
            <span>{playlist.privacy.label}</span>
          </div>
          <div className='playlist-settings'>
            <ThreeDotsIcon className='rotate-90' />
          </div>
          <div className='playlist-block-count'>
            <span>{playlist.videosLength}</span>
            <PlaylistIcon />
          </div>
        </div>
        <div className='play-list-item__meta'>
          <Link href={playlistDetailsLink} tag='RouterLink'>
            <Text type='main' size='medium'>
              {playlist.displayName}
            </Text>
          </Link>
          <Text type='sub' size='small' className='mt-1'>
            Last update on {lastUpdate}
          </Text>
          <div className='d-flex flex-row align-items-center mt-3 align-self-start'>
            {!isMobile && <Tag size='large'>{playlist.privacy.label}</Tag>}
            {playlist.videoChannel && showChannelInfo && (
              <div onClick={handleLinkClick} className='d-flex'>
                <RoundImage
                  src={
                    playlist.videoChannel.avatar
                      ? `${configs.app.fileServer}${playlist.videoChannel.avatar?.path}`
                      : defaultChannelAvatar
                  }
                  width={36}
                  className='ml-5 mr-2'
                />
                <span className='d-flex flex-column mt-1'>
                  <Text type='sub' size='small'>
                    {playlist.videoChannel.displayName}
                  </Text>
                  <Text type='sub' size='tiny' className='d-block ml-1 mt-1'>
                    channel playlist
                  </Text>
                </span>
              </div>
            )}
          </div>
        </div>
        {playlist.type.label !== 'Watch later' && (
          // eslint-disable-next-line react/jsx-handler-names
          <div ref={contextRef} className='ml-auto'>
            <ContextMenu items={contextMenuItems} className='ml-auto' />
          </div>
        )}
      </div>
    </>
  )
}

import { FC, useState } from 'react'
import cn from 'classnames'
import { HistoryStatePopPayload } from '@@ting/redux/actions'
import { Action } from 'redux-act'
import { Checkbox, SearchIcon, TextField, Option } from '@ting/ting-ui-components'
import { useFilterParams } from '@@ting/utils/actions'
import TrendingMobileFiltersSelectedLanguages from './MobileFiltersSelectedLanguages'
import { SelectValue, FilterPage } from '../../types'

import './mobileFiltersLanguages.scss'

type Props = {
  className?: string
  title?: string
  setPage: { push: (newValue: FilterPage) => void; pop: () => Action<HistoryStatePopPayload, any> }
  page: FilterPage
  languagesList: SelectValue[]
}

// TODO move this to use the same thing from api hook
const languageFormat = (languagesList: Record<string, string>) => {
  const result = []
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(languagesList)) {
    result.push({
      value: key,
      label: value as string,
    })
  }
  return result
}

const popularLanguages = languageFormat({
  en: 'English',
  es: 'Spanish',
  zh: 'Chinese',
  fr: 'French',
  de: 'German',
})
const TrendingMobileFiltersLanguages: FC<Props> = props => {
  const { className = '', title, setPage, page, languagesList } = props

  const { filterParams, filterParamsActions } = useFilterParams()

  const [selectedLanguages, setSelectedLanguages] = useState<SelectValue[]>(
    filterParams.languageFilter as unknown as SelectValue[]
  )
  const [searchValue, setSearchValue] = useState('')

  const handleCheck = (checkedValue: SelectValue) => {
    if (selectedLanguages.find(item => item.value === checkedValue.value)) {
      setSelectedLanguages(selectedLanguages.filter(item => item.value !== checkedValue.value))
    } else {
      setSelectedLanguages([...selectedLanguages, checkedValue])
    }
  }

  const handleDone = () => {
    filterParamsActions.changeLanguage(selectedLanguages as unknown as Option)
    setPage.pop()
  }

  return (
    <>
      <div className={cn('tmf__container', className)}>
        <header className='tmf__header tmf__header-language'>
          <div className='tmf__header-meta'>
            <span className='tmf__cancel' onClick={() => setPage.pop()}>
              cancel
            </span>
            <span className='tmf__title'>{title}</span>
            <span className='tmf__done' onClick={handleDone}>
              Done
            </span>
          </div>
          <TextField
            type='text'
            value={searchValue}
            className='tmf__language-filter'
            onChange={(e: any) => setSearchValue(e.target.value)}
            icon={<SearchIcon width={20} height={20} />}
            placeholder='Search languages'
          />
        </header>
        <div className='tmf__content'>
          <Checkbox
            id='selectedLanguages'
            bigSize
            name='all'
            onChange={selectedLanguages.length > 0 && filterParamsActions.clearLanguage}
            checked={selectedLanguages.length === 0}
          >
            All
          </Checkbox>
          {!searchValue && (
            <div className='tmfl__section tmfl__section_border'>
              <span className='tmfl__section-title'>Most popular languages</span>
              {popularLanguages.map(lan => (
                <Checkbox
                  id={lan.value}
                  name='popularLanguages'
                  bigSize
                  key={lan.value}
                  onChange={() => handleCheck({ label: lan.label, value: lan.value })}
                  checked={selectedLanguages && Boolean(selectedLanguages.find(item => item.value === lan.value))}
                >
                  {lan.label}
                </Checkbox>
              ))}
            </div>
          )}
          <div className='tmfl__section tmfl__section_all-languages'>
            {languagesList.map(lan => {
              if (lan.label.match(new RegExp(searchValue, 'gi'))) {
                return (
                  <Checkbox
                    id={lan.value}
                    name='languagesList'
                    bigSize
                    key={lan.value}
                    onChange={() => handleCheck({ label: lan.label, value: lan.value })}
                    checked={Boolean(selectedLanguages.find(item => item.value === lan.value))}
                  >
                    {lan.label}
                  </Checkbox>
                )
              }
              return null
            })}
          </div>
        </div>
        {selectedLanguages.length > 0 && (
          <div className='tmfl__selected' onClick={() => setPage.push(FilterPage.SELECTED_LANGUAGES)}>
            {`Show Selected (${selectedLanguages.length})`}
          </div>
        )}
      </div>
      {page === FilterPage.SELECTED_LANGUAGES && (
        <TrendingMobileFiltersSelectedLanguages
          setPage={setPage}
          handleCheck={handleCheck}
          title='Selected Languages'
          selectedLanguages={selectedLanguages}
        />
      )}
    </>
  )
}

export default TrendingMobileFiltersLanguages

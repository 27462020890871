import categories from '@@ting/configs/categories'

type ListItem = {
  name: string
  id: string
}
type Prop = { name?: string; id?: string; list?: ListItem[] }

export const getCategoryNameOrID = ({ name, id, list = categories }: Prop) =>
  id ? list.find((item: ListItem) => item.id === id)?.name : list.find((item: ListItem) => item.name === name)?.id

enum User {
  playlists = '/playlists',
  playlistsList = '/playlists/list',
  playlistsUUID = '/playlists/:uuid',
  account = '/account',
  history = '/history',
  subscriptions = '/subscriptions',
  accounts = '/accounts/:name',
  settings = '/settings',
}
export default User

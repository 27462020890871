import { FC } from 'react'
import { Heading, Text } from '@ting/ting-ui-components'

export const EmptyPage: FC = () => (
  <div className='text-center w-100 mt-5'>
    <Heading type='h3'>Whoa!</Heading>
    <Text size='medium' type='sub'>
      such emptiness
    </Text>
  </div>
)

import { createAction } from 'redux-act'

export const getVideoDetails = createAction<{ videoUUID: string }>('getVideoDetails')
export const getVideoDetailsSuccess = createAction('getVideoDetailsSuccess')
export const getVideoDetailsFailure = createAction('getVideoDetailsFailure')

export const getVideoDescription = createAction<{ videoId: number }>('getVideoDescription')
export const getVideoDescriptionSuccess = createAction('getVideoDescriptionSuccess')
export const getVideoDescriptionFailure = createAction('getVideoDescriptionFailure')

export const getVideoComments = createAction<{ videoId: number; sort?: string }>('getVideoComments')
export const getVideoCommentsSuccess = createAction('getVideoCommentsSuccess')
export const getVideoCommentsFailure = createAction('getVideoCommentsFailure')

export const getVideoCommentReplies = createAction<{ videoId: number; commentId: number }>('getVideoCommentReplies')
export const getVideoCommentRepliesSuccess = createAction('getVideoCommentRepliesSuccess')
export const getVideoCommentRepliesFailure = createAction('getVideoCommentRepliesFailure')

// Weather user has liked or disliked the video
export const getVideoRating = createAction<{ videoId: number }>('getVideoRating')
export const getVideoRatingSuccess = createAction('getVideoRatingSuccess')
export const getVideoRatingFailure = createAction('getVideoRatingFailure')

// Weather user has liked or disliked the video
export const getSimilarVideos = createAction<{
  tags: string[]
  start?: number
  count?: number
  sort?: string
  searchTarget?: string
}>('getSimilarVideos')
export const getSimilarVideosSuccess = createAction('getSimilarVideosSuccess')
export const getSimilarVideosFailure = createAction('getSimilarVideosFailure')

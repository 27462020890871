import configs from '@@ting/configs'

export function getAbsoluteAPIUrl() {
  let absoluteAPIUrl = configs.app.fileServer

  if (!absoluteAPIUrl) {
    // The API is on the same domain
    absoluteAPIUrl = window.location.origin
  }

  return absoluteAPIUrl
}
export function durationToString(duration: number) {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration % 3600) / 60)
  const seconds = duration % 60

  const minutesPadding = minutes >= 10 ? '' : '0'
  const secondsPadding = seconds >= 10 ? '' : '0'
  const displayedHours = hours > 0 ? `${hours.toString()}:` : ''

  return `${displayedHours + minutesPadding + minutes.toString()}:${secondsPadding}${seconds.toString()}`.replace(
    /^0/,
    ''
  )
}

export function getAbsoluteEmbedUrl() {
  let absoluteEmbedUrl = configs.app.embedUrl
  if (!absoluteEmbedUrl) {
    // The Embed is on the same domain
    absoluteEmbedUrl = window.location.origin
  }

  return absoluteEmbedUrl
}

export function appendFormDataIfTruthy(formData: FormData, key: string, value: any) {
  if (typeof value !== 'undefined' && value !== null) {
    formData.append(key, value)
  }
}

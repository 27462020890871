import { useEffect } from 'react'
import { push } from 'connected-react-router'
import { TingLocalStorage } from '@@ting/utils/storage'

import { authFinished, authInitiated } from './actions'

import { authorizer } from './Authorizer'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { UserService } from '@@ting/services/api'
import { throttle } from '@@ting/utils/throttle'
import { queryClient } from '@@ting/index'
import { cancelSignUp } from '@@ting/services/SignUpService/actions'
import Routes from '@@ting/enums/routes'

export const loginSideEffect = () => {
  const { pathname, search } = window.location
  const redirectLoginTo = pathname + search
  if (!redirectLoginTo.startsWith(Routes.Auth.login)) {
    TingLocalStorage.setItem('loginRedirectTo', redirectLoginTo)
  }
  authorizer.makeAuthorizationRequest()
}

export const logInCallbackSideEffect = async dispatch => {
  dispatch(authInitiated())

  await authorizer.signinCallback()
  const redirectLoginTo = TingLocalStorage.getItem('loginRedirectTo') || '/'
  TingLocalStorage.removeItem('loginRedirectTo')

  if (authorizer.accessToken) {
    dispatch(authFinished())
  }

  dispatch(push(redirectLoginTo))
}

export const logOutSideEffect = async dispatch => {
  TingLocalStorage.removeItem('user')
  await authorizer.revokeAccessToken()
  queryClient.removeQueries(UserService.getUserInfo.name)
  dispatch(authFinished())
  dispatch(cancelSignUp())
  dispatch(push('/'))
}

// export const logOutCallbackSideEffect = async (getState, dispatch) => {
//   await authorizer.signoutCallback()
//   dispatch(authFinished())
//   dispatch(push('/'))
// }

const throttledLoginCallback = throttle(logInCallbackSideEffect, 200)
const throttledLogin = throttle(loginSideEffect, 200)
// const throttledLogoutCallback = throttle(logOutCallbackSideEffect, 200)

export const useHandleLocationChangedSideEffect = async () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  useEffect(() => {
    const handleAsyncAwait = async () => {
      if (pathname === Routes.Auth.loginCallback) {
        await throttledLoginCallback(dispatch)
      }

      if (pathname === Routes.Auth.login) {
        await throttledLogin(dispatch)
      }

      // if (pathname === LOGOUT_REDIRECTION_CALLBACK_PATH) {
      //   await throttledLogoutCallback(dispatch)
      // }
    }
    handleAsyncAwait()
  }, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps
}

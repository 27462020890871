import { FC, useRef, useState } from 'react'
import cn from 'classnames'
import configs from '@@ting/configs'
import { Loading, UserAvatar, useSnackBar, Button } from '@ting/ting-ui-components'
import validateImageUpload from '@@ting/helpers/validateImageUpload'

import './UploadAvatar.scss'
import { readFile } from '@@ting/helpers/cropImage'
import { CropImage } from '@@ting/molecules/CropImage'
import { queryClient } from '@@ting/index'
import { UserService } from '@@ting/services/api'
import { UserAvatarSizes } from '@@ting/models'

type Props = {
  path?: string
  alt?: string
  className?: string
  onAvatarChanged?: (file: File) => Promise<any>
  size?: UserAvatarSizes
  userName?: string
  coreId?: string
  hasButton?: boolean
}

const UploadAvatar: FC<Props> = props => {
  const inputRef = useRef(null)
  const imageDataUrl = useRef(null)
  const { path, alt, className = '', size, userName, onAvatarChanged, coreId, hasButton } = props
  const showSnackBar = useSnackBar()
  const [selectedImage, setSelectedImage] = useState(null)
  const [isCropImageOpen, setIsCropImageOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onUpdateImage = (file: File) => {
    setSelectedImage(imageDataUrl.current)
    setIsLoading(true)
    onAvatarChanged(file)
      .then(() => {
        setIsLoading(false)
        queryClient.invalidateQueries([UserService.getUserInfo.name])
      })
      .catch(() => setSelectedImage(null))
      .finally(() => setIsLoading(false))

    setIsCropImageOpen(false)
  }

  const handleInputOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (inputRef) {
      inputRef.current.click(e)
    }
  }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length) {
      switch (validateImageUpload(e.target.files[0], configs.images.maxUserAvatarSize)) {
        case 'valid':
          imageDataUrl.current = await readFile(e.target.files[0])
          setIsCropImageOpen(true)
          break
        case 'invalidSize':
          showSnackBar({
            message: `This file is too large, maximum size is ${Math.floor(
              configs.images.maxUserAvatarSize / 1024 / 1024
            )} Megabytes`,
          })
          imageDataUrl.current = null
          break
        case 'invalidFormat':
          showSnackBar({
            message: 'Please, make sure it is of the following type : jpg, png, jpeg',
          })
          imageDataUrl.current = null
          break
        default:
          onAvatarChanged(e.target.files[0])
          break
      }
    }
  }
  const imgSrc = selectedImage || (path && `${configs.app.fileServer}${path}`)

  return (
    <>
      <input type='file' ref={inputRef} hidden onChange={handleChange} />
      <div onClick={handleInputOpen} className={cn('upload-avatar', className)}>
        {isLoading && <Loading />}
        <UserAvatar image={imgSrc} alt={alt} size={size} userName={userName} coreID={coreId} />
      </div>
      {hasButton && (
        <Button v2 variant='ghost' type='brand' onClick={handleInputOpen}>
          Upload Picture
        </Button>
      )}
      <CropImage
        image={imageDataUrl.current}
        onSave={onUpdateImage}
        isOpen={isCropImageOpen}
        onClose={() => setIsCropImageOpen(false)}
        cropShape='round'
      />
    </>
  )
}

export default UploadAvatar

import { axiosInstance } from '@@ting/services/api'
import { FilterParam } from '@@ting/utils/actions'
import { BasicQueryObjectType, GetMyChannelsQueryObjectType } from './query-hooks'

type GetChannelsType = BasicQueryObjectType & {
  filterParams?: FilterParam
}
const getChannels = ({ start = 0, count = 5, sort, filterParams }: GetChannelsType) =>
  // TODO add sort parameter
  axiosInstance({
    method: 'GET',
    url: '/video-channels',
    params: {
      start,
      count,
      sort,
      filterParams,
    },
  }).then(res => res.data)

type GetVideoChannelsOfUser = GetMyChannelsQueryObjectType & { accountName: string }
export const getVideoChannelsOfUser = ({
  accountName,
  withStats = false,
  start = 0,
  count = 15,
  sort = undefined,
  withVideos = false,
  videosStart = undefined,
  videosCount = undefined,
  videosSort = undefined,
  includePrivateVideos = undefined,
}: GetVideoChannelsOfUser) =>
  axiosInstance({
    method: 'GET',
    url: `/accounts/${accountName}/video-channels`,
    params: {
      withStats,
      start,
      count,
      sort,
      withVideos,
      videosStart,
      videosCount,
      videosSort,
      includePrivateVideos,
    },
  }).then(res => res.data)

type GetVideoChannelDetailsType = { channelHandle: string }
export const getVideoChannelDetails = ({ channelHandle }: GetVideoChannelDetailsType) =>
  axiosInstance({
    method: 'GET',
    url: `/video-channels/${channelHandle}`,
  }).then(res => res.data)

const getChannelSubscribers = (pageParam = 0, queryObject = {}) =>
  axiosInstance({
    method: 'GET',
    url: `/videos?start=${pageParam}&${new URLSearchParams(queryObject).toString()}`,
  }).then(res => res.data)

type DeleteChannelType = {
  channelHandle: string
}
const deleteChannel = ({ channelHandle }: DeleteChannelType) =>
  axiosInstance({
    method: 'DELETE',
    url: `/video-channels/${channelHandle}`,
  }).then(res => res.data)

type UpdateChannelType = {
  channelHandle: string
  displayName: string
  description: string
  website: string
  tags: string[]
  languages: (string | number)[]
  groups: (string | number)[]
  hasCover: boolean
}
// TODO: add categories and languages on server fix
const updateChannel = ({
  channelHandle,
  displayName,
  description,
  website,
  tags,
  languages,
  groups,
  hasCover,
}: UpdateChannelType) =>
  axiosInstance({
    method: 'PUT',
    url: `/video-channels/${channelHandle}`,
    data: {
      displayName,
      description,
      website,
      languages,
      groups,
      tags,
      hasCover,
    },
  }).then(res => res.data)
export type CreateChannelType = {
  displayName: string
  description: string
  website: string
  tags: string[]
  name: string
}
// TODO: add categories and languages on server fix
const createChannel = ({ name, displayName, description, website, tags }: CreateChannelType) =>
  axiosInstance({
    method: 'POST',
    url: '/video-channels/',
    data: {
      name,
      displayName,
      description,
      website,
      tags,
    },
  }).then(res => res.data)

type UploadChannelAvatarType = { channelHandle: string; avatarFile: File }
const uploadChannelAvatar = async ({ channelHandle, avatarFile }: UploadChannelAvatarType) => {
  const formData = new FormData()
  formData.append('avatarfile', avatarFile)

  return axiosInstance({
    method: 'POST',
    url: `/video-channels/${channelHandle}/avatar/pick`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(res => res.data)
}
export type UploadChannelCoverType = { channelHandle: string; coverimagefile: File }
const uploadChannelCoverImage = async ({ channelHandle, coverimagefile }: UploadChannelCoverType) => {
  const formData = new FormData()
  formData.append('coverimagefile', coverimagefile)

  return axiosInstance({
    method: 'POST',
    url: `/video-channels/${channelHandle}/coverImage`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(res => res.data)
}

export const VideoChannelsService = {
  getChannels,
  getVideoChannelsOfUser,
  getVideoChannelDetails,
  deleteChannel,
  updateChannel,
  createChannel,
  uploadChannelAvatar,
  uploadChannelCoverImage,
  getChannelSubscribers,
}

import { FC } from 'react'
import { Skeleton } from '@ting/ting-ui-components'

type CategoriesNavSkeletonProps = {
  isMobile: boolean
}
const CategoriesNavSkeleton: FC<CategoriesNavSkeletonProps> = ({ isMobile }) => (
  <div className='d-flex'>
    {[...Array(isMobile ? 2 : 10).keys()].map((item: number) => (
      <Skeleton
        key={item}
        className='mr-3 rounded d-inline-block position-relative flex-shrink-0'
        variant='rect'
        width={120}
        height={40}
      />
    ))}
  </div>
)
export default CategoriesNavSkeleton

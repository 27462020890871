/* eslint-disable import/no-mutable-exports */
import { proxify, MemoryStorage } from './memoryStorage'

let TingSessionStorage: Storage
try {
  TingSessionStorage = sessionStorage
} catch (err) {
  const instanceSessionStorage = new MemoryStorage()

  TingSessionStorage = proxify(instanceSessionStorage)
}
export { TingSessionStorage }

enum Channel {
  videoChannels = '/video-channels',

  channels = '/channels',
  videoPreview = '/video-channels/preview',
  publicChannel = '/video-channels/:channelHandle',

  channelsList = '/channels/list',
  channelsListID = '/channels/list/:id',
}
export default Channel

import { axiosInstance } from '@@ting/services/api'

type SignUpType = {
  profileName: string
  userName: string
  termsChecked: boolean
  channelName: string
  channelHandle: string
}
const signUp = ({ profileName, userName, termsChecked, channelName, channelHandle }: SignUpType) =>
  axiosInstance({
    method: 'POST',
    url: '/users/register',
    data: {
      username: userName,
      displayName: profileName,
      terms: termsChecked,
      channel: {
        displayName: channelName,
        name: channelHandle,
      },
    },
  }).then(res => res.data)

export const AuthService = {
  signUp,
}

import { QueryPage, QueryPages } from '@@ting/services/api/query-hooks'
import { InfiniteData } from 'react-query'

export const emptyInfiniteDataQueryPage: InfiniteData<QueryPage<any>> = {
  pages: [],
  pageParams: [],
}

export const emptyResultList: QueryPage<any> = {
  total: 0,
  data: [],
}

export const emptyQueryPages: QueryPages<any> = {
  pages: [emptyResultList],
}

import { useQuery } from 'react-query'
import { CommonService } from '@@ting/services/api/common'
import { fetchOnceOptions } from '@@ting/configs/modules'

export const useGetCategories = () =>
  useQuery([CommonService.getCategories.name], CommonService.getCategories, fetchOnceOptions)

export const useGetLanguages = () =>
  useQuery([CommonService.getLanguages.name], CommonService.getLanguages, fetchOnceOptions)

export const useGetPrivacies = () =>
  useQuery([CommonService.getPrivacies.name], CommonService.getPrivacies, fetchOnceOptions)

export const useGetLicenses = () =>
  useQuery([CommonService.getLicenses.name], CommonService.getLicenses, fetchOnceOptions)

import React from 'react'
import { useGetInfiniteVideos } from '@@ting/services/api/query-hooks'
import { useMobile } from '@@ting/utils/hooks'
import { CarouselSkeleton } from '@@ting/molecules/Skeleton'
import getFlatDataFromPages from '@@ting/helpers/getFlatDataFromPages'
import { useFilterParams } from '@@ting/utils/actions'
import VideoCarousel from '@@ting/molecules/VideoCarousel'

const PopularVideosContainer: React.FC = () => {
  const { filterParams } = useFilterParams()
  const {
    data: trendingVideos,
    isLoading: isTrendingVideosLoading,
    isError: isTrendingVideosError,
  } = useGetInfiniteVideos({
    sort: '-trending',
    count: 10,
    filterParams,
  })
  const {
    data: popularVideos,
    isLoading: isPopularVideosLoading,
    isError: isPopularVideosError,
  } = useGetInfiniteVideos({
    sort: '-views',
    count: 10,
    filterParams,
  })
  const isMobile = useMobile()
  const isLoading = isTrendingVideosLoading || isPopularVideosLoading

  if (isLoading) {
    return (
      <div className='my-5'>
        <CarouselSkeleton className='mb-4' itemsCount={8} isMobile={isMobile} />
        <CarouselSkeleton className='mt-4' itemsCount={8} isMobile={isMobile} />
      </div>
    )
  }
  return (
    <>
      <VideoCarousel title='Trending Now' videos={isTrendingVideosError ? [] : getFlatDataFromPages(trendingVideos)} />
      <VideoCarousel title='Popular Videos' videos={isPopularVideosError ? [] : getFlatDataFromPages(popularVideos)} />
    </>
  )
}

export default PopularVideosContainer

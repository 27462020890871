import { FC, useRef, useState } from 'react'
import { CameraIcon } from '@ting/ting-ui-components'

import './UploadCover.scss'

type Props = {
  path: string
  accept?: string
  onCoverImageChange: any
}

const UploadCover: FC<Props> = props => {
  const { path, accept, onCoverImageChange } = props
  const inputRef = useRef(null)
  const [file, setFile] = useState<string>(null)

  const handleInputOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    inputRef.current.click(e)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length) setFile(URL.createObjectURL(e.target.files[0]))
    if (onCoverImageChange) {
      onCoverImageChange(e.target.files[0])
    }
  }

  const backgroundImage = {
    backgroundImage: file ? `url(${file})` : `url(${path})`,
  }

  return (
    <>
      <input type='file' id='uploadCover' accept={accept || '*'} ref={inputRef} hidden onChange={handleChange} />
      <div onClick={handleInputOpen} className='uploadCover' style={backgroundImage}>
        <div className='uploadCover_camera'>
          <CameraIcon />
        </div>
      </div>
    </>
  )
}

export default UploadCover

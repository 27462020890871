import { axiosInstance, Sort } from '@@ting/services/api'
import configs from '@@ting/configs'
import { store } from '@@ting/index'
import { startSignUp } from '../SignUpService/actions'
import { GetMyVideoHistoryObjectType } from './query-hooks/types'

const getUsersList = (search?: string) =>
  axiosInstance({
    method: 'GET',
    url: '/accounts',
    params: {
      search,
    },
  }).then(res => res.data)

const getUserInfo = () =>
  axiosInstance({
    method: 'GET',
    url: '/users/me',
  })
    .then(res => res.data)
    .catch(error => {
      if (error?.response.status) store.dispatch(startSignUp())
    })

const getOtherUserInfo = (userId: number) =>
  axiosInstance({
    method: 'GET',
    url: `/accounts/byId/${userId}`,
  }).then(res => res.data)

const updateUserInfo = (payload: any) =>
  axiosInstance({
    method: 'PUT',
    url: '/users/me',
    data: payload,
  })

type UploadUserAvatarParams = {
  avatarFile?: File
}

const uploadUserAvatar = async ({ avatarFile }: UploadUserAvatarParams) => {
  const formData = new FormData()
  formData.append('avatarfile', avatarFile)

  return axiosInstance({
    method: 'POST',
    url: '/users/me/avatar/pick',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(res => res.data)
}

type GetMyVideosParams = {
  start?: number
  count?: number
  sort?: Sort
}

const getMyVideos = ({ start = 0, count = 15, sort = undefined }: GetMyVideosParams) =>
  axiosInstance({
    method: 'GET',
    url: '/users/me/videos',
    params: {
      start,
      count,
      sort,
    },
  }).then(res => res.data)

const getPublicAccount = (name: string) =>
  axiosInstance({
    method: 'GET',
    url: `/accounts/${name}`,
  }).then(res => res.data)

const getMyVideoHistory = ({
  start = 0,
  count = 15,
  search = '',
  endDate,
  startDate,
  sort,
  durationMax,
}: GetMyVideoHistoryObjectType) =>
  axiosInstance({
    method: 'GET',
    url: '/users/me/history/videos',
    params: {
      start,
      count,
      search,
      startDate,
      endDate,
      durationMax,
      sort,
    },
  }).then(res => res.data)

type DeleteFromMyVideoHistoryParams = {
  videoId: number | string
}

const deleteFromMyVideoHistory = ({ videoId }: DeleteFromMyVideoHistoryParams) =>
  axiosInstance({
    method: 'DELETE',
    url: `/users/me/history/videos/${videoId}`,
  }).then(res => res.data)

type ClearMyVideoHistoryParams = {
  beforeDate: Date
}

const clearMyVideoHistory = ({ beforeDate }: ClearMyVideoHistoryParams) => {
  const formData = new FormData()
  formData.append('beforeDate', beforeDate.toISOString())

  return axiosInstance({
    method: 'POST',
    url: '/users/me/history/videos/remove',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(res => res.data)
}

type GetMyFollowingParams = {
  start?: number
  count?: number
  sort?: Sort
}

export const getMyFollowing = ({ start, count, sort }: GetMyFollowingParams) =>
  axiosInstance({
    method: 'GET',
    url: '/users/me/follow',
    params: {
      start,
      count,
      sort,
    },
  }).then(res => res.data)

export const followAChannel = (channelHandle: string) =>
  axiosInstance({
    method: 'POST',
    url: '/users/me/follow',
    data: { uri: channelHandle },
  })

export const unfollowAChannel = (channelHandle: string) =>
  axiosInstance({
    method: 'DELETE',
    url: `/users/me/follow/${channelHandle}`,
  })

type UploadCoverImageParams = {
  coverImageFile: File
}

const uploadCoverImage = async ({ coverImageFile }: UploadCoverImageParams) => {
  const formData = new FormData()
  formData.append('coverimagefile', coverImageFile)

  return axiosInstance({
    method: 'POST',
    url: '/users/me/cover-image/pick',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(res => res.data)
}

const deleteMyUser = () =>
  axiosInstance({
    method: 'DELETE',
    url: '/users/me',
  }).then(res => res.data)

const getBlacklistAccounts = (search?: string) =>
  axiosInstance({
    method: 'GET',
    url: '/users/me/blocklist/accounts',
    params: {
      search,
    },
  }).then(res => res.data)

const deleteBlacklistAccount = (accountName: string) =>
  axiosInstance({
    method: 'DELETE',
    url: `/users/me/blocklist/accounts/${accountName}`,
  }).then(res => res.data)

const addBlacklistAccount = ({ accountName }: { accountName: string }) =>
  axiosInstance({
    method: 'POST',
    url: '/users/me/blocklist/accounts',
    data: { accountName },
  })

// eslint-disable-next-line no-unused-vars
const isFollowingAChannel = (uris: string[] = []) =>
  axiosInstance({
    method: 'get',
    url: '/users/me/follow/exist',
    params: {
      uris,
    },
  }).then(res => res.data)

const getUserFollowingList = (accountHandle: string, search: string) =>
  axiosInstance({
    method: 'GET',
    url: `/accounts/${accountHandle}/followings`,
    params: {
      search,
    },
  }).then(res => res.data)

const getUserFollowersList = (accountHandle: string, search: string) =>
  axiosInstance({
    method: 'GET',
    url: `/accounts/${accountHandle}/followers`,
    params: {
      search,
    },
  }).then(res => res.data)

const followAccount = (accountHandle: string) => {
  const params = new URLSearchParams()
  params.append('accountName', accountHandle)
  return axiosInstance({
    method: 'POST',
    url: '/accounts/follow',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: params,
  }).then(() => accountHandle)
}

const unfollowAccount = (accountHandle: string) =>
  axiosInstance({
    method: 'DELETE',
    url: `/accounts/follow/${accountHandle}`,
  }).then(() => accountHandle)

const isFollowAccountExist = (accountHandle: string) =>
  axiosInstance({
    method: 'GET',
    url: `/accounts/follow/exist?accountName=${accountHandle}`,
  })
    .then(() => [accountHandle, true])
    .catch(() => [accountHandle, false])

const getCorePassVerifiedFields = (coreId: string) =>
  axiosInstance({
    method: 'POST',
    url: `${configs.app.KEYCLOAK_DOMAIN}/kyc/isverified`,
    data: {
      user: coreId,
      names: [
        'IDCardFullName',
        'IDCardDob',
        'IDCardExpiryDate',
        'IDCardIssueDate',
        'IDCardDocumentNumber',
        'IDCardGender',
        'IDCardCountry',
        'IDCardDocumentPhoto',
        'IDCardFacePhoto',
        'PassportFullName',
        'PassportDob',
        'PassportExpiryDate',
        'PassportIssueDate',
        'PassportDocumentNumber',
        'PassportGender',
        'PassportCountry',
        'PassportDocumentPhoto',
        'PassportFacePhoto',
        'ResidencePermitFullName',
        'ResidencePermitDob',
        'ResidencePermitExpiryDate',
        'ResidencePermitIssueDate',
        'ResidencePermitDocumentNumber',
        'ResidencePermitGender',
        'ResidencePermitCountry',
        'ResidencePermitDocumentPhoto',
        'ResidencePermitFacePhoto',
        'DriverLicenseFullName',
        'DriverLicenseDob',
        'DriverLicenseExpiryDate',
        'DriverLicenseIssueDate',
        'DriverLicenseDocumentNumber',
        'DriverLicenseGender',
        'DriverLicenseCountry',
        'DriverLicenseDocumentPhoto',
        'DriverLicenseFacePhoto',
        'Email',
        'Phone',
      ],
    },
    headers: {
      'Content-Type': 'text/plain',
    },
  }).then(res => res.data)

export const UserService = {
  getUsersList,
  getUserInfo,
  getMyVideos,
  updateUserInfo,
  uploadUserAvatar,
  getPublicAccount,
  getMyVideoHistory,
  deleteFromMyVideoHistory,
  clearMyVideoHistory,
  getMyFollowing,
  unfollowAChannel,
  followAChannel,
  uploadCoverImage,
  deleteMyUser,
  getBlacklistAccounts,
  deleteBlacklistAccount,
  addBlacklistAccount,
  isFollowingAChannel,
  getUserFollowingList,
  getUserFollowersList,
  followAccount,
  unfollowAccount,
  isFollowAccountExist,
  getOtherUserInfo,
  getCorePassVerifiedFields,
}

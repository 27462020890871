/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Formik } from 'formik'

// Atoms
import {
  Text,
  SelectBox,
  Checkbox,
  Link,
  useSnackBar,
  Button,
  Modal,
  SelectValue,
  Option,
} from '@ting/ting-ui-components'

// Molecules
import { CreatePlaylistModal } from '@@ting/molecules'

// Utils
import { IPlayList } from '@@ting/models'

// query hooks
import {
  QueryPage,
  useGetMyChannels,
  useGetUserPlaylists,
  useGetVideoPlaylistOfVideo,
  useCreateNewPlaylist,
  useAddVideoToPlaylist,
  useGetUserInfoWhenNeed,
} from '@@ting/services/api/query-hooks'
import createInfiniteDataFormat from '@@ting/helpers/createInfiniteDataFormat'
import { useTranslation } from 'react-i18next'
import { channelSelectOptions } from './utils'
import './SaveToPlaylistModal.scss'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

type Props = {
  videoId: number
  onSave: () => void
}

const formatGroupLabel = (data: { label: string }) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
)

export const SaveToPlaylistModal: FC<Props> = ({ videoId, onSave }) => {
  const [filteredPlaylists, setFilteredPlaylists] = useState(null)
  const [showCreatePlaylist, setShowCreatePlaylist] = useState(false)
  const [selectedChannel, setSelectedChannel] = useState(null)
  const { t } = useTranslation(['playlist', 'common'])

  // data fetching
  const { data: user } = useGetUserInfoWhenNeed()
  const { data: playlists } = useGetUserPlaylists(user?.username, { start: 0, count: 15, sort: '-createdAt' }) as {
    data: QueryPage<IPlayList>
  }
  const { data: channels } = useGetMyChannels(user?.username, {})
  const { data: rawPlaylistsOfVideo } = useGetVideoPlaylistOfVideo(videoId)
  const playlistsOfVideo = rawPlaylistsOfVideo?.data

  // mutation functions
  const createNewPlaylist = useCreateNewPlaylist()
  const addVideoToPlaylist = useAddVideoToPlaylist()

  const showSnackBar = useSnackBar()

  const [selectedPlaylist, setSelectedPlaylist] = useState(null)
  const [addPlayListInfo, setAddPlayListInfo] = useState([])
  const [removePlayListInfo, setRemovePlayListInfo] = useState([])
  const [playlistMenuOpen, setPlaylistMenuOpen] = useState(false)

  // Show / hide create playlist section
  const toggleShowCreatePlaylist = () => {
    setShowCreatePlaylist(!showCreatePlaylist)
  }

  // Handle selected channel changed
  const handleChannelSelectChanged = (option: SelectValue) => {
    setSelectedChannel((option as Option).value)
    setSelectedPlaylist(null)
  }

  //
  const handlePlaylistCheckboxChanged = (uuid: string, playlistId: number, checked: boolean) => {
    if (!checked) {
      if (removePlayListInfo.find(playList => playList.uuid === uuid)) {
        setRemovePlayListInfo(removePlayListInfo.filter(playList => playList.uuid !== uuid))
      }
      setAddPlayListInfo([...addPlayListInfo, { uuid, videoId, playlistId }])
    } else {
      if (addPlayListInfo.find(playList => playList.uuid === uuid)) {
        setAddPlayListInfo(addPlayListInfo.filter(playList => playList.uuid !== uuid))
      }
      const { playlistElementId } = playlistsOfVideo?.find(p => p.playlistId === playlistId) || {}
      setRemovePlayListInfo([...removePlayListInfo, { uuid, playlistElementId, playlistId }])
    }
  }

  // Create a new playlist and update the component
  const handleCreatePlaylistSubmit = async (newPlaylist: any) => {
    try {
      // First create the new playlist and get the uuid
      const {
        videoPlaylist: { id },
      } = await createNewPlaylist.mutateAsync({
        ...newPlaylist,
        videoChannelId: selectedChannel > 0 ? selectedChannel : undefined,
      })

      // Then add the video to this new playlist
      await addVideoToPlaylist.mutateAsync({ uuid: id, videoId })

      showSnackBar({ message: t('theVideoHasBeenSuccessfullyAddedToNewPlaylist') })
      setShowCreatePlaylist(false)
    } catch (err) {
      setShowCreatePlaylist(false)
      showSnackBar({ message: t('unableToAddVideoToPlaylist') })
    }
  }

  // Update filtered playlists upon change on selectedChannel and playlists
  useEffect(() => {
    if (selectedChannel === -1) {
      setFilteredPlaylists(playlists?.data.filter(pl => pl.videoChannel === null))
    } else {
      setFilteredPlaylists(playlists?.data.filter(pl => pl.videoChannel?.id === selectedChannel))
    }
  }, [selectedChannel, playlists])

  const handleSaveDisable = () => addPlayListInfo.length === 0 && removePlayListInfo.length === 0

  const handleSave = async () => {
    const addRequests = addPlayListInfo.map(({ playlistId, videoId: videoIdArg }) =>
      addVideoToPlaylist.mutateAsync({
        uuid: playlistId,
        videoId: videoIdArg,
      })
    )
    try {
      await Promise.all([...addRequests])
      if (onSave) {
        onSave()
      }
      showSnackBar({ message: t('changeHasBeenSuccessfullySaved') })
    } catch (e) {
      showSnackBar({ message: t('somethingWentWrongPleaseTryAgain') })
    }
  }
  const renderPlaylist = (playlist: IPlayList) => {
    const checkedFunction = (): boolean => {
      if (addPlayListInfo.find(p => p.playlistId === playlist.id)) {
        return true
      }
      if (removePlayListInfo.find(p => p.playlistId === playlist.id)) {
        return false
      }
      return Boolean(playlistsOfVideo?.find(p => p.playlistId === playlist.id))
    }

    const checked = checkedFunction()
    return (
      <div
        key={playlist.id}
        onClick={() => {
          handlePlaylistCheckboxChanged(playlist.uuid, playlist.id, checked)
          setSelectedPlaylist({ value: playlist.uuid, label: playlist.displayName })
        }}
        className='playlist-checkbox-container'
      >
        <Text type='main' size='small' className='font-weight-normal'>
          {playlist.displayName}
        </Text>
        <Checkbox
          name={`pl-${playlist.uuid}`}
          value={playlist.uuid}
          checked={selectedPlaylist && selectedPlaylist.value === playlist.uuid}
        />
      </div>
    )
  }
  return (
    <div className='save-to-playlist-modal'>
      <Text type='main' size='medium' className='font-weight-normal'>
        {t('saveVideoTo')}...
      </Text>
      <SelectBox
        className='channel-select-box mt-4'
        placeholder='Select a channel'
        isSearchable
        size='large'
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
          singleValue: (base: any) => ({
            ...base,
            fontSize: '16px !important',
            lineHeight: '28px !important',
          }),
        }}
        menuPortalTarget={document.getElementById('selectBox')}
        formatGroupLabel={formatGroupLabel}
        options={channelSelectOptions(createInfiniteDataFormat(channels))}
        onChange={handleChannelSelectChanged}
      />
      <div onClick={() => setPlaylistMenuOpen(!playlistMenuOpen)} className='playlist-selection-container'>
        <div className='playlist-selection-container-title'>
          <Text type='main' size='medium' className='font-weight-normal'>
            {t('playlist')}
          </Text>
        </div>
        {filteredPlaylists?.length !== 0 ? (
          filteredPlaylists?.map((playlist: IPlayList) => renderPlaylist(playlist))
        ) : (
          <div className='playlist-selection-container-not-found'>
            <Text type='main' size='medium' className='font-weight-normal'>
              {t('noPlaylistsFound')}
            </Text>
          </div>
        )}
      </div>

      {!showCreatePlaylist && (
        <Link className='new-playlist-link-btn' tag='button' onClick={toggleShowCreatePlaylist}>
          + {t('createNewPlaylist')}
        </Link>
      )}

      <Modal
        containerClassName='create-playlist'
        onClose={() => setShowCreatePlaylist(false)}
        isOpen={showCreatePlaylist}
      >
        <CreatePlaylistModal variant='modal' onSubmit={handleCreatePlaylistSubmit} />
      </Modal>

      <Formik initialValues={{}} onSubmit={handleSave}>
        {({ handleSubmit }) => (
          <>
            <Button
              type='submit'
              onClick={handleSubmit as any}
              className='save-to-playlist-modal__save-btn'
              disabled={handleSaveDisable()}
              theme='primary'
            >
              {t('common:save')}
            </Button>
          </>
        )}
      </Formik>
    </div>
  )
}

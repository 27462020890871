import { createContext, useContext, useReducer } from 'react'

type SidebarMode = 'normal' | 'drawer'
type StoreStates = {
  lang: string
  sidebarMode: SidebarMode
  sidebarStatus: boolean
  drawerStatus: boolean
}

type IConfigProvider = {
  configState: StoreStates
  configActions: {
    toggleSidebar?: () => void
    changeSidebarMode?: (mode: SidebarMode) => void
    changeLang?: (lang: string) => void
  }
}

type ConfigStoreProps = {
  children: React.ReactNode
  initialState?: StoreStates
}

const CHANGE_LANG = 'app/CHANGE_LANG'
const TOGGLE_SIDEBAR = 'app/TOGGLE_SIDEBAR'
const CHANGE_SIDEBAR_MODE = 'app/CHANGE_SIDEBAR_MODE'

const initialStateData: StoreStates = {
  lang: 'en',
  sidebarMode: 'normal',
  sidebarStatus: true,
  drawerStatus: false,
}

const defaultContextValues = {
  configState: initialStateData,
  configActions: {},
}

const ConfigContext = createContext<IConfigProvider>(defaultContextValues)

const ConfigReducer = (configState: StoreStates, configActions: any) => {
  switch (configActions.type) {
    case CHANGE_LANG:
      return { ...configState, lang: configActions.lang }
    case TOGGLE_SIDEBAR:
      return {
        ...configState,
        sidebarStatus: configState.sidebarMode === 'normal' ? !configState.sidebarStatus : configState.sidebarStatus,
        drawerStatus: configState.sidebarMode === 'drawer' ? !configState.drawerStatus : configState.drawerStatus,
      }
    case CHANGE_SIDEBAR_MODE:
      return {
        ...configState,
        drawerStatus: false,
        sidebarMode: configActions.mode,
      }
    default:
      return configState
  }
}

const ConfigStore: React.FC<ConfigStoreProps> = ({ children, initialState = initialStateData }) => {
  const [configState, dispatch] = useReducer(ConfigReducer, initialState)

  const changeLang = (lang: string) => {
    dispatch({
      type: CHANGE_LANG,
      lang,
    })
  }

  const toggleSidebar = () => {
    dispatch({
      type: TOGGLE_SIDEBAR,
    })
  }

  const changeSidebarMode = (mode: SidebarMode) => {
    dispatch({
      type: CHANGE_SIDEBAR_MODE,
      mode,
    })
  }

  const values = {
    configState,
    configActions: {
      changeLang,
      toggleSidebar,
      changeSidebarMode,
    },
  }

  return <ConfigContext.Provider value={values}>{children}</ConfigContext.Provider>
}

const useConfig = () => {
  const context = useContext(ConfigContext)

  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigContext')
  }

  return context
}

export { useConfig }
export default ConfigStore

import { useRef, useEffect, FC, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Text, Button, FormCheckbox, FormTextField } from '@ting/ting-ui-components'
import { DeliveredOutlinedIcon } from '@ting/ting-ui-components/dist/icons'

import UploadAvatar from '../UploadAvatar'
import { NameRules } from './NameRules'

const usernameRegex = /^[a-z0-9._]{1,50}$/

const step1ValidationSchema = Yup.object().shape({
  profileName: Yup.string()
    .min(4, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(usernameRegex, 'The username should be composed of numbers, lowercase letters and _')
    .required('* Required'),
  termsChecked: Yup.boolean().oneOf([true], '* You should read and accept terms of usage.'),
})

type Props = { initialValues: any; errors: any; onStep1Submit: any; onAvatarChange: (file: File) => Promise<unknown> }

export const Step1: FC<Props> = props => {
  const { initialValues, errors, onStep1Submit, onAvatarChange } = props
  const formikRef = useRef(null)
  const [valid, setIsValid] = useState(undefined)

  useEffect(() => {
    const formik = formikRef.current
    if (!formik) {
      return
    }

    Object.entries(errors).forEach(([field, error]) => {
      formik.setFieldError(field, error)
      formik.setFieldTouched(field, true, false)
    })
  }, [errors])

  const handleProfileNameChanged = (e: any) => {
    const formik = formikRef.current
    if (!formik) {
      return
    }
    const value = e.target.value as string
    if (value.length >= 4 && value.length <= 50 && usernameRegex.test(value)) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
    formik.handleChange(e)
  }

  const handleFormSubmit = (formValues: any) => {
    const { termsChecked, profileName } = formValues
    onStep1Submit({ termsChecked, profileName })
  }

  const TextFieldProp =
    valid !== undefined
      ? {
          state: valid ? 'success' : 'error',
        }
      : {}
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={step1ValidationSchema}
    >
      {({ handleSubmit }) => (
        <>
          <div className='upload-profile'>
            <UploadAvatar coreId='profile-avatar' onAvatarChanged={onAvatarChange} size='xxl' hasButton />
          </div>
          <FormTextField
            v2
            className='mt-5'
            name='profileName'
            label='Profile name'
            placeholder='Your public profile name'
            onChange={handleProfileNameChanged}
            size='large'
            endIcon={valid && <DeliveredOutlinedIcon className='success-check-icon' />}
            {...TextFieldProp}
          />
          <NameRules />
          <FormCheckbox id='termsChecked' name='termsChecked' className='mt-5 agreement-checkbox'>
            <Text type='sub' size='small' className='sign-up-text'>
              I am at least 16 years old and agree to the <Text size='small'>terms of service</Text>{' '}
            </Text>
          </FormCheckbox>
          <div className='action'>
            <Button v2 variant='primary' type='brand' className='continue-button' onClick={handleSubmit as any}>
              Next
            </Button>
          </div>
        </>
      )}
    </Formik>
  )
}

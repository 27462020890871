import { FC, ReactNode } from 'react'
import cn from 'classnames'
import { Button } from '@ting/ting-ui-components'

import { useMobile } from '@@ting/utils/hooks'
import './MobileDialogLayout.scss'

type Props = {
  className?: string
  contentClassName?: string
  headerTitle: string
  headerIcon: ReactNode
  onHeaderClick: () => void
}

export const MobileDialogLayout: FC<Props> = ({
  className,
  contentClassName,
  headerTitle,
  headerIcon,
  onHeaderClick,
  children,
}) => {
  const isMobile = useMobile()

  return (
    <div className={cn('mobile-dialog-layout', className)}>
      {isMobile && (
        <div className='layout-header'>
          {headerTitle}
          <Button
            type='button'
            className='header-button'
            theme='transparent'
            size='small'
            icon={headerIcon}
            onClick={onHeaderClick}
          />
        </div>
      )}
      <div className={cn('layout-content', contentClassName)}>{children}</div>
    </div>
  )
}

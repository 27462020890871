import { createAction } from 'redux-act'
import { createFetchAction } from '../../../utils/createFetchAction'

export const [getUserPlaylists, getUserPlaylistsSuccess, getUserPlaylistsFailure] =
  createFetchAction('getUserPlaylists')

export const [createNewPlaylist, createNewPlaylistSuccess, createNewPlaylistFailure] =
  createFetchAction('createNewPlaylist')

export const togglePlaylistModal = createAction<boolean>('togglePlaylistModal')

export const [editPlaylist, editPlaylistSuccess, editPlaylistFailure] = createFetchAction('editPlaylist')

export const [deletePlaylist, deletePlaylistSuccess, deletePlaylistFailure] = createFetchAction('deletePlaylist')

export const [getPlaylistDetails, getPlaylistDetailsSuccess, getPlaylistDetailsFailure] =
  createFetchAction('getPlaylistDetails')

export const [getPlaylistVideos, getPlaylistDetailsVideosSuccess, getPlaylistDetailsVideosFailure] =
  createFetchAction('getPlaylistVideos')

export const [deleteVideoFromPlaylist, deleteVideoFromPlaylistSuccess, deleteVideoFromPlaylistFailure] =
  createFetchAction('deleteVideoFromPlaylist')

export const [getPlaylistsOfVideo, getPlaylistsOfVideoSuccess, getPlaylistsOfVideoFailure] =
  createFetchAction('getPlaylistsOfVideo')

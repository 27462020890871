import { FC } from 'react'
import { Skeleton } from '@ting/ting-ui-components'

type PlayListItemSkeletonProps = {
  isMobile: boolean
}
export const PlayListItemSkeleton: FC<PlayListItemSkeletonProps> = ({ isMobile }) => (
  <>
    {isMobile ? (
      <div className='my-5'>
        <Skeleton variant='rect' height={300} className='m-2 rounded' />
        <Skeleton width='20%' className='m-2' />
        <Skeleton width='40%' className='m-2' />
      </div>
    ) : (
      <div className='d-flex my-1'>
        <Skeleton variant='rect' className='m-2 rounded' width={240} height={135} />
        <div style={{ width: '70%' }} className='d-flex flex-column justify-content-center'>
          <Skeleton width='70%' className='m-2' />
          <Skeleton width='40%' className='m-2' />
          <Skeleton variant='rect' className='m-2 rounded' width={80} height={40} />
        </div>
      </div>
    )}
  </>
)

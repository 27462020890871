import { getLocation } from 'connected-react-router'

import { PATH } from './constants'
import { cancelSignUp, startSignUp, startSubmitting, succeedSubmitting, failSubmitting } from './actions'

export const locationChangeSideEffect = (getState, dispatch) => {
  const { pathname } = getLocation(getState())

  if (pathname === PATH) {
    dispatch(startSignUp())
  }
}

export const signUpSideEffect = async (dispatch, payload, error) => {
  dispatch(startSubmitting())
  const errorMessage = error?.response?.data?.error
  if (errorMessage) {
    if (errorMessage === 'User with this username or corepass id already exists.') {
      dispatch(failSubmitting({ userName: 'The username is already taken' }))
      return
    }
    if (errorMessage === `Channel with name ${payload.channelHandle} already exists.`) {
      dispatch(failSubmitting({ channelHandle: 'The channel handle is already taken' }))
      return
    }
    if (errorMessage == 'Channel name cannot be the same as user username.') {
      dispatch(failSubmitting({ channelHandle: 'Channel id cannot be the same as user username' }))
      return
    }
    dispatch(failSubmitting({ general: errorMessage }))
    return
  }

  dispatch(succeedSubmitting())
  dispatch(cancelSignUp())

  if (window.opener) {
    window.opener.postMessage({ code: 'signedUp' }, '*')
  }
}

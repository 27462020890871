import { Middleware } from 'redux'
import { go } from 'connected-react-router'

import { State as HistoryState } from '@@ting/redux/reducers/historyState'

type State = {
  historyState: HistoryState
}

const clamp = (value: number, minimum: number, maximum: number) => Math.max(Math.min(value, maximum), minimum)

export const historyStateMiddleware: Middleware<any, State> =
  ({ getState, dispatch }) =>
  next =>
  action => {
    const { historyState: previousState } = getState()
    const results = next(action)
    const { historyState: currentState } = getState()

    const { history, index: currentIndex } = currentState
    const previousIndex = history.findIndex(
      ({ locationKey }) => locationKey === previousState.history[previousState.index].locationKey
    )
    const direction = previousIndex !== -1 ? clamp(currentIndex - previousIndex, -1, 1) : 0

    const groupsStart = history.reduce(
      (accumulator, { garbage }, index) => (garbage ? accumulator : [...accumulator, index]),
      [] as number[]
    )
    const firstGroup = 0
    const lastGroup = groupsStart.length - 1
    const previousGroup = groupsStart.reduceRight(
      (group, start) => (start <= previousIndex ? group : group - 1),
      lastGroup
    )
    const currentGroup = groupsStart.reduceRight(
      (group, start) => (start <= currentIndex ? group : group - 1),
      lastGroup
    )
    const nextGroup =
      previousGroup === currentGroup ? clamp(currentGroup + direction, firstGroup, lastGroup) : currentGroup

    let nextIndex = currentIndex
    if (nextGroup === lastGroup) {
      nextIndex = history.length - 1
    } else if (nextGroup === firstGroup) {
      nextIndex = 0
    } else if (nextGroup !== currentGroup) {
      nextIndex = groupsStart[nextGroup]
    }

    const delta = nextIndex - currentIndex
    if (delta !== 0) {
      dispatch(go(delta))
    }

    return results
  }

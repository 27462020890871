export const videoSortOptions = [
  { label: 'Video name', value: '-name' },
  { label: 'Upload date', value: '-publishedAt' },
  { label: 'Create date', value: '-createdAt' },
  { label: 'Views', value: '-views' },
  { label: 'Likes', value: '-likes' },
  { label: 'Trending', value: '-trending' },
]

export const sidebarItemsLimitationCount = 5

export const tagSuggestions = [
  { id: 0, name: 'Germany' },
  { id: 1, name: 'Germans' },
  { id: 2, name: 'English' },
  { id: 3, name: 'Sport' },
  { id: 4, name: 'Entertainment' },
  { id: 5, name: 'School' },
  { id: 6, name: 'Government' },
  { id: 7, name: 'Policy' },
  { id: 8, name: 'Hiking' },
  { id: 9, name: 'Music' },
  { id: 10, name: 'MusicVideo' },
]

export const channelSelectOptions = (channels: { data: { id: string; displayName: string; avatar: any }[] }) => [
  {
    label: 'Channel playlists',
    options: channels?.data.map(channel => ({
      value: channel.id,
      label: channel.displayName,
      icon: channel.avatar && <img src={channel.avatar?.path} alt='channel icon' height='40' width='40' />,
    })),
  },
  {
    label: 'Other playlists',
    options: [{ label: 'Other playlists', value: -1 }],
  },
]

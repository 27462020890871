import auth from '@@ting/services/authorization/reducer'
import signUp from '@@ting/services/SignUpService/reducer'
import watchVideo from '@@ting/containers/WatchContainer/redux/reducers'
import historyState from './historyState'

export default {
  auth,
  signUp,
  watchVideo,
  historyState,
}

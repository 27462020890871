import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { getUniqueClassName } from '@@ting/utils/pageClassname'
import { useConfig } from '@@ting/utils/actions'

import './CustomClassWrapper.scss'

const CustomClassWrapper: FC = props => {
  const { children } = props
  const history = useHistory()
  const { configState } = useConfig()

  const updateClassName = (location: any) => {
    const className = getUniqueClassName(location)

    const topElement = document.getElementsByClassName('ting-app')[0]
    if (className) {
      topElement.className = `ting-app ${className}`
    } else {
      topElement.className = 'ting-app'
    }
  }

  history.listen(updateClassName)
  useEffect(() => {
    updateClassName(window.location)
  })

  return (
    <div
      className={cn('wrapper', {
        'wrapper-expanded-sidebar': configState.sidebarMode === 'normal' && configState.sidebarStatus,
        'wrapper-unexpanded-sidebar': configState.sidebarMode === 'normal' && !configState.sidebarStatus,
      })}
    >
      {children}
    </div>
  )
}

export default CustomClassWrapper

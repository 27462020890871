/* eslint-disable complexity */
import { FC } from 'react'

import { SelectBox, SelectValue as SelectValueType, Option } from '@ting/ting-ui-components'
import MultiValueInput from '@@ting/molecules/MultiValueInput'
import { useFilterParams } from '@@ting/utils/actions'
import { timeFrameOptions } from '../config'
import { SelectValue } from '../types'

import './desktopFilter.scss'

type Props = {
  languagesList: SelectValue[]
}

export const DesktopFilter: FC<Props> = props => {
  const { languagesList } = props
  const { filterParams, filterParamsActions } = useFilterParams()

  return (
    <div className='filter_form'>
      <div>
        <div className='filter_form-label'>
          <span className='label'>Tags</span>
          {filterParams.tagsFilter.length > 0 && <span onClick={filterParamsActions.clearTagsFilter}>Clear</span>}
        </div>
        <MultiValueInput
          onChange={filterParamsActions.handleSetTags}
          placeholder='Tags'
          value={filterParams.tagsFilter}
        />
      </div>
      <div>
        <div className='filter_form-label'>
          <span className='label'>Language</span>
          {filterParams.languageFilter && <span onClick={filterParamsActions.clearLanguage}>Clear</span>}
        </div>
        <SelectBox
          options={languagesList}
          placeholder='Language'
          onChange={lang => filterParamsActions.changeLanguage(lang as Option)}
          isSearchable
          value={filterParams.languageFilter as SelectValueType}
        />
      </div>

      <div>
        <div className='filter_form-label'>
          <span className='label'>Time Frames</span>
          {filterParams.durationFilter && <span onClick={filterParamsActions.clearDuration}>Clear</span>}
        </div>
        <SelectBox
          options={timeFrameOptions}
          placeholder='Time Frame'
          onChange={durationFilter => filterParamsActions.changeDuration(durationFilter as Option)}
          value={filterParams.durationFilter as Option}
        />
      </div>
    </div>
  )
}

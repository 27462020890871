import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMobile } from '@@ting/utils/hooks'
import { Heading } from '@ting/ting-ui-components'
import './DialogTitle.scss'

type Props = {
  uploading: boolean
  uploadingAudio?: boolean
}

export const DialogTitle: FC<Props> = ({ uploadingAudio, uploading }) => {
  const { t } = useTranslation('uploadDialog')
  const isMobile = useMobile()

  const getTitle = () => {
    if (!uploading) return t('upload')
    if (uploadingAudio) return t('uploadAudio')
    return t('uploadVideo')
  }

  if (!isMobile) {
    return (
      <Heading className='dialog-title' type='h1'>
        {getTitle()}
      </Heading>
    )
  }
  return null
}

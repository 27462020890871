import { FC } from 'react'

import { Text, Heading } from '@ting/ting-ui-components'
import UploadCover from '@@ting/molecules/UploadCover'

import './uploadCoverImage.scss'

type Props = {
  imagePath: string
  title: string
  description?: string
  accept?: string
  onCoverImageChange: (e?: File) => void
}

export const UploadCoverImage: FC<Props> = props => {
  const {
    onCoverImageChange,
    imagePath,
    title,
    accept = '.JPG, .PNG, .GIF',
    description = 'JPEG, PNG, GIF (recommended size 1200x480, max. 10 MB)',
  } = props
  return (
    <div className='upload-cover-box'>
      <label htmlFor='uploadCover' className='mt-0'>
        <Heading type='h3'>{title}</Heading>
      </label>
      <Text type='sub' size='small' className='mb-3'>
        {description}
      </Text>
      <UploadCover onCoverImageChange={onCoverImageChange} path={imagePath} accept={accept} />
    </div>
  )
}

/* eslint-disable no-param-reassign */
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { MediaComponent, UserAvatar, Link, CameraIcon, DotSplitter, Text } from '@ting/ting-ui-components'
import { VideoThumbnail } from '@@ting/molecules/VideoThumbnail'
import { relativeTime } from '@@ting/utils/time'
import { shortenBigNumbers } from '@@ting/utils/number'
import configs from '@@ting/configs'
import { useMobile } from '@@ting/utils/hooks'
import { IVideo } from '@@ting/models'

import './VideoPreview.scss'
import routeGenerator from '@@ting/helpers'
import Routes from '@@ting/enums/routes'
import { CheckmarkFilledIcon } from '@ting/ting-ui-components/dist/icons'

type Props = {
  video?: IVideo
  className?: string
  typePreview?: 'small' | 'normal'
}

const VideoPreview: FC<Props> = ({ video, className = '', typePreview = 'normal', children }) => {
  /* remove video extension */
  const videoName = video.name.split('.')[0]
  const isMobile = useMobile()
  const { t } = useTranslation('common')

  const icon = video.account?.avatar ? (
    <UserAvatar
      size='lg'
      alt={video.account.displayName}
      image={`${configs.app.fileServer}${video.account.avatar.path}`}
    />
  ) : (
    <div className='no-icon'>
      <CameraIcon width={16} height={16} />
    </div>
  )

  const renderSmallPreview = (
    <div className={`videoPreview videoPreview-small ${className}`}>
      <VideoThumbnail video={video} size='small' showHover={false} />
      {isMobile ? (
        <MediaComponent icon={icon}>
          <div className='videoPreview-name'>
            <Link tag='RouterLink' href={routeGenerator(Routes.Video.watchVideoUUID, { videoUUID: video.uuid })}>
              <span>{videoName}</span>
            </Link>
          </div>
          <div>
            <div className='videoPreview-span'>
              <span>{video.channel.displayName}</span>
              <DotSplitter />
              <span>
                {video.views} {t('views')}
              </span>
              <DotSplitter />
              <span>{relativeTime(video.createdAt.toString())}</span>
            </div>
          </div>
        </MediaComponent>
      ) : (
        <div className='videoPreview-info videoPreview-info-small'>
          <div className='videoPreview-info-text'>
            <Link
              tag='RouterLink'
              href={routeGenerator(Routes.Video.watchVideoUUID, { videoUUID: video?.uuid })}
              className='videoPreview-name videoPreview-name-small'
            >
              {videoName}
            </Link>
            {video.account && (
              <Link tag='RouterLink' href={`/accounts/${video.account.name}`}>
                <span className='videoPreview-meta'>{video.account.displayName}</span>
              </Link>
            )}
          </div>
        </div>
      )}
      {children}
    </div>
  )

  const renderNormalPreview = (
    <div className={`videoPreview ${className}`}>
      <VideoThumbnail video={video} />
      {isMobile ? (
        <MediaComponent icon={icon}>
          <div className='videoPreview-name'>
            <Link tag='RouterLink' href={routeGenerator(Routes.Video.watchVideoUUID, { videoUUID: video?.uuid })}>
              <span>{videoName}</span>
            </Link>
          </div>
          <div>
            <div className='videoPreview-span'>
              <span>{video.channel.displayName}</span>
              <DotSplitter />
              <span>{video.views} views</span>
              <DotSplitter />
              <span>{relativeTime(video.createdAt.toString())}</span>
            </div>
          </div>
        </MediaComponent>
      ) : (
        <div className='videoPreview-info'>
          {video.account && (
            <Link tag='RouterLink' href={`/accounts/${video.account.name}`}>
              <UserAvatar
                size='lg'
                image={video.account?.avatar ? `${configs.app.fileServer}${video.account.avatar.path}` : ''}
              />
            </Link>
          )}
          <div className='videoPreview-info-text'>
            <Link
              tag='RouterLink'
              href={routeGenerator(Routes.Video.watchVideoUUID, { videoUUID: video?.uuid })}
              className='videoPreview-name'
            >
              {videoName}
            </Link>
            <Text className='videoPreview-username ' size='body-medium'>
              {video.account?.displayName} <CheckmarkFilledIcon width={13} height={13} />
            </Text>
            <span className='videoPreview-meta'>
              {`${shortenBigNumbers(video.views)} Views`}
              <DotSplitter />
              {relativeTime(video.publishedAt as string)}
            </span>
          </div>
        </div>
      )}
      {children}
    </div>
  )

  if (typePreview === 'small') return renderSmallPreview

  return renderNormalPreview
}
export default VideoPreview

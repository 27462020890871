const routeGenerator = (route: string, item: Record<string, string>, queryString?: Record<string, string>) => {
  let newRoute = route
  if (item) {
    Object.entries(item).forEach(([itemKey, itemValue]) => {
      newRoute = newRoute.replace(`:${itemKey}`, itemValue)
    })
  }
  if (queryString) {
    Object.entries(queryString).forEach(([queryStringKey, queryStringValue]) => {
      newRoute = `${newRoute}?${queryStringKey}=${queryStringValue}`
    })
  }

  return newRoute
}

export default routeGenerator

import { useState, useEffect } from 'react'
import copy from 'copy-to-clipboard'

import { useSnackBar } from '@ting/ting-ui-components'

type CopyOption = {
  successDuration?: number
  snackBarMessage?: string
}

export const useCopy = (text: string, options: CopyOption = {}) => {
  const { successDuration, snackBarMessage } = options
  const [isCopied, setIsCopied] = useState(false)
  const showSnackBar = useSnackBar()

  /**
   * Reset the status after a certain number of milliseconds. This is useful
   * for showing a temporary success message.
   */
  useEffect(() => {
    if (isCopied && successDuration) {
      const id = setTimeout(() => {
        setIsCopied(false)
      }, successDuration)

      return () => {
        clearTimeout(id)
      }
    }
    /* get rid of eslint error */
    return () => null
  }, [isCopied, successDuration])

  return [
    isCopied,
    () => {
      const didCopy = copy(text)
      setIsCopied(didCopy)
    },
    (value: string) => {
      const didCopy = copy(value || text)
      setIsCopied(didCopy)
      if (snackBarMessage) {
        showSnackBar({ message: snackBarMessage })
      }
    },
  ] as const
}

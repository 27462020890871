import { Category } from '@@ting/models/category'
import { axiosInstance } from '@@ting/services/api'

const getCategories = (): Promise<Category> =>
  axiosInstance({
    method: 'GET',
    url: '/videos/categories',
  }).then(res => res.data)

const getLanguages = () =>
  axiosInstance({
    method: 'GET',
    url: '/videos/languages',
  }).then(res => res.data)

const getPrivacies = () =>
  axiosInstance({
    method: 'GET',
    url: '/videos/privacies',
  }).then(res => res.data)

const getLicenses = () =>
  axiosInstance({
    method: 'GET',
    url: '/videos/licences',
  }).then(res => res.data)

export const CommonService = {
  getCategories,
  getLanguages,
  getPrivacies,
  getLicenses,
}

import { QueryPage } from '@@ting/services/api/query-hooks'
import { InfiniteData } from 'react-query'
import getFlatDataFromPages from './getFlatDataFromPages'

const createInfiniteDataFormat = (data: InfiniteData<QueryPage<any>>): QueryPage<any> => ({
  data: getFlatDataFromPages(data),
  total: getFlatDataFromPages(data).length,
})

export default createInfiniteDataFormat

import { createAction } from 'redux-act'

export const getMyChannels = createAction<any>('getMyChannels')
export const getMyChannelsSuccess = createAction('getMyChannelsSuccess')
export const getMyChannelsFailure = createAction('getMyChannelsFailure')

export const getMyChannelVideos = createAction('getMyChannelVideos')
export const getMyChannelVideosSuccess = createAction('getMyChannelVideosSuccess')
export const getMyChannelVideosFailure = createAction('getMyChannelVideosFailure')

export const getChannelVideos = createAction('getChannelVideos')
export const getChannelVideosSuccess = createAction('getChannelVideosSuccess')
export const getChannelVideosFailure = createAction('getChannelVideosFailure')

export const getChannelPlaylists = createAction('getChannelPlaylists')
export const getChannelPlaylistsSuccess = createAction('getChannelPlaylistsSuccess')
export const getChannelPlaylistsFailure = createAction('getChannelPlaylistsFailure')

export const getChannelDetails = createAction('getChannelDetails')
export const getChannelDetailsSuccess = createAction('getChannelDetailsSuccess')
export const getChannelDetailsFailure = createAction('getChannelDetailsFailure')

export const getVideoUploadDependencies = createAction('getVideoUploadDependencies')

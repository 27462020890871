/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useCallback, useMemo, useEffect, useDebugValue } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { historyStateMount, historyStateUnmount, historyStatePop } from '@@ting/redux/actions'

// nextId and Id are used to distinguish multiple uses of useHistoryState
// This id is the 'key' for mapping 'values' of this hook usage in redux state
// Because there might be more than one usage of this hook and there should be a way
// to map hook's 'value' into 'redux state values'
let nextId = 0

export const useHistoryState = <T>(initialValue: T) => {
  const history = useHistory<{ historyStatePush: { id: number; value: T } }>()
  const dispatch = useDispatch()

  // Acquire a unique id for this hook usage.
  // If it's the first time this hook is ran (first render, hence using Ref), increment nextId by one for
  // next usage of this hook.
  const idRef = useRef(nextId)
  const id = idRef.current
  if (id === nextId) {
    nextId += 1
  }

  // Initialize a new room in redux state for this hook usage
  // We should also clear up the redux state after un-mounting.
  useEffect(() => {
    dispatch(historyStateMount({ id, initialValue }))
    return () => {
      dispatch(historyStateUnmount({ id }))
    }
  }, [])

  // Get latest value stored in redux state
  const selector = useCallback(state => state.historyState.values[id] || initialValue, [])
  // TODO: fix the type, after migrating redux to TypeScript
  const value = useSelector<any, T>(selector)
  useDebugValue(value)

  const helpers = useMemo(
    () => ({
      push: (newValue: T) =>
        history.push({
          pathname: history.location.pathname,
          search: history.location.search,
          hash: history.location.hash,
          state: { historyStatePush: { id, value: newValue } },
        }),
      pop: () => dispatch(historyStatePop({ id })),
    }),
    []
  )

  return [value, helpers] as const
}

import { FC, Fragment } from 'react'
import { QueryPage } from '@@ting/services/api/query-hooks'
import { Waypoint, PlaylistListItem } from '@@ting/molecules'
import { IPlayList } from '@@ting/models'
import { useMobile } from '@@ting/utils/hooks'
import Routes from '@@ting/enums/routes'
import routeGenerator from '@@ting/helpers'
import { useRemovePlaylist, useEditPlaylist } from '@@ting/hooks'
import InfinitePlayListSkeleton from './InfinitePlayListSkeleton'
import { EmptyPage } from '../EmptyPage'

type InfinitePlayListProps = {
  playLists: QueryPage<IPlayList>
  isLoading: boolean
  isError: boolean
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  showChannelInfo?: boolean
  fetchNextPage?: () => any
}

export const InfinitePlayList: FC<InfinitePlayListProps> = props => {
  const {
    playLists,
    isLoading,
    isError,
    hasNextPage,
    isFetchingNextPage,
    showChannelInfo = true,
    fetchNextPage,
  } = props
  const { deletePlaylist } = useRemovePlaylist()
  const { editPlaylist } = useEditPlaylist()
  const isMobile = useMobile()

  if (playLists?.total === 0) {
    return <EmptyPage />
  }
  return (
    <div className='mt-5'>
      {!isError &&
        playLists?.data.map((playList: IPlayList, index: number) => (
          <Fragment key={playList.id}>
            {index === playLists.data.length - 1 && hasNextPage && <Waypoint onEnter={fetchNextPage} />}
            <PlaylistListItem
              playlist={playList}
              playlistDetailsLink={routeGenerator(Routes.User.playlistsUUID, { uuid: playList.uuid })}
              showChannelInfo={showChannelInfo}
              onDeletePlaylist={deletePlaylist}
              onEditPlaylist={editPlaylist}
            />
          </Fragment>
        ))}
      {(isFetchingNextPage || isLoading) && <InfinitePlayListSkeleton isMobile={isMobile} />}
    </div>
  )
}

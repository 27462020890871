const validateImageUpload = (
  img: File,
  maxSize = 1024 * 1024, // in bytes, equals to 1MB
  validFormats: string[] = ['png', 'jpg', 'jpeg']
): 'invalidSize' | 'invalidFormat' | 'valid' => {
  if (img?.size > maxSize) {
    return 'invalidSize'
  }
  if (!validFormats.includes(img?.type.slice(6, img?.type.length))) {
    return 'invalidFormat'
  }
  return 'valid'
}

export default validateImageUpload

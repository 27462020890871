import { FilterParam } from '@@ting/utils/actions'

export const getFiltrationParams = (state: any): FilterParam => ({
  languageFilter: state.common.languageFilter,
  durationFilter: state.common.durationFilter,
  tagsFilter: state.common.tagsFilter,
  chosenCategory: state.common.chosenCategory,
})

export const getLanguagesListOptions = (state: any) => {
  const { languagesList } = state.common
  const result = []
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(languagesList)) {
    result.push({
      value: key,
      label: value as string,
    })
  }
  return result
}

export const getTagsForQuery = (tags: any) => {
  const result = []
  // eslint-disable-next-line no-restricted-syntax
  for (const item of tags) {
    result.push(item.value)
  }
  return result
}

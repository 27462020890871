import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationRequestHandler,
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
  BaseTokenRequestHandler,
  TokenRequestHandler,
  EndSessionResponse,
  TokenResponse,
  StringMap,
  FetchRequestor,
  Requestor,
  AuthorizationResponse,
  RevokeTokenRequest,
} from '@ting/app-auth'

import { TingLocalStorage } from '@@ting/utils/storage'
import configs from '@@ting/configs'
import Routes from '@@ting/enums/routes'
import { TOKEN_RESPONSE } from './constants'
import { QueryStringUtils } from './QueryStringUtils'

const { origin } = window.location
const settings = {
  // URL to the authentication server (including realm)
  authority: configs.app.KEYCLOAK_DOMAIN,

  // The name of the client in Keycloak setup for this service
  client_id: configs.app.KEYCLOAK_CLIENT,

  // Where to redirect the user to after successful authentication
  redirect_uri: `${origin}${Routes.Auth.loginCallback}`,

  // Where to redirect the user to after logging the user out
  post_logout_redirect_uri: `${origin}${Routes.Auth.logoutCallback}`,

  // "openid" tells the server that this client uses oidc for authentication
  scope: 'offline',
}

class Authorizer {
  private notifier: AuthorizationNotifier
  private authorizationHandler: AuthorizationRequestHandler
  private tokenHandler: TokenRequestHandler

  // state
  private configuration: AuthorizationServiceConfiguration | undefined
  private request: AuthorizationRequest | undefined
  private code: string | undefined
  private tokenResponse: TokenResponse | undefined
  private requestor: Requestor
  private isMakingRefreshTokenRequest = false

  constructor() {
    this.notifier = new AuthorizationNotifier()
    this.authorizationHandler = new RedirectRequestHandler(undefined, new QueryStringUtils())
    this.requestor = new FetchRequestor()
    this.tokenHandler = new BaseTokenRequestHandler(this.requestor)
    // set notifier to deliver responses
    this.authorizationHandler.setAuthorizationNotifier(this.notifier)
    // set a listener to listen for authorization responses
    this.notifier.setAuthorizationListener((request, response) => {
      if (response && response instanceof AuthorizationResponse && request instanceof AuthorizationRequest) {
        this.request = request
        this.code = response.code
      }
      if (response && response instanceof EndSessionResponse) {
        localStorage.removeItem(TOKEN_RESPONSE)
        this.tokenResponse = null
      }
    })

    // retrieve the tokenResponse from localStorage in case it's valid or refreshToken is still usable
    const jsonTokenResponse = JSON.parse(TingLocalStorage.getItem(TOKEN_RESPONSE))
    if (jsonTokenResponse) {
      this.tokenResponse = new TokenResponse(jsonTokenResponse)
    }
  }

  get accessToken() {
    return this.tokenResponse?.accessToken
  }

  fetchServiceConfiguration() {
    return AuthorizationServiceConfiguration.fetchFromIssuer(settings.authority, this.requestor)
      .then(response => {
        this.configuration = response
      })
      .catch(() => {
        // ignore
      })
  }

  async makeAuthorizationRequest() {
    const request = new AuthorizationRequest({
      client_id: settings.client_id,
      redirect_uri: settings.redirect_uri,
      scope: settings.scope,
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      extras: { prompt: 'consent', access_type: 'offline' },
    })

    if (this.configuration) {
      this.authorizationHandler.performAuthorizationRequest(this.configuration, request)
    } else {
      await this.fetchServiceConfiguration()
      /** Try again till the configuration get ready */
      setTimeout(() => this.makeAuthorizationRequest(), 1000)
    }
  }

  async makeTokenRequestFromCode() {
    if (!this.configuration || !this.code) {
      throw new Error('login_required')
    }

    let extras: StringMap | undefined
    if (this.request && this.request.internal) {
      extras = {}
      extras.code_verifier = this.request.internal.code_verifier
      // extras.access_type = 'offline'
    }
    // use the code to make the token request.
    const request = new TokenRequest({
      client_id: settings.client_id,
      redirect_uri: settings.redirect_uri,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: this.code,
      refresh_token: undefined,
      extras,
    })

    try {
      const tokenResponse = await this.tokenHandler.performTokenRequest(this.configuration, request)
      this.handleTokenResponse(tokenResponse)
    } catch (error) {
      // do something
    }
  }

  async makeRefreshTokenRequest() {
    if (!this.configuration || !this.tokenResponse) {
      throw new Error('login_required')
    }
    if (this.isMakingRefreshTokenRequest) {
      return
    }
    this.isMakingRefreshTokenRequest = true

    // use the token response to make a request for an access token
    const request = new TokenRequest({
      client_id: settings.client_id,
      redirect_uri: settings.redirect_uri,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: this.tokenResponse.refreshToken,
    })

    try {
      const tokenResponse = await this.tokenHandler.performTokenRequest(this.configuration, request)
      this.handleTokenResponse(tokenResponse)
    } catch (error) {
      this.tokenResponse = null
      localStorage.removeItem(TOKEN_RESPONSE)
      throw new Error('login_required')
    } finally {
      this.isMakingRefreshTokenRequest = true
    }
  }

  handleTokenResponse = (response: TokenResponse) => {
    this.tokenResponse = response
    TingLocalStorage.setItem(TOKEN_RESPONSE, JSON.stringify(response.toJson()))

    // unset code, so we can do refresh token exchanges subsequently
    this.code = undefined
  }

  checkForAuthorizationResponse() {
    return this.authorizationHandler.completeAuthorizationRequestIfPossible()
  }

  async signinCallback() {
    if (!this.configuration) {
      await this.fetchServiceConfiguration()
    }
    await this.checkForAuthorizationResponse()
    return this.makeTokenRequestFromCode()
  }

  async silentSignin() {
    if (!this.configuration) {
      await this.fetchServiceConfiguration()
    }
    return this.makeRefreshTokenRequest()
  }

  async revokeAccessToken() {
    if (!this.configuration) {
      await this.fetchServiceConfiguration()
    }
    const request = new RevokeTokenRequest({
      token: this.tokenResponse?.accessToken,
      token_type_hint: 'refresh_token',
      client_id: settings.client_id,
    })
    console.log('starting a token revoke request')
    const response = await this.tokenHandler.performRevokeTokenRequest(this.configuration, request)
    if (response) {
      localStorage.removeItem(TOKEN_RESPONSE)
      this.tokenResponse = null
    }
  }

  // async signout() {
  //   if (!this.configuration) {
  //     await this.fetchServiceConfiguration()
  //   }
  //   const request = new EndSessionRequest({
  //     id_token_hint: this.tokenResponse?.idToken,
  //     post_logout_redirect_uri: settings.post_logout_redirect_uri,
  //     state: undefined,
  //   })
  //   return this.authorizationHandler.performEndSessionRequest(this.configuration, request)
  // }
  // async signoutCallback() {
  //   if (!this.configuration) {
  //     await this.fetchServiceConfiguration()
  //   }
  //   await this.authorizationHandler.completeEndSessionRequestIfPossible()
  // }
}

export const authorizer = new Authorizer()

import { useConfig } from '@@ting/utils/actions'
import { useMobile } from '@@ting/utils/hooks'
import { Button, MenuThinIcon, Skeleton } from '@ting/ting-ui-components'
import { HeaderLogo } from './components'
import './HeaderSkeleton.scss'

export const HeaderSkeleton = () => {
  const { configActions } = useConfig()
  const isMobile = useMobile()

  return (
    <div className='header-skeleton'>
      <div className='d-flex align-items-center'>
        {!isMobile && (
          <Button className='header-skeleton-menu-button' theme='transparent' onClick={configActions.toggleSidebar}>
            <MenuThinIcon />
          </Button>
        )}
        <HeaderLogo />
      </div>
      <Skeleton variant='rect' width='40%' height={40} className='rounded mx-3' />
      <div className='header-upload-profile-skeleton'>
        <Skeleton variant='circle' width={30} height={30} className='rounded mx-3' />
        <Skeleton variant='circle' width={30} height={30} className='rounded mx-3' />
        <Skeleton variant='circle' width={30} height={30} className='rounded mx-3' />
        <Skeleton variant='circle' width={40} height={40} />
      </div>
    </div>
  )
}

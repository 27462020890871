import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import cn from 'classnames'
import { Text, Button, FormTextField, Option, FormTextArea } from '@ting/ting-ui-components'
import { AccountOutlinedIcon, LinkOutlinedIcon, PeopleOutlinedIcon } from '@ting/ting-ui-components/dist/icons'
import { useTranslation } from 'react-i18next'
import { IPlayList } from '@@ting/models'
import { SelectField, Value } from '@@ting/molecules'

import './CreatePlaylistModal.scss'

const PlaylistFormSchema = Yup.object().shape({
  displayName: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('* Required'),
  privacy: Yup.mixed().required('* Required'),
})

type OptionProps = {
  label: string
  description: string
  value: string
  icon?: any
}

type CustomOptionPlaylistTypeProps = {
  data: OptionProps
  setValue: any
}

const CustomOptionPlaylistType: React.FC<CustomOptionPlaylistTypeProps> = props => {
  const { data: type, setValue } = props
  return (
    <div className='custom-option-playlist-type__wrapper' onClick={() => setValue(type)}>
      {type.icon}
      <span className='custom-option-playlist-type__title'>{type.label}</span>
    </div>
  )
}

export type CreateEditPlaylistFormType = {
  displayName: string
  privacy: Value
}

type Props = {
  variant?: 'modal' | 'embed'
  className?: string
  data?: IPlayList
  onSubmit: (values: any) => void
  onClose?: () => void
}

export const CreatePlaylistModal: React.FC<Props> = props => {
  const { variant = 'modal', className = '', data, onSubmit, onClose } = props
  const { t } = useTranslation(['playlist', 'common'])

  const initialValues: CreateEditPlaylistFormType = {
    displayName: data ? data.displayName : '',
    privacy: data ? { value: data.privacy.id, label: data.privacy.label } : null,
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={PlaylistFormSchema}>
      {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
        <div className='create-playlist-modal-wrapper'>
          <div
            className={cn('create-playlist-modal', className, { [`create-playlist-modal-variant_${variant}`]: true })}
          >
            {variant === 'modal' && (
              <Text type='main' size='medium'>
                {t(data ? 'editPlaylist' : 'createNewPlaylist')}
              </Text>
            )}
            <div className='form-wrapper'>
              <div className='create-playlist-modal__input-wrapper'>
                <div className='create-playlist-modal__input-wrapper_name'>
                  <FormTextField
                    label='Title'
                    placeholder='Playlist title'
                    className='create-playlist-modal__input'
                    name='displayName'
                    maxLength={50}
                  />
                </div>

                <div className='create-playlist-modal__input-wrapper_description'>
                  <FormTextArea
                    label='Description (optional)'
                    placeholder=''
                    className='create-playlist-modal__input'
                    name='description'
                    maxLength={150}
                  />
                </div>

                <div className={cn('privacies-select', { [`privacies-select-variant_${variant}`]: true })}>
                  <SelectField
                    label='Privacy'
                    name='privacy'
                    placeholder='Select'
                    value={values.privacy as Option}
                    components={{ Option: CustomOptionPlaylistType }}
                    setFieldValue={setFieldValue}
                    size='large'
                    styles={{
                      menuList: (base: unknown[]) => ({ ...base, padding: '23px' }),
                      menuPortal: (base: unknown[]) => ({ ...base, zIndex: 9999, width: '240px' }),
                      singleValue: (base: any) => ({
                        ...base,
                        fontSize: '16px !important',
                        lineHeight: '28px !important',
                      }),
                    }}
                    menuPortalTarget={document.getElementById('selectBox')}
                    isSearchable={false}
                    options={
                      [
                        {
                          label: 'Public',
                          description: 'Anyone can search for and view',
                          value: '1',
                          icon: <PeopleOutlinedIcon height='20' width='20' />,
                        },
                        {
                          label: 'Link only',
                          description: 'Only you can view',
                          value: '2',
                          icon: <LinkOutlinedIcon height='20' width='20' />,
                        },
                        {
                          label: 'Private',
                          description: 'Can be viewed via link only',
                          value: '3',
                          icon: <AccountOutlinedIcon height='20' width='20' />,
                        },
                      ] as OptionProps[]
                    }
                  />
                </div>
              </div>
              <div className={cn('d-flex', onClose ? 'justify-content-between' : 'justify-content-end')}>
                {onClose && (
                  <Button type='button' theme='secondary' className='create-playlist-modal__back-btn' onClick={onClose}>
                    {t('common:back')}
                  </Button>
                )}
                <Button
                  type='submit'
                  theme='primary'
                  className='create-playlist-modal__submit-btn'
                  onClick={handleSubmit as any}
                  disabled={values.displayName === '' || isSubmitting}
                >
                  {t('common:save')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

/* eslint-disable no-param-reassign */
import React, { FC, useRef } from 'react'
import cn from 'classnames'
import { Button, Text } from '@ting/ting-ui-components'
import {
  MusicOutlinedIcon,
  MoviesOutlinedIcon,
  VehiclesOutlinedIcon,
  ArtOutlinedIcon,
  SportOutlinedIcon,
  TravelsOutlinedIcon,
  GamingOutlinedIcon,
  PeopleOutlinedIcon,
  ComedyOutlinedIcon,
  Entertainment2OutlinedIcon,
  NewsOutlinedIcon,
  HowToOutlinedIcon,
  EducationOutlinedIcon,
  ActivismOutlinedIcon,
  AnimalsOutlinedIcon,
  KidsOutlinedIcon,
  FoodOutlinedIcon,
  ChevronRightOutlinedIcon,
  ChevronLeftOutlinedIcon,
  InfiniteOutlinedIcon,
  ScienceOutlinedIcon,
} from '@ting/ting-ui-components/dist/icons'
import { useFilterParams } from '@@ting/utils/actions'
import { useMobile } from '@@ting/utils/hooks'
import { useGetCategories } from '@@ting/services/api/query-hooks'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, SwiperOptions } from 'swiper'
import CategoriesNavSkeleton from './CategoriesNavSkeleton'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

import './CategoriesNav.scss'

type CategoryItemProps = {
  id?: number | string
  category: string
}

interface Props extends SwiperOptions {
  className?: string
  icons?: boolean
}

export const iconMap: Record<string, React.ReactNode> = {
  All: <InfiniteOutlinedIcon className='category-icon' />,
  Music: <MusicOutlinedIcon className='category-icon' />,
  Films: <MoviesOutlinedIcon className='category-icon' />,
  Vehicles: <VehiclesOutlinedIcon className='category-icon' />,
  Art: <ArtOutlinedIcon className='category-icon' />,
  Sports: <SportOutlinedIcon className='category-icon' />,
  Travels: <TravelsOutlinedIcon className='category-icon' />,
  Gaming: <GamingOutlinedIcon className='category-icon' />,
  People: <PeopleOutlinedIcon className='category-icon' />,
  Comedy: <ComedyOutlinedIcon className='category-icon' />,
  Entertainment: <Entertainment2OutlinedIcon className='category-icon' />,
  'News & Politics': <NewsOutlinedIcon className='category-icon' />,
  'How To': <HowToOutlinedIcon className='category-icon' />,
  Education: <EducationOutlinedIcon className='category-icon' />,
  Activism: <ActivismOutlinedIcon className='category-icon' />,
  'Science & Technology': <ScienceOutlinedIcon className='category-icon' />,
  Animals: <AnimalsOutlinedIcon className='category-icon' />,
  Kids: <KidsOutlinedIcon className='category-icon' />,
  Food: <FoodOutlinedIcon className='category-icon' />,
}

export const CategoriesNav: FC<Props> = ({ className = '', icons = false, ...props }) => {
  const { filterParams, filterParamsActions } = useFilterParams()
  const { data: categoriesList, isLoading: isCategoriesLoading, isError: isCategoriesError } = useGetCategories()

  const navigationPrevRef = useRef<HTMLButtonElement | null>(null)
  const navigationNextRef = useRef<HTMLButtonElement | null>(null)

  const isMobile = useMobile()

  const CategoryItemView: FC<CategoryItemProps> = ({ id, category }) => {
    const categoryID = id ? id?.toString() : null
    return (
      <Button
        v2
        size='medium'
        type={filterParams.chosenCategory === categoryID ? 'brand' : 'neutral'}
        startIcon={icons && iconMap[category]}
        onClick={() => filterParamsActions.changeCategory(categoryID)}
      >
        <Text color='currentColor' type='main' size='buttons-medium-small'>
          {category}
        </Text>
      </Button>
    )
  }

  return (
    <div className={cn('categories-nav', className)}>
      {isCategoriesLoading ? (
        <CategoriesNavSkeleton isMobile={isMobile} />
      ) : (
        <div className='categories-nav__swiper-container'>
          <Button
            hidden={isMobile}
            className='categories-nav-btn categories-nav-btn__prev swiper-button-prev'
            theme='secondary'
            round
            icon={<ChevronLeftOutlinedIcon width={24} height={24} />}
            forwardedRef={navigationPrevRef}
          />
          <Button
            hidden={isMobile}
            className='categories-nav-btn categories-nav-btn__next swiper-button-next'
            theme='secondary'
            round
            icon={<ChevronRightOutlinedIcon width={24} height={24} />}
            forwardedRef={navigationNextRef}
          />
          <Swiper
            slidesPerView='auto'
            spaceBetween={8}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={swiper => {
              if (typeof swiper.params.navigation !== 'boolean') {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
              }
            }}
            modules={[Navigation]}
            {...props}
          >
            <SwiperSlide key='0'>
              <CategoryItemView id='0' category='All' />
            </SwiperSlide>
            {Object.entries(isCategoriesError ? [] : categoriesList)?.map(([id, name]) => (
              <SwiperSlide key={id}>
                <CategoryItemView key={name} id={id} category={name} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  )
}
export default React.memo(CategoriesNav)

import { FC } from 'react'
import cn from 'classnames'

import { Sort } from '@@ting/services/api'
import { useGetInfiniteVideos, useGetLanguages } from '@@ting/services/api/query-hooks'
import { Filter, InfiniteVideoList } from '@@ting/molecules'
import SortArrayOfObjectsAlphabetically, { ObjectType } from '@@ting/helpers/SortArrayOfObjectsAlphabetically'
import { useFilterParams } from '@@ting/utils/actions'
import { emptyInfiniteDataQueryPage } from '@@ting/utils/constants/emptyData'

import './filteredVideosContainer.scss'

export enum FilterPage {
  NONE,
  MAIN,
  LANGUAGES,
  SELECTED_LANGUAGES,
  TIME_DURATION,
}
type FilteredVideosContainerProps = {
  className?: string
  sort?: Sort
  hasFilter?: boolean
}

const languagesToLanguageList = (languages: { [key: string]: string }) => {
  const result: ObjectType[] = []
  if (!languages) {
    return result
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(languages)) {
    result.push({
      value: key,
      label: value,
    })
  }
  SortArrayOfObjectsAlphabetically(result)
  return result
}
const FilteredVideosContainer: FC<FilteredVideosContainerProps> = ({
  className,
  sort = '-likes',
  hasFilter = false,
}) => {
  const { filterParams } = useFilterParams()

  const {
    data: videos,
    isLoading: isVideosLoading,
    isFetchingNextPage: isFetchingNextVideosPage,
    isError: isVideosError,
    hasNextPage: videosHasNextPage,
    fetchNextPage: fetchVideosNextPage,
  } = useGetInfiniteVideos({
    sort,
    count: 16,
    filterParams,
  })
  const { data: languages, isLoading: isLanguagesLoading, isError: isLanguagesError } = useGetLanguages()

  return (
    <div className={cn('row', className)}>
      {hasFilter && (
        <div className='trending-head'>
          <Filter
            languagesList={isLanguagesError ? [] : languagesToLanguageList(languages)}
            isLoading={isLanguagesLoading}
          />
        </div>
      )}
      <InfiniteVideoList
        videos={isVideosError ? emptyInfiniteDataQueryPage : videos}
        isVideosLoading={isVideosLoading}
        videosHasNextPage={videosHasNextPage}
        isFetchingNextPage={isFetchingNextVideosPage}
        fetchVideosNextPage={fetchVideosNextPage}
      />
    </div>
  )
}

export default FilteredVideosContainer

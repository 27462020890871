import { Helmet } from 'react-helmet-async'
import CategoriesNavigatorContainer from '@@ting/features/MainPage/containers/CategoriesNavigatorContainer'
import LiveVideosContainer from '@@ting/features/MainPage/containers/LiveVideosContainer'
import PopularVideosContainer from '@@ting/features/MainPage/containers/PopularVideosContainer'
import FilteredVideosContainer from '@@ting/features/FilteredVideos/containers/FilteredVideosContainer'

import './MainPageContainer.scss'
import { CategoriesNav } from '@@ting/molecules'

const MainPageContainer: React.FC = () => (
  <>
    <Helmet>
      <title>Home</title>
    </Helmet>

    <div className='main-page-container'>
      <CategoriesNav className='categories  my-4' icons />
      <div className='sub-content'>
        <FilteredVideosContainer sort='name' hasFilter={false} />
        <CategoriesNavigatorContainer />
        <LiveVideosContainer />
        <PopularVideosContainer />
      </div>
    </div>
  </>
)

export default MainPageContainer

import { FC, useEffect, useState, useRef, Suspense } from 'react'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'

import { Loading, Modal, useSnackBar } from '@ting/ting-ui-components'
import { IVideoDetails } from '@@ting/models'
import { AddEditVideoForm } from '@@ting/molecules'
import { useHistoryState, useMobile } from '@@ting/utils/hooks'
import getFlatDataFromPages from '@@ting/helpers/getFlatDataFromPages'
import {
  useGetMyChannels,
  useGetUserInfoWhenNeed,
  useImportVideoMutation,
  useRemoveVideo,
  useUploadMutation,
} from '@@ting/services/api/query-hooks'
import routeGenerator from '@@ting/helpers'
import Routes from '@@ting/enums/routes'
import { UploadVideo, UploadPreview } from './components'
import { DialogTitle } from './components/DialogTitle'

import './UploadVideoDialog.scss'

type UploadVideoDialogProps = {
  className?: string
  channelId?: number
  onVideoPublished: () => void
  closeDialog: () => void
}

export const UploadVideoDialog: FC<UploadVideoDialogProps> = ({
  className,
  channelId,
  onVideoPublished,
  closeDialog,
}) => {
  const [step, stepHelpers] = useHistoryState(0)
  const isMobile = useMobile()
  const cancelUploadRef = useRef(null)
  const history = useHistory()

  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [video, setVideo] = useState(null)
  const [fileToUpload, setFileToUpload] = useState(null)
  const [importError, setImportError] = useState(null)
  const [uploadError, setUploadError] = useState(null)

  const { data: user, isFetching: userIsLoading } = useGetUserInfoWhenNeed()
  const { data: myChannels, isFetching: myChannelsisLoading } = useGetMyChannels(user?.username, {})
  const removeVideoMutation = useRemoveVideo()
  const uploadMutation = useUploadMutation()
  const importMutation = useImportVideoMutation()
  const showSnackBar = useSnackBar()

  const uploadingAudio = Boolean(fileToUpload?.type.includes('audio'))

  useEffect(() => () => cancelUploadRef.current && cancelUploadRef.current(), [])

  // Handle video import by `url` or video upload with `file`
  const handleUploadVideo = async ({ url, file }: { url: string; file: File }) => {
    if (cancelUploadRef.current !== null) {
      cancelUploadRef.current()
    }

    if (file) {
      setFileToUpload(file)
    }

    setImportError(null)
    setUploadError(null)

    if (file) {
      stepHelpers.push(1)
    }

    const handleUploadProgress = (progressEvent: { loaded: number; total: number }) => {
      setUploadPercentage(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }

    // Extract video name from url
    const name = url ? url.substr(0, 100) : file.name.replace(/\.[^/.]+$/, '')
    const channel = channelId
      ? getFlatDataFromPages(myChannels).find(c => c.id === channelId)
      : myChannels?.pages[0].data[0]
    const initialVideo = { name, channel } as IVideoDetails
    setVideo(initialVideo)

    const { cancelTokenSource } = url ? importMutation : uploadMutation

    const mutateAction = () => {
      if (url) {
        return importMutation.mutateAsync({
          targetUrl: url,
          channelId: channel?.id,
          name,
        })
      }
      return uploadMutation.mutateAsync({
        videofile: file,
        channelId: channel?.id,
        name,
        onUploadProgress: handleUploadProgress,
      })
    }

    cancelUploadRef.current = () => {
      setVideo(null)
      setFileToUpload(null)
      cancelTokenSource.cancel('Upload canceled due to page abort')
    }

    try {
      const res = await mutateAction()
      setVideo({ ...initialVideo, ...res.video })
      cancelUploadRef.current = () => {
        removeVideoMutation.mutate({ videoId: res.video.id })
      }

      // we only go to next step if video import is successful
      if (!file) {
        stepHelpers.push(2)
      }
    } catch (error) {
      setVideo(null)
      setFileToUpload(null)
      showSnackBar({ message: (error as any).response?.data?.error || (error as any).message })

      // only pop from state if user tried to upload a file
      if (file) {
        stepHelpers.push(0)
      }
      if (url) {
        setImportError('Something went wrong, try again.')
      } else if ((error as any).response) {
        setImportError((error as any).response.data.error)
      } else {
        setUploadError('Something went wrong, try again.')
      }
      cancelUploadRef.current = null
    }
  }

  const handleNext = () => {
    stepHelpers.push(step + 1)
  }

  const handleBack = () => {
    stepHelpers.pop()
  }

  const handlePublish = () => {
    cancelUploadRef.current = null
    history.push(routeGenerator(Routes.Video.watchVideoUUID, { videoUUID: video.uuid }))
    onVideoPublished()
  }

  return (
    <Modal
      isOpen
      shouldCloseOnOverlayClick={false}
      fullscreen={isMobile}
      hideCloseButton={isMobile}
      scrollable
      onClose={closeDialog}
      className='upload-video-dialog-wrapper'
    >
      <div className={cn('upload-video-dialog', className)}>
        <Suspense fallback={<Loading />}>
          {myChannelsisLoading || userIsLoading ? (
            <Loading />
          ) : (
            <div className='dialog-content'>
              <DialogTitle uploading={Boolean(fileToUpload)} uploadingAudio={uploadingAudio} />
              {step === 0 && (
                <UploadVideo
                  initialUploadError={uploadError}
                  initialImportError={importError}
                  onSubmit={handleUploadVideo}
                  onCancel={closeDialog}
                />
              )}
              <div className='upload-previewAndForm'>
                {(step === 1 || (step === 2 && !isMobile)) && fileToUpload && (
                  <UploadPreview
                    className='upload-preview'
                    percentage={uploadPercentage}
                    fileToUpload={fileToUpload}
                    video={video}
                    onNext={handleNext}
                    onBack={handleBack}
                    uploadingAudio={uploadingAudio}
                  />
                )}

                {(step === 2 || (step === 1 && !isMobile)) && video && (
                  <AddEditVideoForm
                    className='upload-form'
                    video={video}
                    myChannels={myChannels}
                    onBack={handleBack}
                    onSubmit={handlePublish}
                  />
                )}
              </div>
            </div>
          )}
        </Suspense>
      </div>
    </Modal>
  )
}

import { createAction } from 'redux-act'
import { createFetchAction } from '@@ting/utils/createFetchAction'

export const getCategoriesList = createAction('getCategoriesList')
export const getCategoriesListSuccess = createAction('getCategoriesListSuccess')
export const getCategoriesListFailure = createAction('getCategoriesListFailure')
export const setChosenCategory = createAction('setChosenCategory')

export const getLanguagesList = createAction('getLanguagesList')
export const getLanguagesListSuccess = createAction('getLanguagesListSuccess')
export const getLanguagesListFailure = createAction('getLanguagesListFailure')

export const getPrivaciesList = createAction('getPrivaciesList')
export const getPrivaciesListSuccess = createAction('getPrivaciesListSuccess')
export const getPrivaciesListFailure = createAction('getPrivaciesListFailure')

export const getLicensesList = createAction('getLicensesList')
export const getLicensesListSuccess = createAction('getLicensesListSuccess')
export const getLicensesListFailure = createAction('getLicensesListFailure')

export const setLanguageFilter = createAction('setLanguageFilter')

export const setDurationFilter = createAction('setDurationFilter')

export const setTagsFilter = createAction('setTagsFilter')

export const clearTagsFilter = createAction('clearTagsFilter')

export const clearLanguageFilter = createAction('clearLanguageFilter')

export const clearDurationFilter = createAction('clearDurationFilter')

export const [getFollowingList, getFollowingListSuccess, getFollowingListFailure] =
  createFetchAction('getFollowingList')

export const [followAccount, followAccountSuccess, followAccountFailure] = createFetchAction('followAccount')

export const [unfollowAccount, unfollowAccountSuccess, unfollowAccountFailure] = createFetchAction('unfollowAccount')

export const [followAccountExist, followAccountExistSuccess, followAccountExistFailure] =
  createFetchAction('followAccountExist')

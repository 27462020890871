import React, { FC, useRef, useEffect } from 'react'
import { useNextTick } from '@@ting/utils/hooks'
import { isDOMElement } from '@@ting/utils/isDOMElement'

type WayPointProps = {
  children?: React.ReactElement
  onEnter?: () => any
  onLeave?: () => any
}
type ScrollableParent = Window | HTMLElement
enum Positions {
  inside,
  above,
  below,
  nowhere,
}

export const Waypoint: FC<WayPointProps> = ({ onEnter, onLeave, children }) => {
  const elementRef = useRef<HTMLElement>()
  const scrollableParentRef = useRef<ScrollableParent>()
  const previousPositionRef = useRef<Positions>()
  const handleScrollNextTick = useNextTick(handleScroll)

  useEffect(() => {
    if (!elementRef.current) {
      return null
    }
    if (!scrollableParentRef.current) {
      scrollableParentRef.current = findScrollableParent(elementRef.current)
    }
    const boundedHandleScrollNextTick = () =>
      handleScrollNextTick(elementRef.current, scrollableParentRef.current, previousPositionRef, onEnter, onLeave)

    scrollableParentRef.current.addEventListener('scroll', boundedHandleScrollNextTick)
    window.addEventListener('resize', boundedHandleScrollNextTick)
    handleScrollNextTick(elementRef.current, scrollableParentRef.current, previousPositionRef, onEnter, onLeave)

    return function removeListener() {
      scrollableParentRef.current.removeEventListener('scroll', boundedHandleScrollNextTick)
      window.removeEventListener('resize', boundedHandleScrollNextTick)
    }
  }, [onEnter, onLeave, handleScrollNextTick])

  if (!children) {
    return <span ref={elementRef} style={{ width: 0, height: 0 }} />
  }
  if (isDOMElement(children)) {
    return React.cloneElement(children, { ref: elementRef })
  }
  return React.cloneElement(children, { innerRef: elementRef })
}

function findScrollableParent(node: HTMLElement) {
  let parent = node
  while (parent.parentNode) {
    parent = parent.parentNode as HTMLElement

    if (parent === document.body) {
      return window
    }

    const style = window.getComputedStyle(parent)
    const overflow = style.getPropertyValue('overflow-y') || style.getPropertyValue('overflow')

    if (overflow === 'auto' || overflow === 'scroll' || overflow === 'overlay') {
      return parent
    }
  }
  return window
}

function calculatePosition(elementTop: number, elementBottom: number, parentTop: number, parentBottom: number) {
  if (parentTop <= elementTop && elementTop <= parentBottom) {
    return Positions.inside
  }

  if (parentTop <= elementBottom && elementBottom <= parentBottom) {
    return Positions.inside
  }

  if (elementTop <= parentTop && parentBottom <= elementBottom) {
    return Positions.inside
  }

  if (parentBottom < elementTop) {
    return Positions.below
  }

  if (elementBottom < parentTop) {
    return Positions.above
  }
  return Positions.nowhere
}

function handleScroll(
  element: HTMLElement,
  parent: ScrollableParent,
  previousPositionRef: React.MutableRefObject<Positions>,
  onEnter: () => any,
  onLeave: () => any
) {
  if (!element || !parent) {
    return
  }

  const { top, bottom } = element.getBoundingClientRect()

  let parentTop
  let parentHeight
  if (parent === window) {
    parentHeight = parent.innerHeight
    parentTop = 0
  } else if (parent instanceof HTMLElement) {
    parentHeight = parent.offsetHeight
    parentTop = parent.getBoundingClientRect().top
  }
  const parentBottom = parentTop + parentHeight

  const position = calculatePosition(top, bottom, parentTop, parentBottom)
  const prevPosition = previousPositionRef.current
  // eslint-disable-next-line
  previousPositionRef.current = position

  if (position === prevPosition) {
    return
  }
  if (position === Positions.inside && typeof onEnter === 'function') {
    onEnter()
  } else if (prevPosition === Positions.inside && typeof onLeave === 'function') {
    onLeave()
  }
  if (
    (prevPosition === Positions.above && position === Positions.below) ||
    (prevPosition === Positions.below && position === Positions.above)
  ) {
    if (typeof onEnter === 'function') {
      onEnter()
    }
    if (typeof onLeave === 'function') {
      onLeave()
    }
  }
}

import { useQuery, useInfiniteQuery, useMutation, UseQueryResult } from 'react-query'
import {
  VideosService,
  Search,
  GetVideosParam,
  GetLiveVideosParams,
  UploadVideoParams,
  ImportVideoParams,
} from '@@ting/services/api/videos'
import { queryClient } from '@@ting/index'
import { getNextPageParam } from '@@ting/utils/getNextPageParams'
import fetchOnceOptions from '@@ting/configs/fetchOnceOptions'
import { VideoCommentThreadTree } from '@@ting/models'
import { CancelToken } from '@@ting/services/api'
import { useRef } from 'react'
import {
  VideoId,
  VideoCommentsQueryObject,
  CommentId,
  GetSimilarVideosQueryObject,
  BasicQueryObjectType,
} from './types'

export const useGetVideoDetails = (videoUUID: string) =>
  useQuery([VideosService.getVideoDetails.name, videoUUID], () => VideosService.getVideoDetails({ videoUUID }), {
    enabled: !!videoUUID,
  })

export const useGetVideoDescription = (videoId: VideoId) =>
  useQuery([VideosService.getVideoDescription.name, videoId], () => VideosService.getVideoDescription({ videoId }))

export const useGetVideoComments = (videoId: VideoId, queryObject: VideoCommentsQueryObject) =>
  useInfiniteQuery(
    [VideosService.getVideoComments.name, videoId, queryObject],
    ({ pageParam = 0 }) => VideosService.getVideoComments({ videoId, start: pageParam, ...queryObject }),
    { getNextPageParam }
  )

export const useGetVideoCommentsReplies = (
  videoId: VideoId,
  commentId: CommentId
): UseQueryResult<VideoCommentThreadTree, unknown> =>
  useQuery([VideosService.getVideoCommentReplies.name, videoId, commentId], () =>
    VideosService.getVideoCommentReplies({ videoId, commentId })
  )

export const useGetVideoRating = (videoId: VideoId) =>
  useQuery([VideosService.getVideoRating.name, videoId], () => VideosService.getVideoRating({ videoId }))

export const useGetSimilarVideos = (queryObject: GetSimilarVideosQueryObject) =>
  useQuery([VideosService.getSimilarVideos.name, queryObject], () => VideosService.getSimilarVideos(queryObject))

export const useGetInfiniteVideos = (queryObject: GetVideosParam) =>
  useInfiniteQuery(
    [VideosService.getVideos.name, queryObject],
    ({ pageParam = 0 }) => VideosService.getVideos({ ...queryObject, start: pageParam }),
    { getNextPageParam }
  )

export const useGetSearchedVideo = (search: Search) =>
  useInfiniteQuery(
    [VideosService.getSearchedVideo.name, search],
    ({ pageParam = 0 }) => VideosService.getSearchedVideo({ ...search, start: pageParam }),
    { getNextPageParam }
  )

export const useGetSearchedVideoChannel = (search: Search) =>
  useQuery([VideosService.getSearchedVideoChannel.name, search], () => VideosService.getSearchedVideoChannel(search), {
    enabled: !!search.query,
  })

export const useGetSearchedProfile = (search: Search) =>
  useQuery([VideosService.getSearchedProfile.name, search], () => VideosService.getSearchedProfile(search), {
    enabled: !!search.query,
  })

export const useGetSubscriptionVideos = (search: BasicQueryObjectType, channelIdOneOf?: string[]) =>
  useInfiniteQuery(
    [VideosService.getSubscriptionVideos.name, search, channelIdOneOf],
    ({ pageParam = 0 }) => VideosService.getSubscriptionVideos({ ...search, channelIdOneOf, start: pageParam }),
    { getNextPageParam }
  )

export const useGetLiveVideos = ({ count, filterParams }: GetLiveVideosParams) =>
  useQuery([VideosService.getLiveVideos.name, count, filterParams], () =>
    VideosService.getLiveVideos({ count, filterParams })
  )

export const useLikeVideo = () =>
  useMutation(VideosService.likeVideo.name, VideosService.likeVideo, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries([VideosService.getVideoDetails.name, variables.videoUUID])
    },
  })

export const useDislikeVideo = () =>
  useMutation(VideosService.disLikeVideo.name, VideosService.disLikeVideo, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries([VideosService.getVideoDetails.name, variables.videoUUID])
    },
  })

export const useRemoveVideo = () =>
  useMutation(VideosService.deleteVideo.name, VideosService.deleteVideo, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries([VideosService.getVideos.name, variables.videoId])
      queryClient.invalidateQueries([VideosService.getChannelVideos.name])
    },
  })

export const useGetVideoCaptions = (videoId: string | number) =>
  useQuery([VideosService.getVideoCaptions.name, videoId], () => VideosService.getVideoCaptions(videoId), {
    ...fetchOnceOptions,
    enabled: !!videoId,
  })

export const useAddVideoComment = () =>
  useMutation(VideosService.addVideoComment.name, VideosService.addVideoComment, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries([VideosService.getVideoCommentReplies.name, variables.videoId])
      queryClient.invalidateQueries([VideosService.getVideoComments.name, variables.videoId])
    },
  })

export const useAddVideoCommentReply = () =>
  useMutation(VideosService.addVideoCommentReply.name, VideosService.addVideoCommentReply, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries([VideosService.getVideoCommentReplies.name, variables.videoId])
      queryClient.invalidateQueries([VideosService.getVideoComments.name, variables.videoId])
    },
  })

export const useLikeVideoComment = () =>
  useMutation(VideosService.likeVideoComment.name, VideosService.likeVideoComment, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries([VideosService.getVideoCommentReplies.name, variables.videoId])
      queryClient.invalidateQueries([VideosService.getVideoComments.name, variables.videoId])
    },
  })

export const useDislikeVideoComment = () =>
  useMutation(VideosService.dislikeVideoComment.name, VideosService.dislikeVideoComment, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries([VideosService.getVideoCommentReplies.name, variables.videoId])
      queryClient.invalidateQueries([VideosService.getVideoComments.name, variables.videoId])
    },
  })

export const useGetVideoFullDescription = (descriptionPath: string) =>
  useQuery([VideosService.getVideoFullDescription.name, descriptionPath], () =>
    VideosService.getVideoFullDescription(descriptionPath)
  )

export const useUpdateVideo = () =>
  useMutation(VideosService.updateVideo.name, VideosService.updateVideo, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries([VideosService.getVideos.name, variables.videoId])
      queryClient.invalidateQueries([VideosService.getChannelVideos.name, variables.videoId])
    },
  })

export const useGetChatRecord = (videoId: string | number) =>
  useQuery([VideosService.getVideoChatRecord.name, videoId], () => VideosService.getVideoChatRecord(videoId))

export const useUploadMutation = () => {
  const cancelTokenSource = useRef(CancelToken.source())

  const mutation = useMutation(
    VideosService.uploadVideo.name,
    (variables: Omit<UploadVideoParams, 'cancelToken'>) =>
      VideosService.uploadVideo({ ...variables, cancelToken: cancelTokenSource.current }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([VideosService.getVideos.name])
        queryClient.invalidateQueries([VideosService.getChannelVideos.name])
      },
    }
  )

  return { ...mutation, cancelTokenSource: cancelTokenSource.current }
}

export const useImportVideoMutation = () => {
  const cancelTokenSource = useRef(CancelToken.source())

  const mutation = useMutation(
    VideosService.importVideo.name,
    (variables: Omit<ImportVideoParams, 'cancelToken'>) =>
      VideosService.importVideo({ ...variables, cancelToken: cancelTokenSource.current }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([VideosService.getVideos.name])
        queryClient.invalidateQueries([VideosService.getChannelVideos.name])
      },
    }
  )

  return { ...mutation, cancelTokenSource: cancelTokenSource.current }
}

enum Video {
  watch = '/watch/',
  watchVideoUUID = '/watch/:videoUUID',

  watchPlaylist = '/watch/playlist/',
  watchPlaylistUUID = '/watch/playlist/:playlistUUID',
  watchPlaylistPosition = '/watch/playlist/:playlistUUID/:playlistPosition',

  embedWatch = '/embed/watch',
  embedPlayer = '/embed/watch/:videoUUID',
}
export default Video

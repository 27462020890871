export function getErrorMessage(error: any): string {
  const { response } = error
  if (response) {
    if (typeof response.data === 'object') {
      const { errors } = response.data
      if (errors) {
        let message = ''

        Object.values(errors).forEach((err: any) => {
          message += `${err.msg}\r\n`
        })

        message = message.substring(0, message.length - '\r\n'.length)

        return message
      }

      return 'SHOULD HANDLE IT'
    }
    if (response.data) {
      return response.data
    }

    return response.statusText
  }
  if (error.isAxiosError) {
    return 'Network error, please ensure you are connected.'
  }
  return 'Unknown error, please try again.'
}

export type ObjectType = {
  [key in 'label' | 'name' | 'value']?: string
}

const SortObjectsAlphabetically = (a: ObjectType, b: ObjectType, property: 'label' | 'name' | 'value' = 'label') => {
  if (a[property] < b[property]) {
    return -1
  }
  if (a[property] > b[property]) {
    return 1
  }
  return 0
}
const SortArrayOfObjectsAlphabetically = (array: ObjectType[]) => {
  array.sort(SortObjectsAlphabetically)
}
export default SortArrayOfObjectsAlphabetically

/* eslint-disable complexity */
import { Cmd, loop } from 'redux-loop'
import { createReducer } from 'redux-act'

import {
  startSignUp,
  cancelSignUp,
  startSubmitting,
  succeedSubmitting,
  failSubmitting,
  resetSignUpErrors,
} from './actions'
import { LOCATION_CHANGE } from 'connected-react-router'

import { locationChangeSideEffect } from './sideEffects'

const initialState = {
  signingUp: false,
  isSubmitting: false,
  errors: {},
}

const handleLocationChanged = state =>
  loop(
    { ...state },
    Cmd.list([Cmd.run(locationChangeSideEffect, { args: [Cmd.getState, Cmd.dispatch] }, Cmd.action(startSignUp()))], {
      sequence: true,
    })
  )

const handleSignupStart = () => ({
  ...initialState,
  signingUp: true,
})

const handleSignupCancel = () => initialState

const handleStartSubmitting = state => ({
  ...state,
  isSubmitting: true,
})

const handleSucceedSubmitting = state => ({
  ...state,
  isSubmitting: false,
  errors: {},
})

const handleFailSubmitting = (state, payload) => ({
  ...state,
  isSubmitting: false,
  errors: payload,
})

const handleResetSignupErrors = state => ({
  ...state,
  errors: {},
})

const actions = {
  [LOCATION_CHANGE]: handleLocationChanged,
  [startSignUp]: handleSignupStart,
  [cancelSignUp]: handleSignupCancel,
  [startSubmitting]: handleStartSubmitting,
  [succeedSubmitting]: handleSucceedSubmitting,
  [failSubmitting]: handleFailSubmitting,
  [resetSignUpErrors]: handleResetSignupErrors,
}

const reducer = createReducer(actions, initialState)

export default reducer

import { FC } from 'react'
import cn from 'classnames'
import { Action } from 'redux-act'

import { HistoryStatePopPayload } from '@@ting/redux/actions'
import NavigateInput from '@@ting/molecules/NavigateInput'
import MultiValueInput from '@@ting/molecules/MultiValueInput'
import { useFilterParams } from '@@ting/utils/actions'
import { FilterPage, SelectValue } from '../../types'

import './mobileFiltersMain.scss'

type Props = {
  className?: string
  title?: string
  setPage: { push: (newValue: FilterPage) => void; pop: () => Action<HistoryStatePopPayload, any> }
  onClose: () => void
}

const TrendingMobileFiltersMain: FC<Props> = props => {
  const { className = '', title, setPage, onClose } = props
  const { filterParams, filterParamsActions } = useFilterParams()

  const languageValueFormat = (language: SelectValue[]) => {
    if (!language) return ''
    return language.reduce((acc, lan, index) => {
      if (index === 0) {
        return lan.label
      }
      if (index === 3) {
        return `${acc}, ...`
      }
      if (index > 3) {
        return null
      }
      return `${acc}, ${lan.label}`
    }, '')
  }

  const handleSetTags = (tags: SelectValue[]) => {
    filterParamsActions.handleSetTags(tags)
  }

  const clearTagsFilter = () => {
    filterParamsActions.clearTagsFilter()
  }

  const handleDone = () => {
    filterParamsActions.setAll(filterParams)
    onClose()
  }

  return (
    <div className={cn('tmf__container', className)}>
      <header className='tmf__header'>
        <span onClick={onClose} className='tmf__cancel'>
          cancel
        </span>
        <span className='tmf__title'>{title}</span>
        <span onClick={handleDone} className='tmf__done'>
          Done
        </span>
      </header>
      <div className='tmf__content'>
        <div className='tmf__input'>
          <div className='tmf__input-label-container'>
            <span className='label'>Tags</span>
            {filterParams.tagsFilter?.length > 0 && (
              <span className='tmf__input-clear' onClick={clearTagsFilter}>
                Clear
              </span>
            )}
          </div>
          <MultiValueInput onChange={handleSetTags} placeholder='Tags' value={filterParams.tagsFilter} />
        </div>
        <NavigateInput
          onClick={() => {
            setPage.push(FilterPage.LANGUAGES)
          }}
          defaultValue='All'
          className='tmf__navigation-input'
          label='Language'
          onClear={filterParamsActions.clearLanguage}
          value={languageValueFormat(filterParams.languageFilter as unknown as SelectValue[])}
        />
        <NavigateInput
          onClick={() => setPage.push(FilterPage.TIME_DURATION)}
          defaultValue='All Time'
          className='tmf__navigation-input'
          label='Timeframe'
          onClear={filterParamsActions.clearDuration}
          value={filterParams?.durationFilter?.label}
        />
      </div>
    </div>
  )
}

export default TrendingMobileFiltersMain

import { FC, ComponentProps } from 'react'
import { Text } from '@ting/ting-ui-components'
import { containsUrlRegex } from '@@ting/utils/regex'

const protocolRegex = /^https?:\/\//

type Props = ComponentProps<typeof Text> & {
  text: string
}

export const TextWithLinks: FC<Props> = ({ text = '', ...rest }) => {
  // eslint-disable-next-line no-param-reassign
  text = text || ''
  return (
    <Text {...rest}>
      {Array.from(text.matchAll(containsUrlRegex))
        .reduceRight(
          ([reminder, ...restTexts], match) => {
            const { index } = match
            const newReminder = reminder.substring(0, index)
            const url = match[0]
            const rawText = reminder.substring(index + url.length)
            return [newReminder, url, rawText, ...restTexts]
          },
          [text]
        )
        .map((rawText, index) => {
          if (index % 2 === 0) {
            // eslint-disable-next-line react/no-array-index-key
            return <span key={`${index.toString()}-text`}>{rawText}</span>
          }

          const url = rawText.match(protocolRegex) ? rawText : `https://${rawText}`
          return (
            // eslint-disable-next-line react/no-array-index-key
            <a key={`${index.toString()}-link`} href={url} target='_blank' rel='noreferrer'>
              {rawText}
            </a>
          )
        })}
    </Text>
  )
}

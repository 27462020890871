import { useEffect, useRef, FC, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Button, FormTextField } from '@ting/ting-ui-components'
import { DeliveredOutlinedIcon } from '@ting/ting-ui-components/dist/icons'

import UploadAvatar from '../UploadAvatar'
import { NameRules } from './NameRules'

const channelRegex = /^[A-Za-z0-9\-_.:]{4,120}$/

const step1ValidationSchema = Yup.object().shape({
  channelName: Yup.string()
    .min(4, 'Too Short!')
    .max(120, 'Too Long!')
    .matches(channelRegex, 'The username should be composed of numbers, lowercase letters and _')
    .required('* Required'),
})

type Props = {
  initialValues: any
  isSubmitting: boolean
  errors: any
  onBackClick: any
  onStep2Submit: any
  onChannelAvatarChange: (file: File) => Promise<unknown>
}

export const Step2: FC<Props> = props => {
  const { initialValues, isSubmitting, errors, onBackClick, onStep2Submit, onChannelAvatarChange } = props
  const formikRef = useRef(null)
  const [valid, setIsValid] = useState(undefined)

  useEffect(() => {
    const formik = formikRef.current
    if (!formik) {
      return
    }

    Object.entries(errors).forEach(([field, error]) => {
      formik.setFieldError(field, error)
      formik.setFieldTouched(field, true, false)
    })
  }, [errors])

  const handleChannelNameChanged = (event: any) => {
    const formik = formikRef.current
    if (!formik) {
      return
    }
    const value = event.target.value as string
    if (value.length >= 4 && value.length <= 50 && channelRegex.test(value)) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }

    formik.handleChange(event)
  }

  const handleFormSubmit = (formValues: any) => {
    const { channelName } = formValues
    onStep2Submit({ channelName })
  }

  const TextFieldProp =
    valid !== undefined
      ? {
          state: valid ? 'success' : 'error',
        }
      : {}
  // Set error message for channel handle if we got the error from server
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={step1ValidationSchema}
    >
      {({ handleSubmit, values }) => (
        <>
          <div className='upload-profile'>
            <UploadAvatar coreId='profile-avatar' onAvatarChanged={onChannelAvatarChange} size='xxl' hasButton />
          </div>
          <FormTextField
            v2
            className='mt-5'
            name='channelName'
            size='large'
            label='Channel name'
            placeholder='Your channel name'
            onChange={handleChannelNameChanged}
            endIcon={valid && <DeliveredOutlinedIcon className='success-check-icon' />}
            {...TextFieldProp}
          />
          <NameRules />
          <div className='action'>
            <Button
              v2
              variant='primary'
              type='neutral'
              theme='secondary'
              className='back-button'
              onClick={() => onBackClick(values)}
            >
              Back
            </Button>
            <Button
              v2
              variant='primary'
              type='brand'
              className='complete-button'
              onClick={handleSubmit as any}
              disabled={isSubmitting}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Formik>
  )
}

import { createAction } from 'redux-act'

import {
  SIGN_UP,
  SIGN_UP_CANCEL,
  SIGN_UP_START,
  START_SUBMITTING,
  SUCCEED_SUBMITTING,
  FAIL_SUBMITTING,
  RESET_SIGNUP_ERRORS,
} from './constants'

export const startSignUp = createAction(SIGN_UP_START)
export const cancelSignUp = createAction(SIGN_UP_CANCEL)
export const signUp = createAction(SIGN_UP)
export const startSubmitting = createAction(START_SUBMITTING)
export const succeedSubmitting = createAction(SUCCEED_SUBMITTING)
export const failSubmitting = createAction(FAIL_SUBMITTING)
export const resetSignUpErrors = createAction(RESET_SIGNUP_ERRORS)

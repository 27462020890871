import { FC } from 'react'
import getFlatDataFromPages from '@@ting/helpers/getFlatDataFromPages'
import { useGetInfiniteVideos } from '@@ting/services/api/query-hooks'
import { CarouselSkeleton } from '@@ting/molecules/Skeleton'
import { useFilterParams } from '@@ting/utils/actions'
import { useMobile } from '@@ting/utils/hooks'
import VideoCarousel from '@@ting/molecules/VideoCarousel'
import Routes from '@@ting/enums/routes'

type Props = {
  className?: string
}

const CategoriesNavigatorContainer: FC<Props> = props => {
  const { className = '' } = props

  const { filterParams } = useFilterParams()
  const {
    data: mostLikedVideos,
    isLoading: isMostLikedVideosLoading,
    isError: isMostLikedVideosError,
  } = useGetInfiniteVideos({
    sort: '-likes',
    count: 10,
    filterParams,
  })
  const {
    data: recentVideos,
    isLoading: isRecentVideosLoading,
    isError: isRecentVideosError,
  } = useGetInfiniteVideos({
    sort: '-trending',
    count: 10,
    filterParams,
  })
  const isMobile = useMobile()

  return (
    <div className={className}>
      {isRecentVideosLoading && <CarouselSkeleton isMobile={isMobile} itemsCount={8} />}
      {!isRecentVideosLoading && (
        <VideoCarousel
          title='Recently Added'
          url={Routes.Common.trending}
          videos={isRecentVideosError ? [] : getFlatDataFromPages(recentVideos)}
        />
      )}
      {isMostLikedVideosLoading && <CarouselSkeleton isMobile={isMobile} itemsCount={8} />}
      {!isMostLikedVideosLoading && (
        <VideoCarousel
          title='Most Liked'
          url={Routes.Common.mostLiked}
          videos={isMostLikedVideosError ? [] : getFlatDataFromPages(mostLikedVideos)}
        />
      )}
    </div>
  )
}

export default CategoriesNavigatorContainer

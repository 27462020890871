import formatRelative from 'date-fns/formatRelative'

export const relativeTime = (time: string) => formatRelative(new Date(time), new Date())

export const timeFormat = (time: number) => (time > 9 ? time : `0${time}`)
/**
 * @function secondToHumanReadableTime
 * @description format second to human readable format
 * @param duration
 * @returns
 */
export const secondToHumanReadableTime = (duration: number) => {
  const hours = timeFormat(Math.floor(duration / 3600) % 60)
  const minutes = timeFormat(Math.floor(duration / 60) % 60)
  const seconds = timeFormat(Math.floor(duration % 60))
  return `${hours !== '00' ? `${hours}:` : ''}${minutes}:${seconds}`
}

export function wait(ms: number) {
  return new Promise<void>(res => {
    setTimeout(() => res(), ms)
  })
}

import { useEffect, useRef } from 'react'
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useSnackBar, Loading } from '@ting/ting-ui-components'

import { setSnackBar } from '@@ting/redux/actions'
import { lazyComponent } from '@@ting/utils'
import { selectSigningUp } from '@@ting/services/SignUpService/selectors'
import { smoothScroll } from '@@ting/utils/smoothScroll'

import ErrorBoundary from '@@ting/molecules/ErrorBoundary'
import { SignUp } from '@@ting/molecules/SignUp'

// End admin page
/* eslint-disable max-len */

// Pages
import MainPageContainer from '@@ting/containers/MainPageContainer'
import PageNotFound from '@@ting/molecules/PageNotFound'
import { Header } from '@@ting/molecules/Header'
import { Sidebar } from '@@ting/molecules/Sidebar'
import { useHandleLocationChangedSideEffect } from '@@ting/services/authorization/sideEffects'
import ConfigStore from '@@ting/utils/actions/ConfigProvider'
import FilterStore from '@@ting/utils/actions/FilterProvider'
import Routes from '@@ting/enums/routes'
import CustomClassWrapper from './CustomClassWrapper'
import { PrivateRoute } from './private-route'

import './App.scss'

const TrendingContainer = lazyComponent(
  () => import(/* webpackChunkName: 'TrendingContainer' */ '@@ting/containers/TrendingContainer'),
  true
)
const SearchContainer = lazyComponent(
  () => import(/* webpackChunkName: 'SearchContainer' */ '@@ting/containers/SearchContainer'),
  true
)
const MostLikedContainer = lazyComponent(
  () => import(/* webpackChunkName: 'MostLikedContainer' */ '@@ting/containers/MostLikedContainer'),
  true
)
const UserPageContainer = lazyComponent(
  () => import(/* webpackChunkName: 'UserPage' */ '@@ting/containers/UserPageContainer'),
  true
)
const PublicChannelContainer = lazyComponent(
  () => import(/* webpackChunkName: 'PublicChannel' */ '@@ting/containers/PublicChannelContainer'),
  true
)
const WatchVideoContainer = lazyComponent(
  () =>
    import(
      /* webpackChunkName: 'WatchContainer', webpackPrefetch: true  */ '@@ting/containers/WatchContainer/WatchVideoContainer'
    ),
  false
)
const WatchPlaylistContainer = lazyComponent(
  () => import(/* webpackChunkName: 'WatchPlaylist' */ '@@ting/containers/WatchContainer/WatchPlaylistContainer'),
  true
)
const WalletModalContainer = lazyComponent(
  () => import(/* webpackChunkName: 'WalletModal' */ '@@ting/containers/WalletModalContainer'),
  true
)
const Subscriptions = lazyComponent(
  () => import(/* webpackChunkName: 'Subscriptions' */ '@@ting/features/Subscriptions/containers/SubscriptionsList'),
  true
)

const HistoryList = lazyComponent(
  () => import(/* webpackChunkName: 'HistoryList' */ '@@ting/features/History/containers/HistoryList')
)
const ChannelDetails = lazyComponent(
  () => import(/* webpackChunkName: 'ChannelDetails' */ '@@ting/features/Channels/containers/ChannelDetails')
)
const ProfilePageContainer = lazyComponent(
  () => import(/* webpackChunkName: 'ProfilePageContainer' */ '@@ting/features/Profile/containers/ProfilePageContainer')
)
const PlaylistsList = lazyComponent(
  () => import(/* webpackChunkName: 'PlaylistsList' */ '@@ting/features/Playlist/containers/PlaylistsList')
)
const PlaylistDetails = lazyComponent(
  () => import(/* webpackChunkName: 'PlaylistDetails' */ '@@ting/features/Playlist/containers/PlaylistDetails')
)
const App = () => {
  const containerRef = useRef<HTMLDivElement>()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const signingUp = useSelector(selectSigningUp)
  const showSnackBar = useSnackBar()
  useHandleLocationChangedSideEffect().then()

  const handleLocation = () => {
    const tempLocation = { ...location }
    tempLocation.pathname = location.pathname.replace(/walletModal/gi, '')
    return tempLocation
  }

  useEffect(
    () =>
      history.listen(() => {
        const container = containerRef.current
        if (!container) {
          return
        }
        if ((history.action === 'PUSH' || history.action === 'REPLACE') && !history.location.search.includes('?tab')) {
          smoothScroll(container, { top: 0, left: 0 })
        }
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  // Sets axiosInstance interceptors
  useEffect(() => {
    dispatch((setSnackBar as any)({ showSnackBar }))
  }, [showSnackBar, dispatch])

  return (
    <div className='ting-app' ref={containerRef}>
      <ErrorBoundary>
        <ConfigStore>
          <FilterStore>
            {signingUp && <SignUp />}
            <Header />
            <Sidebar />
            <CustomClassWrapper>
              <Switch location={handleLocation()}>
                <Route exact path={Routes.Common.homePage} component={MainPageContainer} />
                <Route exact path={Routes.Auth.signup} component={MainPageContainer} />
                <Route exact path={Routes.Auth.login} component={MainPageContainer} />
                <Route exact path={Routes.Auth.loginCallback} component={Loading} />
                <Route exact path={Routes.Auth.logoutCallback} component={Loading} />

                <Route exact path={Routes.Common.trending} component={TrendingContainer} />
                <Route exact path={Routes.Common.mostLiked} component={MostLikedContainer} />
                <Route exact path={Routes.Common.search} component={SearchContainer} />
                <PrivateRoute exact path={Routes.Common.subscriptions} component={Subscriptions} />
                <Route exact path={Routes.User.history} component={HistoryList} />
                <Route exact path={Routes.Channel.channelsListID} component={ChannelDetails} />
                <Redirect exact path='/my-channels/*' to='/channels/list' />
                <Redirect exact path='/my-playlists' to='/playlists/list' />
                <Route exact path={Routes.User.playlistsList} component={PlaylistsList} />
                <Route exact path={Routes.User.playlistsUUID} component={PlaylistDetails} />
                <PrivateRoute path={Routes.User.settings} component={ProfilePageContainer} />

                <Route
                  exact
                  path={Routes.Video.watchPlaylistUUID}
                  render={({ match }) => <Redirect to={`${match.url}/1`} />}
                />
                <Route exact path={Routes.Video.watchPlaylistPosition} component={WatchPlaylistContainer} />
                <Route exact path={Routes.Video.watchVideoUUID} component={WatchVideoContainer} />

                <Route exact path={Routes.User.accounts} component={UserPageContainer} />
                <Route exact path={Routes.Channel.publicChannel} component={PublicChannelContainer} />
                <Route component={PageNotFound} />
              </Switch>
              <Route path={Routes.Common.walletModal} component={WalletModalContainer} />
            </CustomClassWrapper>
          </FilterStore>
        </ConfigStore>
      </ErrorBoundary>
    </div>
  )
}

export default App

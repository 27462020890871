const matches = [/accounts\/([0-9a-z_-]+)$/i, /video-channels\/([0-9a-z_-]+)$/i]

export const getUniqueClassName = location => {
  let pathName = location.pathname
  for (let pattern of matches) {
    const found = pathName.match(pattern)
    if (found) {
      const url = found[0].split('/')
      return url[url.length - 1]
    }
  }
}

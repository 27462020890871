import { FC } from 'react'
import { PlayListItemSkeleton } from '../Skeleton'

type InfinitePlayListSkeletonProps = {
  isMobile: boolean
}
const InfinitePlayListSkeleton: FC<InfinitePlayListSkeletonProps> = ({ isMobile }) => (
  <>
    {[...Array(5).keys()].map((item: number) => (
      <PlayListItemSkeleton key={item} isMobile={isMobile} />
    ))}
  </>
)
export default InfinitePlayListSkeleton

import { FC, useRef, useEffect } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Text, Button, ArrowDownIcon } from '@ting/ting-ui-components'
import { IVideoDetails } from '@@ting/models'
import { MobileDialogLayout } from '@@ting/molecules'
import { useCopy, useMobile } from '@@ting/utils/hooks'

import './UploadPreview.scss'
import { CopyOutlinedIcon } from '@ting/ting-ui-components/dist/icons'
import Routes from '@@ting/enums/routes'
import routeGenerator from '@@ting/helpers'
import audioIcon from './assets/audioIcon.svg'

type Props = {
  className?: string
  percentage: number
  fileToUpload: File
  video: IVideoDetails
  onBack: () => void
  onNext: () => void
  uploadingAudio?: boolean
}

export const UploadPreview: FC<Props> = ({
  className,
  percentage,
  fileToUpload,
  video,
  onBack,
  onNext,
  uploadingAudio,
}) => {
  const { t } = useTranslation('uploadDialog')
  const isMobile = useMobile()
  const previewVideoRef = useRef(null)
  const previewUrl = `${window.location.origin}/${routeGenerator(
    Routes.Video.watchVideoUUID,
    {
      videoUUID: video?.uuid,
    },
    null
  )}`
  const [, , copyToClipboard] = useCopy(previewUrl, { snackBarMessage: t('urlCopied') })

  useEffect(() => {
    if (uploadingAudio) return
    previewVideoRef.current.src = URL.createObjectURL(fileToUpload)
  }, [fileToUpload, uploadingAudio])

  return (
    <MobileDialogLayout
      className={cn('upload-preview', className)}
      contentClassName='upload-preview-content'
      headerTitle='Preview'
      headerIcon={<ArrowDownIcon width={20} height={20} style={{ transform: 'rotate(90deg)' }} />}
      onHeaderClick={onBack}
    >
      <div className='upload-preview-body'>
        <div className={cn('progress-container', { 'upload-done': percentage === 100 })}>
          {uploadingAudio ? (
            <div className='audio-preview'>
              <img src={audioIcon} alt='audio' />
            </div>
          ) : (
            <video ref={previewVideoRef} className='video-preview' autoPlay muted loop />
          )}
          {/* <CircularProgress progress={percentage} size={108} indicatorWidth={8} showPercentage={false} /> */}
          <Text type='main' size='xlarge' className='progress-text'>
            {percentage}%
          </Text>
        </div>

        {video?.uuid && (
          <div className='open-video-box'>
            <Text size='small' className='open-video-box-title'>
              {t('linkToVideo')}
            </Text>
            <div className='video-link-row'>
              <a href={previewUrl} target='_blank' rel='noreferrer' className='video-link'>
                <Text type='main' size='small' className='video-link-text'>
                  {previewUrl}
                </Text>
              </a>
              <Button
                type='button'
                className='copy-button'
                theme='transparent'
                size='small'
                icon={<CopyOutlinedIcon width={24} height={30} />}
                onClick={() => copyToClipboard(previewUrl)}
              />
            </div>

            <Text size='small' className='open-video-box-title'>
              {t('fileName')}
            </Text>
            <Text size='small' className='video-name'>
              {video?.name}
            </Text>
          </div>
        )}
      </div>

      {isMobile && (
        <div className='button-section'>
          <Button
            className='button-section__action-button'
            type='button'
            theme='primary'
            size='medium'
            onClick={onNext}
          >
            {t('next')}
          </Button>
        </div>
      )}
    </MobileDialogLayout>
  )
}

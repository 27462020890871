import i18n, { InitOptions, ReactOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'

const defaultLang = 'en'

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`@@ting/locales/${language}/${namespace}.json`)
        .then(resources => {
          callback(null, resources)
        })
        .catch(error => {
          callback(error, null)
        })
    })
  )
  .init({
    lng: defaultLang,
    fallbackLng: 'en',
    defaultNS: 'common',
    react: {
      wait: true,
      useSuspense: true,
    },
  } as InitOptions & ReactOptions)

export default i18n

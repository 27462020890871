import { setSnackBar as setSnackBarActionCreator, showSnackBar as showSnackBarActionCreator } from '../actions'

const setSnackBarType = setSnackBarActionCreator.getType()
const showSnackBarType = showSnackBarActionCreator.getType()

export const snackBarMiddleware = () => {
  let showSnackBar = null
  return next => action => {
    if (action.type === setSnackBarType) {
      showSnackBar = action.payload.showSnackBar
    }
    if (action.type === showSnackBarType && showSnackBar) {
      showSnackBar(action.payload)
    }
    return next(action)
  }
}

import { FC, useEffect, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import cn from 'classnames'
import { Helmet } from 'react-helmet-async'
import { InfiniteData } from 'react-query'
import { useTranslation } from 'react-i18next'
import {
  useSnackBar,
  Heading,
  Button,
  ArrowDownIcon,
  FormTextField,
  FormTextArea,
  FormTagsComponent,
  FormCheckbox,
  Option,
  SelectValue,
} from '@ting/ting-ui-components'

import { IPlayList, IVideoDetails, VideoPrivacy } from '@@ting/models'
import {
  useGetVideoFullDescription,
  useGetCategories,
  useGetLanguages,
  useGetLicenses,
  useGetPrivacies,
  useGetChannelPlaylists,
  useUpdateVideo,
  QueryPage,
} from '@@ting/services/api/query-hooks'

import { MobileDialogLayout, SelectField } from '@@ting/molecules'
import { useHistoryState, useMobile } from '@@ting/utils/hooks'
import { fileURL, getErrorMessage } from '@@ting/utils'
import Configs from '@@ting/configs/general'
import getFlatDataFromPages from '@@ting/helpers/getFlatDataFromPages'
import { tagSuggestions } from './tempConstants'
import { AddEditVideoFormSteps } from './AddEditVideoFormSteps'
import { SelectThumbnail } from './components'
import './AddEditVideoForm.scss'

const headerTitles = ['General settings', 'Video details', 'Additional settings']

const videoFormSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Too short!').max(100, 'Too long').required('* Required'),
  description: Yup.string().required('* Required').min(3, 'Too short!').max(3000, 'Too long!'),
  channel: Yup.mixed().required('* Required'),
  category: Yup.mixed().required('* Required'),
  language: Yup.mixed().required('* Required'),
  privacy: Yup.mixed().required('* Required'),
  license: Yup.mixed().required('* Required'),
  tags: Yup.array().max(Configs.MAX_NUMBER_OF_TAGS, 'You can only use up to 5 tags'),
})

type FormValues = {
  channel: SelectValue
  playlist: Option
  name: string
  description: string
  tags: Option[]
  category: Option
  language: Option
  privacy: Option
  license: Option
  nsfw: boolean
  waitTranscoding: boolean
  commentsEnabled: boolean
  downloadEnabled: boolean
  secondaryThumbnailfile?: File
}

type Props = {
  className?: string
  video: IVideoDetails
  isEdit?: boolean
  isMyChannelsLoading?: boolean
  myChannels?: InfiniteData<QueryPage<any>>
  onBack: () => void
  onSubmit: () => void
}

export const AddEditVideoForm: FC<Props> = ({
  className,
  video,
  isEdit,
  myChannels,
  isMyChannelsLoading,
  onBack,
  onSubmit,
}) => {
  const [step, stepHelpers] = useHistoryState(0)
  const isMobile = useMobile()
  const { t } = useTranslation('uploadDialog')
  const showSnackBar = useSnackBar()
  const formikRef = useRef<FormikProps<FormValues>>(null)
  const { id: videoId, channel } = video
  const [currentChannel, setCurrentChannel] = useState<string | number>(channel.displayName)
  const [currentPlayList, setCurrentPlayList] = useState<SelectValue>(null)

  const { data: channelPlayLists, isLoading: isChannelPlaylistsLoading } = useGetChannelPlaylists(
    currentChannel as string,
    {
      start: 0,
      count: 15,
      sort: '-createdAt',
    }
  )
  useEffect(() => {
    setCurrentPlayList(channelPlayLists as unknown as SelectValue)
  }, [channelPlayLists])
  useEffect(() => {
    setCurrentPlayList(null)
  }, [currentChannel])
  const { data: categories } = useGetCategories()
  const { data: languages } = useGetLanguages()
  const { data: privacies } = useGetPrivacies()
  const { data: licenses } = useGetLicenses()
  const { data: fullDescription } = useGetVideoFullDescription(video.descriptionPath)
  const updateVideoMutation = useUpdateVideo()

  const initialValues: FormValues = {
    channel: channel ? { label: channel.displayName, value: channel.id.toString() } : null,
    playlist: null,
    name: video?.name || '',
    description: fullDescription?.description || video.description || '',
    tags: (video?.tags || []).map((name, index) => ({ value: index.toString(), label: name })),
    category: video?.category ? { label: video.category.label, value: video.category.id.toString() } : null,
    language: video?.language ? { label: video.language.label, value: video.language.id } : null,
    privacy: video?.privacy ? { label: video.privacy.label, value: video.privacy.id.toString() } : null,
    license: video?.licence ? { label: video.licence.label, value: video.licence.id.toString() } : null,
    nsfw: video?.nsfw || false,
    waitTranscoding: video?.waitTranscoding || false,
    commentsEnabled: video?.commentsEnabled || false,
    downloadEnabled: video?.downloadEnabled || false,
    secondaryThumbnailfile: null,
  }

  const handleFormBack = () => {
    stepHelpers.pop()
  }

  const handleFormNext = () => {
    formikRef.current.validateField('description')
    if (step === 1 && formikRef.current.errors.description) {
      formikRef.current.setTouched({ description: true })
      return
    }

    stepHelpers.push(step + 1)
  }
  const handleFormSubmit = async (values: FormValues) => {
    try {
      await updateVideoMutation.mutateAsync({
        videoId,
        channelId: (values.channel as Option).value,
        name: values.name,
        description: values.description,
        thumbnailfile: null,
        previewfile: null,
        tags: values.tags.map((option: Option) => option.label),
        category: values.category.value,
        language: values.language?.value,
        licence: values.license?.value,
        privacy: values.privacy?.value as unknown as VideoPrivacy,
        nsfw: values.nsfw,
        waitTranscoding: values.waitTranscoding,
        commentsEnabled: values.commentsEnabled,
        downloadEnabled: values.downloadEnabled,
        secondaryThumbnailfile: values.secondaryThumbnailfile,
      })
      showSnackBar({ message: isEdit ? 'Successfully updated video form' : 'Successfully added video' })
      onSubmit()
    } catch (error) {
      showSnackBar({ message: getErrorMessage(error) })
      stepHelpers.pop()
      stepHelpers.pop()
    }
  }
  const handleChangeChannel = (name: string, value: SelectValue) => {
    formikRef.current.setFieldValue(name, value)
    setCurrentChannel((value as Option).label)
  }
  const handleChangePlaylist = (name: string, value: SelectValue) => {
    formikRef.current.setFieldValue(name, value)
    setCurrentPlayList(value as Option)
  }

  const handleChangeThumbnail = (thumbnail?: File) => {
    formikRef.current.setFieldValue('secondaryThumbnailfile', thumbnail)
  }

  return (
    <>
      <Helmet>
        <title>{isEdit ? t('editTitle', { name: video.name }) : t('upload')}</title>
      </Helmet>
      <MobileDialogLayout
        headerTitle={headerTitles[step]}
        headerIcon={<ArrowDownIcon width={20} height={20} style={{ transform: 'rotate(90deg)' }} />}
        onHeaderClick={step === 0 ? onBack : handleFormBack}
      >
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={videoFormSchema}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched, values, isSubmitting }) => (
            <form className={cn('add-edit-video-form', className)} onSubmit={handleSubmit}>
              {step === 0 && (
                <div className='form-section'>
                  <Heading className='section-title' type='h1'>
                    {t('generalSettings')}
                  </Heading>
                  <SelectField
                    name='channel'
                    placeholder={t('selectChannel')}
                    label={t('channel')}
                    className='form-field'
                    value={values.channel}
                    setFieldValue={handleChangeChannel}
                    disabled={isMyChannelsLoading}
                    options={getFlatDataFromPages(myChannels)?.map(
                      ({ displayName, id }: { displayName: string; id: string }) => ({
                        label: displayName,
                        value: id,
                      })
                    )}
                  />
                  <SelectField
                    name='playlist'
                    placeholder={t('none')}
                    label={t('playlist')}
                    className='form-field'
                    value={currentPlayList}
                    setFieldValue={handleChangePlaylist}
                    disabled={isChannelPlaylistsLoading}
                    options={getFlatDataFromPages(channelPlayLists).map(({ displayName, id }: IPlayList) => ({
                      label: displayName,
                      value: id,
                    }))}
                  />
                </div>
              )}

              {step === 1 && (
                <div className='form-section'>
                  <Heading className='section-title' type='h1'>
                    {t('videoDetails')}
                  </Heading>
                  <FormTextField
                    className='form-field'
                    name='name'
                    label={t('videoTitle')}
                    placeholder={t('videoTitle')}
                    v2
                  />
                  <FormTextArea
                    className='form-field'
                    label={t('videoDescription')}
                    placeholder={t('videoDescription')}
                    name='description'
                    v2
                    maxLength={300}
                  />
                  <FormTagsComponent
                    name='tags'
                    label={t('videoTags')}
                    className='form-field'
                    suggestions={tagSuggestions}
                    value={values.tags}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    maxCount={10}
                  />
                  <SelectThumbnail
                    generatedThumbnailUrl={isEdit ? fileURL(video.thumbnailPath) : video?.thumbnailUrl}
                    secondaryThumbnailfile={values.secondaryThumbnailfile}
                    onChange={handleChangeThumbnail}
                  />
                </div>
              )}

              {step === 2 && (
                <div className='form-section'>
                  <Heading className='section-title' type='h1'>
                    {t('additionalSettings')}
                  </Heading>
                  <SelectField
                    name='category'
                    label={t('category')}
                    placeholder={t('selectCategory')}
                    className='form-field'
                    value={values.category}
                    setFieldValue={setFieldValue}
                    isSearchable
                    options={Object.keys(categories || {}).map(id => ({ label: categories[id], value: id }))}
                  />
                  <SelectField
                    name='language'
                    label={t('language')}
                    placeholder={t('selectLanguage')}
                    className='form-field'
                    value={values.language}
                    setFieldValue={setFieldValue}
                    isSearchable
                    options={Object.keys(languages || {})?.map(id => ({ label: languages[id], value: id }))}
                  />
                  <SelectField
                    name='privacy'
                    label={t('privacy')}
                    placeholder={t('selectPrivacy')}
                    className='form-field'
                    value={values.privacy as Option}
                    setFieldValue={setFieldValue}
                    options={Object.keys(privacies || {})?.map(id => ({ label: privacies[id], value: id }))}
                  />
                  <SelectField
                    name='license'
                    label={t('license')}
                    placeholder={t('selectLicense')}
                    className='form-field'
                    value={values.license as Option}
                    setFieldValue={setFieldValue}
                    options={Object.keys(licenses || {})?.map(id => ({ label: licenses[id], value: id }))}
                  />
                  <div className='form-field'>
                    <FormCheckbox id='nsfw' name='nsfw'>
                      {t('nsfw')}
                    </FormCheckbox>
                    <FormCheckbox id='waitTranscoding' name='waitTranscoding'>
                      {t('waitTranscoding')}
                    </FormCheckbox>
                    <FormCheckbox id='commentsEnabled' name='commentsEnabled'>
                      {t('commentsEnabled')}
                    </FormCheckbox>
                    <FormCheckbox id='downloadEnabled' name='downloadEnabled'>
                      {t('downloadEnabled')}
                    </FormCheckbox>
                  </div>
                </div>
              )}

              <div className='button-section'>
                {!isMobile && <AddEditVideoFormSteps className='steps' steps={3} completedSteps={step} />}
                {step < 2 && (
                  <Button
                    className='button-section__action-button'
                    type='brand'
                    htmlType='button'
                    theme='primary'
                    size={isMobile ? 'medium' : 'big'}
                    onClick={handleFormNext}
                    v2
                  >
                    {t('next')}
                  </Button>
                )}
                {step === 2 && (
                  <Button
                    className='button-section__action-button'
                    type='brand'
                    theme='primary'
                    size={isMobile ? 'medium' : 'big'}
                    disabled={isSubmitting || !videoId}
                    v2
                  >
                    {isEdit ? t('save') : t('publish')}
                  </Button>
                )}

                {step > 0 && (
                  <Button
                    className='button-section__action-button'
                    type='neutral'
                    theme='secondary'
                    size={isMobile ? 'medium' : 'big'}
                    onClick={handleFormBack}
                    v2
                  >
                    {t('back')}
                  </Button>
                )}
              </div>
            </form>
          )}
        </Formik>
      </MobileDialogLayout>
    </>
  )
}

import { FC } from 'react'
import cn from 'classnames'
import { Loading, Modal } from '@ting/ting-ui-components'
import { AddEditVideoForm } from '@@ting/molecules'
import { useMobile } from '@@ting/utils/hooks'
import './EditVideoDialog.scss'
import { useGetVideoDetails } from '@@ting/services/api/query-hooks'

type Props = {
  className?: string
  videoUUID: string
  onVideoEdited: () => void
  onClose: () => void
}

export const EditVideoDialog: FC<Props> = ({ className, videoUUID, onVideoEdited, onClose }) => {
  const isMobile = useMobile()
  const {
    data: videoToEdit,
    isLoading: videoDetailsIsLoading,
    isError: videoDetailsIsError,
  } = useGetVideoDetails(videoUUID)

  return (
    <Modal onClose={onClose} isOpen scrollable fullscreen={isMobile} hideCloseButton={isMobile}>
      <div className={cn('edit-video-dialog', className)}>
        {videoDetailsIsLoading ? (
          <Loading />
        ) : (
          <AddEditVideoForm
            className='edit-video-form'
            isEdit
            video={videoDetailsIsError ? [] : videoToEdit}
            onBack={onClose}
            onSubmit={onVideoEdited}
          />
        )}
      </div>
    </Modal>
  )
}

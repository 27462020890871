import { useState } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { Button, ChevronIcon, ChevronTopIcon, HrLine, UserAvatar } from '@ting/ting-ui-components'
import {
  useGetCategories,
  useGetMyChannels,
  useGetUserFollowingList,
  useGetUserInfoWhenNeed,
} from '@@ting/services/api/query-hooks'
import sidebarList from '@@ting/configs/sidebarList'
import { selectAuthorizationInitiated } from '@@ting/redux'
import { loginSideEffect } from '@@ting/services/authorization/sideEffects'
import { sidebarItemsLimitationCount } from '@@ting/utils/constants/common'

import { iconMap } from '@@ting/molecules/CategoriesNav'
import { useConfig, useFilterParams } from '@@ting/utils/actions'
import { sliceArray } from '@@ting/helpers/sliceArray'
import SidebarContentSkeleton from './SidebarContentSkeleton'

import './SidebarContent.scss'

type Props = {
  className?: string
}
export const SidebarContent: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation('common')
  const { configState } = useConfig()
  const { data: user, isLoading: userLoading } = useGetUserInfoWhenNeed()
  const { data: myFollowingsList, isLoading: myFollowingsListLoading } = useGetUserFollowingList(user?.username)
  const { data: categoriesList, isFetching: categoriesLoading } = useGetCategories()
  const { data: userChannel, isLoading: userChannelLoading } = useGetMyChannels(user?.username)
  const { filterParamsActions } = useFilterParams()
  const [viewAll, setViewAll] = useState(false)
  const authInitialized = useSelector(selectAuthorizationInitiated)
  const handleViewAll = () => {
    setViewAll(!viewAll)
  }
  const handleLogin = () => {
    loginSideEffect()
  }

  const expandedMenu = configState.sidebarStatus || configState.sidebarMode === 'drawer'

  const SidebarCategories = () =>
    userLoading || categoriesLoading ? (
      <SidebarContentSkeleton sidebarExpanded={expandedMenu} />
    ) : (
      <>
        <div className='sidebarContent-title'>{t('categories')}</div>
        {sliceArray(Object.entries(categoriesList), viewAll)?.map(([id, category]) => (
          <div key={id}>
            <NavLink
              to='invalid'
              className='sidebarContent-item'
              onClick={() => filterParamsActions.changeCategory(id)}
            >
              <div className='sidebarContent-item-icons'>{iconMap[category as string]}</div>
              {expandedMenu && category}
            </NavLink>
          </div>
        ))}
      </>
    )

  const SidebarFollowingsList = () =>
    userLoading || myFollowingsListLoading ? (
      <SidebarContentSkeleton sidebarExpanded={expandedMenu} />
    ) : (
      <>
        {myFollowingsList?.data?.length > 0 && (
          <>
            <div className='sidebarContent-title'>{t('following')}</div>
            {sliceArray(myFollowingsList?.data, viewAll).map(({ Following }) => (
              <div key={Following.Actor.id}>
                <NavLink to={`/accounts/${Following.Actor.preferredUsername}`} className='sidebarContent-item'>
                  <div className='sidebarContent-item-icons'>
                    <UserAvatar
                      alt=''
                      image={Following.Actor.Avatar?.fileUrl}
                      userName={Following.Actor.preferredUsername}
                      size='xs'
                    />
                  </div>
                  {expandedMenu && Following.Actor.preferredUsername}
                </NavLink>
              </div>
            ))}
          </>
        )}
      </>
    )

  const UserChannelList = () =>
    userChannelLoading ? (
      <SidebarContentSkeleton sidebarExpanded={expandedMenu} />
    ) : (
      <>
        <div className='sidebarContent-title'>My Channels</div>
        {userChannel?.pages[0]?.data?.map(channel => (
          <div key={channel.id}>
            <NavLink to={`/video-channels/${channel.name}`} className='sidebarContent-item'>
              <div className='sidebarContent-item-icons'>
                <UserAvatar
                  className='channel-avatar'
                  alt=''
                  image={channel.avatar?.fileUrl}
                  userName={channel.name}
                  size='xs'
                />
              </div>
              {expandedMenu && channel.name}
            </NavLink>
          </div>
        ))}
      </>
    )

  return (
    <div className={cn('sidebarContent', className, { 'sidebarContent-expanded': expandedMenu })}>
      {sidebarList.map(({ title, link, icon, hasSeparator }) => (
        <div key={title}>
          <NavLink exact className='sidebarContent-item' activeClassName='active' to={link}>
            <div className='sidebarContent-item-icons'>{icon}</div>
            {expandedMenu && t(title)}
          </NavLink>
          {hasSeparator && <HrLine className='line-divider' />}
        </div>
      ))}
      {user ? <SidebarFollowingsList /> : <SidebarCategories />}
      {(!user || (myFollowingsList?.data.length > sidebarItemsLimitationCount && user)) && (
        <Button
          theme='transparent'
          onClick={handleViewAll}
          className='sidebarContent-viewAll'
          icon={viewAll ? <ChevronTopIcon width={24} height={8} /> : <ChevronIcon width={24} height={8} />}
        >
          {t(viewAll ? 'viewLess' : 'viewAll')}
        </Button>
      )}
      <HrLine className='line-divider' />
      {user && <UserChannelList />}
      {!user && (
        <>
          <HrLine className='line-divider' />
          <Button
            className='sidebarContent-sign-in'
            v2
            type='brand'
            variant='secondary'
            size='medium'
            onClick={handleLogin}
            disabled={authInitialized}
          >
            {t('signIn')}
          </Button>
        </>
      )}
    </div>
  )
}

import { createStore, applyMiddleware } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import { install, combineReducers } from 'redux-loop'
import { createBrowserHistory } from 'history'

import { historyStateMiddleware, snackBarMiddleware } from './middlewares'
import reducers from './reducers'

export const history = createBrowserHistory()

// eslint-disable-next-line no-unused-vars
export default function configureStore(initialState) {
  const reducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  })

  // Init enhancer
  const enhancer = composeWithDevTools(
    install(),
    applyMiddleware(routerMiddleware(history), historyStateMiddleware, snackBarMiddleware)
  )

  const store = createStore(reducer, initialState, enhancer)

  return store
}

import { FC, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { Text, Button, ThreeDotsIcon, Popover } from '@ting/ting-ui-components'
import { useMobile } from '@@ting/utils/hooks'

import './ContextMenu.scss'

export type Items = {
  label: string | React.ReactElement
  onClick: () => void
}
type Props = {
  className?: string
  dotsClassName?: string
  items: Items[]
  backdropElem?: HTMLElement
}

type ItemProps = {
  items: Items[]
  handleItemClick: (e: () => void) => any
}

const ContextMenuItem: FC<ItemProps> = props => {
  const { items, handleItemClick } = props
  return (
    <>
      {items.map(({ label, onClick }) => {
        if (typeof label === 'string') {
          return (
            <Text type='sub' size='small' className='item-link-btn' key={label} onClick={handleItemClick(onClick)}>
              {label}
            </Text>
          )
        }
        return <span onClick={handleItemClick(onClick)}>{label}</span>
      })}
    </>
  )
}
export const ContextMenu: FC<Props> = ({ className, dotsClassName, backdropElem, items }) => {
  const popoverContainerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useMobile()
  const handleToggleClick = () => setIsOpen(!isOpen)

  const handleItemClick = (onClick: () => void) => () => {
    setIsOpen(false)
    onClick()
  }

  useEffect(() => {
    const backdrop = backdropElem || window

    const handleBackdropClick = (event: Event) => {
      const popoverContainer = popoverContainerRef.current
      if (popoverContainer && !popoverContainer.contains(event.target as HTMLElement)) {
        setIsOpen(false)
      }
    }

    backdrop.addEventListener('click', handleBackdropClick)
    return () => backdrop.removeEventListener('click', handleBackdropClick)
  }, [backdropElem])

  return (
    <div className={cn('context-menu', className)}>
      <div ref={popoverContainerRef} className={dotsClassName}>
        <Button theme='transparent' round onClick={handleToggleClick}>
          <ThreeDotsIcon />
        </Button>
      </div>

      {!isMobile && (
        <Popover
          target={popoverContainerRef.current}
          className='context-menu-inner'
          offset={[-40, 15]}
          placement='bottom'
        >
          <ContextMenuItem items={items} handleItemClick={handleItemClick} />
        </Popover>
      )}
      {isMobile && isOpen && (
        <>
          <div className='context-menu-mobile-content__mask' onClick={() => setIsOpen(false)} />
          <div className='context-menu-mobile-content'>
            <ContextMenuItem items={items} handleItemClick={handleItemClick} />
          </div>
        </>
      )}
    </div>
  )
}

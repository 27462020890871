import { FC, useEffect } from 'react'
import cn from 'classnames'
import { Button, MenuIcon, ChevronIcon, UserAvatar, Drawer } from '@ting/ting-ui-components'
import { useLocation } from 'react-router-dom'
import { IUser } from '@@ting/models'
import { logOutSideEffect, loginSideEffect as logInAction } from '@@ting/services/authorization/sideEffects'
import { selectAuthorizationInitiated, selectIsAuthorized } from '@@ting/redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { UploadVideo } from '@@ting/molecules/Header/components'
import { useHistoryState } from '@@ting/utils/hooks'
import { useGetUserInfo } from '@@ting/services/api/query-hooks'
import Routes from '@@ting/enums/routes'
import routeGenerator from '@@ting/helpers'
import MenuGroup from './MenuGroup'
import MenuItem from './MenuItem'

import './MobileMenu.scss'

type Props = {
  className?: string
}

enum PAGES {
  SIGN_IN,
  MAIN,
  PROFILE,
  GROUPS,
}
interface ProfileSectionMenuProps {
  user: IUser
}

const ProfileSectionMenu: FC<ProfileSectionMenuProps> = ({ user }) => {
  const dispatch = useDispatch()
  const handleLogout = async () => {
    await logOutSideEffect(dispatch)
  }

  return (
    <>
      <div className='drawer-menu-section drawer-indent-wrapper'>
        <MenuItem smallFont title='Profile' link={routeGenerator(Routes.User.account, { name: user.username })} />
        <MenuItem
          smallFont
          title='My channels'
          link={routeGenerator(Routes.User.account, { name: user.username }, { tab: 'channels' })}
        />
        <MenuItem smallFont title='Playlists' link={Routes.User.playlistsList} />
        <MenuItem smallFont title='Following' link={Routes.User.subscriptions} />
        <MenuItem smallFont title='History' link={Routes.User.history} />
      </div>
      <MenuItem smallFont title='Log out' onClick={handleLogout} className='drawer-indent-item' />
    </>
  )
}
export const MobileMenu: FC<Props> = ({ className }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useHistoryState(false)

  const isAuthorized = useSelector(selectIsAuthorized)
  const { data: user } = useGetUserInfo({ enabled: isAuthorized })
  const [page, setPage] = useHistoryState<PAGES>(PAGES.SIGN_IN)
  const location = useLocation()
  const authInitialized = useSelector(selectAuthorizationInitiated)

  const handleLogin = async () => {
    await logInAction()
  }

  useEffect(() => {
    setPage.push(user?.username ? PAGES.MAIN : PAGES.SIGN_IN)
  }, [setPage, user?.username])

  useEffect(() => {
    setIsOpenDrawer.pop()
  }, [setIsOpenDrawer, location.pathname])

  return (
    <>
      <div className={cn('mobile-menu', className)}>
        <Button
          className='mobile-menu-toggle'
          theme='transparent'
          size='large'
          onClick={() => setIsOpenDrawer.push(true)}
          icon={<MenuIcon width={28} height={28} />}
        />
      </div>
      <Drawer isOpen={isOpenDrawer} onClose={() => setIsOpenDrawer.pop()} position='right' className='mobile-drawer'>
        <>
          <div className='drawer-header'>
            {page === PAGES.MAIN && (
              <div className='user-header-content' onClick={() => setPage.push(PAGES.PROFILE)}>
                <UserAvatar image={user?.account?.avatar?.path} size='lg' />
                <span className='user-header-content__name'>{user?.account?.displayName}</span>
                <ChevronIcon className='user-header-content__arrow' width={12} height={6} />
              </div>
            )}
            {page === PAGES.PROFILE && (
              <MenuItem isHeaderItem onClick={() => setPage.pop()} title='Main Menu' backIcon />
            )}
            {page === PAGES.GROUPS && (
              <MenuItem isHeaderItem onClick={() => setPage.pop()} title='Main menu' backIcon />
            )}
            {page === PAGES.SIGN_IN && (
              <MenuItem isHeaderItem onClick={() => setPage.push(PAGES.GROUPS)} title='Groups' forwardIcon />
            )}
          </div>
          <div className='drawer-scrollable-content'>
            {page === PAGES.SIGN_IN && (
              <div className='sign-in-content'>
                <span className='title'>Sign in to Ting community</span>
                {/* <span className='caption'> */}
                {/*  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda, distinctio! */}
                {/*  <span className='link'>Learn more</span> */}
                {/* </span> */}
                <Button
                  disabled={authInitialized}
                  onClick={handleLogin}
                  theme='outline-primary'
                  className='btn-full-width'
                >
                  Sign in
                </Button>
              </div>
            )}
            {page === PAGES.MAIN && (
              <div className='user-loged-in-content'>
                <div className='drawer-menu-section'>
                  <MenuItem title='Groups' onClick={() => setPage.push(PAGES.GROUPS)} forwardIcon />
                  <MenuItem title='Subscription' link={Routes.Common.subscriptions} />
                </div>
                <div className='drawer-menu-section'>
                  <MenuItem smallFont title='Start Live Stream' />
                  <MenuItem smallFont title='Start Video Call' />
                  <MenuItem smallFont title='Earnings & Wallets' />
                </div>
                <UploadVideo
                  btnClick={() => setIsOpenDrawer.pop()}
                  className='user-content__upload'
                  btnClassName='user-content__upload-btn'
                />
              </div>
            )}
            {page === PAGES.PROFILE && <ProfileSectionMenu user={user} />}
            {page === PAGES.GROUPS && <MenuGroup onItemClick={() => setIsOpenDrawer.push(false)} />}
          </div>
        </>
      </Drawer>
    </>
  )
}

import { FC } from 'react'

import { Text } from '@ting/ting-ui-components'

import './EmptyVideoPreview.scss'

type Props = {
  message?: string
}

export const EmptyVideoPreview: FC<Props> = props => {
  const { message = 'Looks like something’s missing' } = props

  return (
    <div className='emptyVideoPreview'>
      <div className='emptyVideoPreview_block col' />
      <div className='emptyVideoPreview_block col' />
      <div className='emptyVideoPreview_block col' />
      <div className='emptyVideoPreview_block col' />
      <div className='emptyVideoPreview_message'>
        <Text size='medium' type='sub'>
          {message}
        </Text>
      </div>
    </div>
  )
}

export default EmptyVideoPreview

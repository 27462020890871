import { FC } from 'react'
import cn from 'classnames'
import { Skeleton } from '@ting/ting-ui-components'

type SidebarContentSkeletonProps = {
  sidebarExpanded: boolean
}
const SidebarContentSkeleton: FC<SidebarContentSkeletonProps> = ({ sidebarExpanded }) => (
  <>
    {[...Array(5).keys()].map((item: number) => (
      <div key={item} className='d-flex my-3 w-75 align-self-center align-items-center'>
        <Skeleton
          variant='circle'
          width={20}
          height={20}
          animation='pulse'
          className={cn({ 'mr-3': sidebarExpanded, 'm-auto': !sidebarExpanded })}
        />
        {sidebarExpanded && <Skeleton variant='rect' width={127} height={11} animation='pulse' />}
      </div>
    ))}
  </>
)
export default SidebarContentSkeleton

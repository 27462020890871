import { FC, Fragment } from 'react'

import { QueryPages, QueryPage } from '@@ting/services/api/query-hooks'
import { Waypoint } from '@@ting/molecules'
import isMobile from '@@ting/utils/isMobile'
import { EmptyPage } from '../EmptyPage'
import InfinitePlayListSkeleton from '../InfinitePlayList/InfinitePlayListSkeleton'

type InfiniteListProps = {
  data: QueryPages<any>
  isLoading: boolean
  isFetchingNextPage: boolean
  hasNextPage: boolean
  LoadingComponent?: React.ReactNode
  className?: string
  fetchNextPage: () => any
  itemRenderer: (d: any) => React.ReactElement
  EmptyState?: React.ReactElement
}

export const InfiniteList: FC<InfiniteListProps> = props => {
  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    LoadingComponent = <InfinitePlayListSkeleton isMobile={isMobile} />,
    className = '',
    fetchNextPage,
    itemRenderer,
    EmptyState = <EmptyPage />,
  } = props

  if (data?.pages[0].total === 0) {
    return EmptyState
  }

  return (
    <div className={className}>
      {data?.pages.map((page: QueryPage<any>, index: number) => (
        <Fragment key={`${index.toString()}-list-page`}>
          {page.data.map((item, i) =>
            index === data.pages.length - 1 && i === 0 && hasNextPage ? (
              <Waypoint onEnter={fetchNextPage} key={item.id || Math.random().toString()}>
                {itemRenderer(item)}
              </Waypoint>
            ) : (
              itemRenderer(item)
            )
          )}
        </Fragment>
      ))}
      {(isFetchingNextPage || isLoading) && LoadingComponent}
    </div>
  )
}

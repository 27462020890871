import { FC, useState } from 'react'
import cn from 'classnames'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Button, Heading, useSnackBar } from '@ting/ting-ui-components'
import { CloseOutlinedIcon, UploadCloudOutlinedIcon, LoadingOutlinedIcon } from '@ting/ting-ui-components/dist/icons'
import configs from '@@ting/configs'

import './SelectThumbnail.scss'

type Props = {
  generatedThumbnailUrl?: string
  secondaryThumbnailfile?: File
  onChange: (thumbnail?: File) => void
}
export const SelectThumbnail: FC<Props> = ({ generatedThumbnailUrl, secondaryThumbnailfile, onChange }) => {
  const showSnackBar = useSnackBar()
  const { t } = useTranslation('uploadDialog')
  const [selectedFileForUpload, setSelectedFileForUpload] = useState<File | null>(null)

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: configs.videos.maxUploadSize,
    multiple: false,
    onDropAccepted: (files: File[]) => {
      setSelectedFileForUpload(files[0])
      onChange(files[0])
    },
    onDropRejected: files => {
      showSnackBar({ message: files[0].errors[0].message })
    },
  })

  const handleClickRemoveSelectedFile = () => {
    setSelectedFileForUpload(null)
    onChange(null)
  }

  return (
    <div className='thumbnail-field'>
      <Heading className='thumbnail-title' type='h1'>
        {t('Thumbnail')}
      </Heading>
      <div className='select-thumbnails'>
        <div className='select-thumbnails_item'>
          {generatedThumbnailUrl ? (
            <div
              className={cn('select-thumbnails_image', {
                selected: !secondaryThumbnailfile,
              })}
              onClick={() => onChange(null)}
            >
              <img src={generatedThumbnailUrl} alt='thumbnail' />
            </div>
          ) : (
            <div className='loading-thumbnail'>
              <LoadingOutlinedIcon width={40} height={40} />
            </div>
          )}
          <div className='select-thumbnails_item_bottom-bar'>
            <span>{t('GeneratedImage')}</span>
          </div>
        </div>

        <div className='select-thumbnails_item'>
          {selectedFileForUpload ? (
            <div
              className={cn('select-thumbnails_image', {
                selected: Boolean(secondaryThumbnailfile),
              })}
              onClick={() => onChange(selectedFileForUpload)}
            >
              <img src={URL.createObjectURL(selectedFileForUpload)} alt='thumbnail' />
            </div>
          ) : (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className='select-thumbnails_drag-drop-box'>
                <UploadCloudOutlinedIcon width={20} height={20} />
                <span className='select-thumbnails_drag-drop-box-description'>{t('UploadThumbnail')}</span>
                <span className='select-thumbnails_drag-drop-box-description'>{t('UploadThumbnailSize')}</span>
              </div>
            </div>
          )}
          <div className='select-thumbnails_item_bottom-bar'>
            <span>{t('UploadedImage')}</span>
            {selectedFileForUpload && (
              <Button
                className='select-thumbnails_item_bottom-bar_button'
                type='danger'
                variant='ghost'
                startIcon={<CloseOutlinedIcon width={12} height={12} />}
                onClick={handleClickRemoveSelectedFile}
                v2
              >
                {t('RemoveThumbnail')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

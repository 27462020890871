export default [
  { id: '0', name: 'All' },
  { id: '1', name: 'Music' },
  { id: '2', name: 'Films' },
  { id: '3', name: 'Vehicles' },
  { id: '4', name: 'Art' },
  { id: '5', name: 'Sports' },
  { id: '6', name: 'Travels' },
  { id: '7', name: 'Gaming' },
  { id: '8', name: 'People' },
  { id: '9', name: 'Comedy' },
  { id: '10', name: 'Entertainment' },
  { id: '11', name: 'News&Politics' },
  { id: '12', name: 'HowTo' },
  { id: '13', name: 'Education' },
  { id: '14', name: 'Activism' },
  { id: '15', name: 'Science&Technology' },
  { id: '16', name: 'Animals' },
  { id: '17', name: 'Kids' },
  { id: '18', name: 'Food' },
]

import { v4 as uuidv4 } from 'uuid'

/* generate uniq id for sessionId or in case we need uniq id like key props */
export const uuid = uuidv4

/**
 * @function generateSessionIdStore
 * @description generate sessionID and store it on local storage
 * @param {string} key of item in localStorage
 */
export const generateUniqIdStore = (key = 'sessionId'): string => {
  const uniqueId = uuid()
  localStorage.setItem(key, uniqueId)
  return uniqueId
}

/**
 * @function handleSessionId
 * @description create UniqId as sessionId
 */
export const getSessionId = () => {
  let sessionId: string = localStorage.getItem('sessionId')
  if (!sessionId) {
    sessionId = generateUniqIdStore()
    localStorage.setItem('sessionId', sessionId)
  }
  return sessionId
}

export const createUUID = () => uuid()

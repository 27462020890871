import { FC } from 'react'
import cn from 'classnames'
import { Button } from '@ting/ting-ui-components'

import { UploadVideoDialog } from '@@ting/molecules/UploadVideoDialog'
import { useHistoryState } from '@@ting/utils/hooks'
import { AddVideoOutlinedIcon } from '@ting/ting-ui-components/dist/icons'

type Props = {
  className?: string
  btnClassName?: string
  btnClick?: () => void
}

export const UploadVideo: FC<Props> = ({ className, btnClassName, btnClick }) => {
  const [uploadModalOpen, uploadModalOpenHelpers] = useHistoryState(false)

  const handleUploadButtonClick = () => {
    uploadModalOpenHelpers.push(true)
    if (btnClick) {
      btnClick()
    }
  }

  const handleVideoPublished = () => {
    uploadModalOpenHelpers.pop()
  }

  const handleToggleUploadModal = () => {
    if (uploadModalOpen) {
      uploadModalOpenHelpers.pop()
    } else {
      uploadModalOpenHelpers.push(true)
    }
  }

  return (
    <div className={cn('upload-video', className)}>
      <Button className={cn(btnClassName)} theme='transparent' onClick={handleUploadButtonClick}>
        <AddVideoOutlinedIcon className='header-options-icon' />
      </Button>
      {uploadModalOpen && (
        <UploadVideoDialog onVideoPublished={handleVideoPublished} closeDialog={handleToggleUploadModal} />
      )}
    </div>
  )
}

import { FC } from 'react'
import cn from 'classnames'
import { useGetCategories } from '@@ting/services/api/query-hooks'
import { Loading } from '@ting/ting-ui-components'
import { useFilterParams } from '@@ting/utils/actions'
import MenuItem from '../MenuItem'

import './MenuGroup.scss'

/**
 * @interface MenuGroup Component IProps
 */
interface IMenuGroupProps extends React.HTMLProps<HTMLDivElement> {
  onItemClick?: () => void
}

/**
 * @param props: IMenuGroupProps
 */
const MenuGroup: FC<IMenuGroupProps> = props => {
  const { className, onItemClick } = props
  const { filterParamsActions } = useFilterParams()
  const {
    data: categoriesList,
    isLoading: categoriesListIsLoading,
    isError: categoriesListIsError,
  } = useGetCategories()

  const handleSetCategory = (id: string) => () => {
    filterParamsActions.changeCategory(id)
  }

  const handleItemClick = (id: string) => {
    handleSetCategory(id)
    if (onItemClick) {
      onItemClick()
    }
  }

  if (categoriesListIsLoading) return <Loading />
  return (
    <div className={cn('menugroup-container', className)}>
      {!categoriesListIsError &&
        Object.entries(categoriesList).map(([id, category]) => (
          <MenuItem onClick={() => handleItemClick(id)} key={id} title={category as string} smallFont />
        ))}
    </div>
  )
}

export default MenuGroup

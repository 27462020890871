import { FC } from 'react'
import './NavigateInput.scss'
import { ChevronIcon } from '@ting/ting-ui-components'

type Props = {
  value?: string[] | string
  className?: string
  label?: string
  onClick?: () => void
  defaultValue?: string
  onClear?: () => void
}

const NavigateInput: FC<Props> = props => {
  const { className = '', label, value, defaultValue, onClear, onClick } = props
  return (
    <div className={`navigate-input ${className}`}>
      <div className='navigate-input__label-wrapper'>
        {label && <span className='navigate-input__label'>{label}</span>}
        {onClear && value && (
          <span onClick={onClear} className='navigate-input__clear'>
            clear
          </span>
        )}
      </div>
      <div className='navigate-input__box' onClick={onClick}>
        {value && (
          <span className='navigate-input__value'>{Array.isArray(value) ? value.splice(3).join(',') : value}</span>
        )}
        {defaultValue && !value && <span className='navigate-input__value'>{defaultValue}</span>}
        <div className='navigate-input__icon'>
          <ChevronIcon width={12} height={12} className='chevron-right' />
        </div>
      </div>
    </div>
  )
}

export default NavigateInput

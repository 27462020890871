import { FC } from 'react'
import cn from 'classnames'
import { Skeleton } from '@ting/ting-ui-components'

import './CarouselSkeleton.scss'

type CarouselSkeletonProps = {
  className?: string
  itemsCount?: number
  isMobile: boolean
}
export const CarouselSkeleton: FC<CarouselSkeletonProps> = ({ className, itemsCount = 4, isMobile }) => (
  <div className={cn(className, 'carousel-sleleton')}>
    {[...Array(isMobile ? 1 : itemsCount).keys()].map((item: number) => (
      <div key={item} className='carousel-sleleton-item'>
        <Skeleton variant='rect' key={item} />
        <Skeleton width='25%' />
        <Skeleton width='50%' />
      </div>
    ))}
  </div>
)

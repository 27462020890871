import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Cropper from 'react-easy-crop'
import { Point } from 'react-easy-crop/types'
import getCroppedImg from '@@ting/helpers/cropImage'
import { Button, Modal } from '@ting/ting-ui-components'
import './CropImage.scss'

interface IProps {
  image: string
  onSave: (croppedImage: any) => void
  showGrid?: boolean
  cropShape?: 'rect' | 'round'
  aspect?: number
  isOpen?: boolean
  onClose?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const CropImage: FC<IProps> = ({
  onSave,
  image,
  showGrid = false,
  cropShape = 'rect',
  aspect = 1,
  isOpen = true,
  onClose = () => {},
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixelsValue, setCroppedAreaPixelsValue] = useState(null)
  const { t } = useTranslation('common')

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixelsValue(croppedAreaPixels)
  }, [])

  const onCroppedImageSave = useCallback(async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixelsValue)
    onSave(croppedImage)
  }, [croppedAreaPixelsValue, image, onSave])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='crop-modal-container'>
        <div className='crop-container'>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            cropShape={cropShape}
            showGrid={showGrid}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className='buttons-row '>
          <Button theme='primary' onClick={onCroppedImageSave}>
            {t('save')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

import { drawerSidebarRoutes } from '@@ting/configs/drawerSidebarRoutes'
import { useConfig } from '@@ting/utils/actions'
import { Button, Drawer, MenuThinIcon, TagType } from '@ting/ting-ui-components'
import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { HeaderLogo } from '../Header/components'
import { SidebarContent } from './components/SidebarContent'
import './Sidebar.scss'

export const Sidebar: React.FC = () => {
  const history = useHistory()
  const {
    configState: { sidebarMode, drawerStatus },
    configActions: { toggleSidebar, changeSidebarMode },
  } = useConfig()

  useLayoutEffect(() => {
    const drawerMode = drawerSidebarRoutes.some(route => history.location.pathname.startsWith(route))
    changeSidebarMode(drawerMode ? 'drawer' : 'normal')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.key])

  const menuContentEl = <SidebarContent className='menuContent' />
  return sidebarMode === 'drawer' ? (
    <Drawer
      isOpen={drawerStatus}
      onClose={toggleSidebar}
      position='left'
      className='menuDrawer'
      removeWhenClosed={false}
    >
      <div className='menuDrawer-logo'>
        <Button className='menu-button' theme='transparent' onClick={toggleSidebar}>
          <MenuThinIcon />
        </Button>
        <HeaderLogo tag={TagType.AnchorLink} />
      </div>
      {menuContentEl}
    </Drawer>
  ) : (
    menuContentEl
  )
}

import Routes from '@@ting/enums/routes'
import {
  RewindClockOutlinedIcon,
  HomeOutlinedIcon,
  ThumbsUpOutlinedIcon,
  TrendArrowOutlinedIcon,
  SceneOutlinedIcon,
  ChannelsOutlinedIcon,
  NewStarOutlinedIcon,
} from '@ting/ting-ui-components/dist/icons'
export default [
  {
    title: 'home',
    link: Routes.Common.homePage,
    icon: <HomeOutlinedIcon width={20} height={20} />,
    hasSeparator: false,
  },
  {
    title: 'trending',
    link: Routes.Common.trending,
    icon: <TrendArrowOutlinedIcon width={20} height={20} />,
    hasSeparator: false,
  },
  {
    title: 'following',
    link: Routes.Common.subscriptions,
    icon: <ChannelsOutlinedIcon width={20} height={20} />,
    hasSeparator: true,
  },
  {
    title: 'likedVideos',
    link: Routes.Common.mostLiked,
    icon: <ThumbsUpOutlinedIcon width={20} height={20} />,
    hasSeparator: false,
  },
  {
    title: 'playlists',
    link: Routes.User.playlistsList,
    icon: <SceneOutlinedIcon width={20} height={20} />,
    hasSeparator: false,
  },
  {
    title: 'premium',
    link: '/premium',
    icon: <NewStarOutlinedIcon width={20} height={20} />,
    hasSeparator: false,
  },
  {
    title: 'history',
    link: Routes.User.history,
    icon: <RewindClockOutlinedIcon width={20} height={20} />,
    hasSeparator: true,
  },
]

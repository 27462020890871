import { FC } from 'react'
import { Skeleton } from '@ting/ting-ui-components'
import cn from 'classnames'

type TabsSkeletonProps = {
  className?: string
  isMobile?: boolean
}
export const TabsSkeleton: FC<TabsSkeletonProps> = ({ className, isMobile }) => (
  <div className={cn('d-flex', className)}>
    {[...Array(isMobile ? 1 : 3).keys()].map((item: number) => (
      <Skeleton variant='rect' key={item} height={40} className='m-2' />
    ))}
  </div>
)

import { FC, useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { NotFoundPageIcon, Button } from '@ting/ting-ui-components'
import Routes from '@@ting/enums/routes'
import { useConfig } from '@@ting/utils/actions'

import './PageNotFound.scss'

export const PageNotFound: FC = () => {
  const { configActions } = useConfig()

  useLayoutEffect(() => {
    configActions.changeSidebarMode('drawer')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <div className='pageNotFound'>
        <div className='row'>
          <div className='container'>
            <div>
              <NotFoundPageIcon />
            </div>
            <div className='text-center'>
              <h1>Whoa!</h1>
              <p>Looks like something’s missing</p>
              <Button href={Routes.Common.homePage} theme='outline-primary'>
                Back to Home
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageNotFound

/* eslint-disable no-param-reassign */
import { useRef, FC } from 'react'
import { Swiper } from 'swiper/react'
import { Navigation, SwiperOptions } from 'swiper'
import cn from 'classnames'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import './Carousel.scss'
import { Button, SimpleArrowLeftIcon, SimpleArrowRightIcon } from '@ting/ting-ui-components'

interface Props extends SwiperOptions {
  className?: string
  navigationClassName?: string
}

export const Carousel: FC<Props> = ({ className, navigationClassName, children, ...props }) => {
  const navigationPrevRef = useRef<HTMLButtonElement | null>(null)
  const navigationNextRef = useRef<HTMLButtonElement | null>(null)

  return (
    <div className={cn('carousel', className)}>
      <div className='carousel__swiper-container'>
        <div className={cn('carousel__navigation', navigationClassName)}>
          <Button
            className='navigation-btn navigation-btn__prev swiper-button-prev'
            theme='secondary'
            round
            icon={<SimpleArrowLeftIcon width={30} height={30} />}
            forwardedRef={navigationPrevRef}
          />
          <Button
            className='navigation-btn navigation-btn__next swiper-button-next'
            theme='secondary'
            round
            icon={<SimpleArrowRightIcon width={30} height={30} />}
            forwardedRef={navigationNextRef}
          />
        </div>
        <Swiper
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={swiper => {
            if (typeof swiper.params.navigation !== 'boolean') {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
            }
          }}
          modules={[Navigation]}
          {...props}
        >
          {children}
        </Swiper>
      </div>
    </div>
  )
}

export default Carousel

import { useRef, useCallback } from 'react'

export const useDebounce = (callback, wait) => {
  const timeoutRef = useRef(null)
  const callbackRef = useRef(null)
  callbackRef.current = callback

  return useCallback(
    function (...args) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        callbackRef.current(...args)
        timeoutRef.current = null
      }, wait)
    },
    [wait]
  )
}

import { FC } from 'react'
import { Text } from '@ting/ting-ui-components'
import { SwiperOptions } from 'swiper'

import EmptyVideoPreview from '@@ting/molecules/EmptyVideoPreview'

import { Breakpoint } from '@@ting/utils/hooks'
import { SwiperSlide } from 'swiper/react'
import { IVideo } from '@@ting/models'
import { VideoPreview } from '@@ting/molecules'
import Carousel from '../Carousel'
import './VideoCarousel.scss'

interface Props extends SwiperOptions {
  url?: string
  title?: string
  className?: string
  videos: IVideo[]
}

export const VideoCarousel: FC<Props> = ({ url, title, className, videos, ...props }) => (
  <div className='video-carousel'>
    {title && (
      <div className='video-carousel__title'>
        {title && (
          <Text className='video-carousel__title-text' size='h4'>
            {title}
          </Text>
        )}
        {/* {url && (
          <Link tag='RouterLink' href={url} className='video-carousel__show-more-link'>
            Show more
          </Link>
        )} */}
      </div>
    )}
    <Carousel
      breakpoints={{
        [Breakpoint.XXXXL]: {
          slidesPerView: 6,
          slidesPerGroup: 6,
        },
        [Breakpoint.XXXL]: {
          slidesPerView: 5,
          slidesPerGroup: 5,
        },
        [Breakpoint.XXL]: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },

        [Breakpoint.XL]: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        [Breakpoint.LG]: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        [Breakpoint.MD]: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        [Breakpoint.SM]: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
      }}
      spaceBetween={10}
      navigationClassName='video-carousel__navigation'
      {...props}
    >
      {videos.map(video => (
        <SwiperSlide key={video.id}>
          <VideoPreview video={video} key={video.id} />
        </SwiperSlide>
      ))}
    </Carousel>

    {videos.length === 0 && <EmptyVideoPreview message='There is no video preview' />}
  </div>
)

export default VideoCarousel

import { FC } from 'react'
import cn from 'classnames'
import { Link, TagType } from '@ting/ting-ui-components'
import AppLogo from '@@ting/assets/img/app-logo.svg'
import Routes from '@@ting/enums/routes'

type Props = {
  className?: string
  tag?: TagType
  onClick?: () => void
}

export const HeaderLogo: FC<Props> = props => {
  const { className = '', tag = 'RouterLink', onClick = () => {} } = props

  return (
    <Link className={cn('header-logo', className)} tag={tag} href={Routes.Common.homePage} onClick={onClick}>
      <img src={AppLogo} alt='Ting' />
    </Link>
  )
}

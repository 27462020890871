/**
 * @function shortenBigNumbers
 * @description add M as Million and K as Kilo to numbers
 * @param num {number}
 */
export const shortenBigNumbers = (num: number) => {
  if (num > 1_000_000) {
    return `${num.toFixed(2)}M`
  }
  if (num > 1000) {
    return `${num.toFixed(2)}K`
  }
  return num
}

import { createContext, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { SelectValue } from '@@ting/molecules'
import { Option } from '@ting/ting-ui-components'
import { getCategoryNameOrID } from '@@ting/helpers/getCategoryNameOrID'
import { getUrlSearchParameter } from '@@ting/helpers/getUrlSearchParameter'

export type FilterParam = {
  languageFilter: Option
  durationFilter: SelectValue
  tagsFilter: SelectValue[]
  chosenCategory: string
}
export enum FilterParamActionTypes {
  setLanguage,
  setDuration,
  setTags,
  setCategory,
  clearLanguage,
  clearDuration,
  clearTags,
  setAll,
}
export type FilterParamActions =
  | { type: FilterParamActionTypes.setLanguage; payload: Option }
  | { type: FilterParamActionTypes.setDuration; payload: SelectValue }
  | { type: FilterParamActionTypes.setTags; payload: SelectValue[] }
  | { type: FilterParamActionTypes.setCategory; payload: string }
  | { type: FilterParamActionTypes.clearLanguage }
  | { type: FilterParamActionTypes.clearDuration }
  | { type: FilterParamActionTypes.clearTags }
  | { type: FilterParamActionTypes.setAll; payload: Partial<FilterParam> }

interface IFilterProvider {
  filterParams: FilterParam
  filterParamsActions: {
    changeLanguage?: (selected: Option) => void
    changeDuration?: (selected: SelectValue) => void
    handleSetTags?: (selected: SelectValue[]) => void
    changeCategory?: (selected: string) => void
    clearLanguage?: () => void
    clearDuration?: () => void
    clearTagsFilter?: () => void
    setAll?: (selected: Partial<FilterParam>) => void
  }
}

interface FilterStoreProps {
  children: React.ReactNode
  initialState?: FilterParam
}

const initialStateData: FilterParam = {
  languageFilter: [] as unknown as Option,
  durationFilter: null,
  tagsFilter: [],
  chosenCategory: getCategoryNameOrID({ name: getUrlSearchParameter('category') }),
}

const defaultContextValues = {
  filterParams: initialStateData,
  filterParamsActions: {},
}

const FilterContext = createContext<IFilterProvider>(defaultContextValues)

const FilterReducer = (filterParams: FilterParam, { type, payload }: any) => {
  switch (type) {
    case FilterParamActionTypes.setLanguage:
      return { ...filterParams, languageFilter: payload }
    case FilterParamActionTypes.setDuration:
      return { ...filterParams, durationFilter: payload }
    case FilterParamActionTypes.setTags:
      return { ...filterParams, tagsFilter: payload }
    case FilterParamActionTypes.setCategory:
      return { ...filterParams, chosenCategory: payload }
    case FilterParamActionTypes.clearDuration:
      return { ...filterParams, durationFilter: null }
    case FilterParamActionTypes.clearLanguage:
      return { ...filterParams, languageFilter: null }
    case FilterParamActionTypes.clearTags:
      return { ...filterParams, tagsFilter: [] }
    case FilterParamActionTypes.setAll:
      return { ...filterParams, ...payload }
    default:
      return filterParams
  }
}

const FilterStore: React.FC<FilterStoreProps> = ({ children, initialState = initialStateData }) => {
  const history = useHistory()
  const [filterParams, dispatch] = useReducer(FilterReducer, initialState)
  const changeLanguage = (payload: Option) => {
    dispatch({
      type: FilterParamActionTypes.setLanguage,
      payload,
    })
  }
  const changeDuration = (payload: SelectValue) => {
    dispatch({
      type: FilterParamActionTypes.setDuration,
      payload,
    })
  }
  const handleSetTags = (payload: SelectValue[]) => {
    dispatch({
      type: FilterParamActionTypes.setTags,
      payload,
    })
  }
  const changeCategory = (payload: string) => {
    history.push(`?category=${getCategoryNameOrID({ id: payload })}`)
    dispatch({
      type: FilterParamActionTypes.setCategory,
      payload,
    })
  }
  const clearLanguage = () => {
    dispatch({
      type: FilterParamActionTypes.clearLanguage,
    })
  }
  const clearDuration = () => {
    dispatch({
      type: FilterParamActionTypes.clearDuration,
    })
  }
  const clearTagsFilter = () => {
    dispatch({
      type: FilterParamActionTypes.clearTags,
    })
  }
  const setAll = (payload: Partial<FilterParam>) => {
    dispatch({
      type: FilterParamActionTypes.setAll,
      payload,
    })
  }

  const values = {
    filterParams,
    filterParamsActions: {
      changeLanguage,
      changeDuration,
      handleSetTags,
      changeCategory,
      clearLanguage,
      clearDuration,
      clearTagsFilter,
      setAll,
    },
  }

  return <FilterContext.Provider value={values}>{children}</FilterContext.Provider>
}

const useFilterParams = () => {
  const context = useContext(FilterContext)

  if (context === undefined) {
    throw new Error('useFilterParams must be used within a FilterContext')
  }

  return context
}

export { useFilterParams }
export default FilterStore

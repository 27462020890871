import { useTranslation } from 'react-i18next'
import { IPlayList } from '@@ting/models'
import { useDeletePlaylist } from '@@ting/services/api/query-hooks'
import { useSnackBar } from '@ting/ting-ui-components'

export const useRemovePlaylist = () => {
  const deletePlaylistMutation = useDeletePlaylist()
  const showSnackBar = useSnackBar()
  const { t } = useTranslation('playlist')

  const handleDeletePlaylist = async (playList: IPlayList) => {
    let message = ''
    try {
      await deletePlaylistMutation.mutateAsync({ playlistId: playList.id })
      message = t('removePlaylistSuccessfully', { playListName: playList.displayName })
    } catch (error) {
      message = t('failDeletingPlaylist')
    }
    showSnackBar({ message })
  }

  return { deletePlaylist: handleDeletePlaylist }
}

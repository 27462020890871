import { FC, useEffect, useState } from 'react'
import cn from 'classnames'
import { useDropzone } from 'react-dropzone'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { CloseCrossIcon, UploadIcon, FormTextField, Button } from '@ting/ting-ui-components'
import { LinkOutlinedIcon } from '@ting/ting-ui-components/dist/icons'
import configs from '@@ting/configs'
import { MobileDialogLayout } from '@@ting/molecules'
import './UploadVideo.scss'

type FormValues = {
  url: string
}

const importFormInitialValues: FormValues = {
  url: '',
}

const importFormSchema = Yup.object().shape({
  url: Yup.string().url('Invalid URL!'),
})

type Props = {
  className?: string
  initialUploadError?: string
  initialImportError?: string
  onSubmit: (params: { url: string; file: File }) => void
  onCancel: () => void
}

export const UploadVideo: FC<Props> = ({ className, initialUploadError, initialImportError, onSubmit, onCancel }) => {
  const { t } = useTranslation('uploadDialog')
  const [uploadError, setUploadError] = useState(initialUploadError || '')
  const { getRootProps, getInputProps } = useDropzone({
    accept: ['video/*', 'audio/*'],
    maxSize: configs.videos.maxUploadSize,
    multiple: false,
    onDrop: () => {
      setUploadError('')
    },
    onDropAccepted: (files: File[]) => {
      onSubmit({ file: files[0], url: null })
    },
    onDropRejected: files => {
      setUploadError(files[0].errors[0].message)
    },
  })

  const importFormInitialErrors = {
    url: initialImportError,
  }

  const handleImportURLSubmitted = async ({ url }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    await onSubmit({ url, file: null })
    setSubmitting(false)
  }

  // We might have an upload error during uploading the file,
  // so we should update the uploadError state here
  useEffect(() => {
    if (initialUploadError) {
      setUploadError(initialUploadError)
    }
  }, [initialUploadError])

  return (
    <MobileDialogLayout
      className={cn('upload-video', className)}
      contentClassName='upload-video-content'
      headerTitle='Upload video'
      headerIcon={<CloseCrossIcon width={20} height={20} />}
      onHeaderClick={onCancel}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='drag-drop-box'>
          <UploadIcon width={140} height={90} className='drag-drop-icon' />
          <span className='drag-drop-title'>
            {t('dragDropHere')}
            <span className='highlight'>{t('browseFiles')}</span>
          </span>
          <span className='drag-drop-subtitle'>{t('dragDropSubtitle')}</span>
          {uploadError && <span className='upload-error'>{uploadError}</span>}
        </div>
      </div>

      <Formik
        initialValues={importFormInitialValues}
        initialErrors={importFormInitialErrors}
        validationSchema={importFormSchema}
        onSubmit={handleImportURLSubmitted}
      >
        {({ handleSubmit, values, isSubmitting }) => (
          <form className='import-video-url-form' onSubmit={handleSubmit}>
            <div className='import-video-url-input-wrapper'>
              <FormTextField
                label={t('uploadFromUrl')}
                placeholder={t('enterUrl')}
                className='import-video-url-input'
                name='url'
                startIcon={<LinkOutlinedIcon />}
                v2
              />
              <Button type='brand' disabled={isSubmitting || !values.url} className='import-video-url-submit' v2>
                {isSubmitting ? t('uploading') : t('upload')}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </MobileDialogLayout>
  )
}

import { useState, useEffect } from 'react'
import { useDebounce } from './useDebounce'

export enum Breakpoint {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1440,
  XXXL = 1800,
  XXXXL = 2140,
}

const findBreakpoint = () =>
  [Breakpoint.XS, Breakpoint.SM, Breakpoint.MD, Breakpoint.LG, Breakpoint.XL, Breakpoint.XXL].reduce(
    (previous, current) => {
      if (current > window.innerWidth) {
        return previous
      }
      return Math.max(current, previous)
    },
    Breakpoint.XS
  )

export const useBreakpoint = (): Breakpoint => {
  const [breakpoint, setBreakpoint] = useState(findBreakpoint())
  const handleResize = useDebounce(() => setBreakpoint(findBreakpoint()), 200)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  return breakpoint
}

export const useBreakpointUp = (minSize: Breakpoint) => {
  const breakpoint = useBreakpoint()
  return minSize <= breakpoint
}

export const useBreakpointDown = (maxSize: Breakpoint) => {
  const breakpoint = useBreakpoint()
  return maxSize > breakpoint
}

export const useBreakpointBetween = (minSize: Breakpoint, maxSize: Breakpoint) => {
  const breakpoint = useBreakpoint()
  return minSize <= breakpoint && maxSize > breakpoint
}

export const useMobile = () => useBreakpointDown(Breakpoint.MD)

enum Common {
  homePage = '/',
  trending = '/trending',
  mostLiked = '/most-liked',
  subscriptions = '/subscriptions',
  search = '/search',
  walletModal = '*/walletModal',
  earnings = '/Earnings',
  moreInfo = '/moreInfo',
  cookiePolicy = '/',
  TermsOfSale = '/',
  PrivacyPolicy = '/',
}
export default Common

import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { SnackBarProvider } from '@ting/ting-ui-components'
import { Route, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { lazyComponent } from '@@ting/utils'
import App from './app'
import createStore, { history } from './redux/store'
import config from './configs'
import { setInterceptors } from './services/api/interceptors'
import '@@ting/utils/i18n'
import Routes from './enums/routes'

import './styles/index.scss'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://3cd821f1849f4373afb27234c03544cb@o151440.ingest.sentry.io/4504729078136832',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

export const store = createStore({})

setInterceptors()
// Create a react-query client
export const queryClient = new QueryClient({
  defaultOptions: config.defaultQueryConfig,
})

const EmbedPlayer = lazyComponent(
  () => import(/* webpackChunkName: 'EmbedPlayer', webpackPrefetch: true  */ '@@ting/containers/EmbedPlayer')
)
// StrictMode should be inside of ConnectedRouter: https://github.com/supasate/connected-react-router/issues/193
ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<h1>Something went wrong!</h1>}>
    <Provider store={store}>
      <HelmetProvider>
        <ConnectedRouter history={history} noInitialPop={false}>
          <StrictMode>
            <QueryClientProvider client={queryClient}>
              <SnackBarProvider>
                <Helmet titleTemplate='%s | TiNG Everything' />
                <Suspense fallback={null}>
                  <Switch>
                    <Route exact path={Routes.Video.embedPlayer} component={EmbedPlayer} />
                    <Route component={App} />
                  </Switch>
                </Suspense>
              </SnackBarProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </StrictMode>
        </ConnectedRouter>
      </HelmetProvider>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
)

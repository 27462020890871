import { FC } from 'react'
import cn from 'classnames'
import { SuccessIcon } from '@ting/ting-ui-components'
import './AddEditVideoFormSteps.scss'

type Props = {
  steps: number
  completedSteps: number
  className?: string
}

export const AddEditVideoFormSteps: FC<Props> = ({ className, steps, completedSteps }) => (
  <div className={cn('add-edit-video-form-steps', className)}>
    {Array(steps)
      .fill(null)
      .map((_, index) => {
        const completed = index < completedSteps
        return (
          <span
            key={`steps-${index.toString()}`}
            className={cn('step-circle', {
              completed,
              active: index === completedSteps,
            })}
          >
            {completed && <SuccessIcon className='stepCircle__icon' width={10} height={9} />}
          </span>
        )
      })}
  </div>
)

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { queryClient } from '@@ting/index'
import { Modal, Text, useSnackBar, Button } from '@ting/ting-ui-components'
import { slugify } from '@@ting/utils'
import { useHistoryState, useMobile } from '@@ting/utils/hooks'
import { logOutSideEffect } from '@@ting/services/authorization/sideEffects'
import { useSignUp, useUploadChannelAvatar, useUploadUserAvatar } from '@@ting/services/api/query-hooks'
import { selectErrors, selectIsSubmitting, selectSigningUp } from '@@ting/services/SignUpService/selectors'
import { cancelSignUp, resetSignUpErrors } from '@@ting/services/SignUpService/actions'
import Routes from '@@ting/enums/routes'
import { UserService } from '@@ting/services/api'
import { CloseFilledIcon } from '@ting/ting-ui-components/dist/icons'
import { Step2 } from './Step2'
import { Step1 } from './Step1'

import './SignUp.scss'

export const SignUp = () => {
  const [values, setValues] = useState({
    profileName: '',
    userName: '',
    termsChecked: false,
    channelName: '',
    channelHandle: '',
    avatarFile: null,
    channelAvatar: null,
  })
  const [fieldErrors, setFieldErrors] = useState({})

  const [step, stepHelpers] = useHistoryState(1)
  const isMobile = useMobile()
  const signingUp = useSelector(selectSigningUp)
  const isSubmitting = useSelector(selectIsSubmitting)
  const errors = useSelector(selectErrors)
  const showSnackBar = useSnackBar()
  const dispatch = useDispatch()
  const signUpMutation = useSignUp(dispatch)
  const history = useHistory()
  const updateAvatarMutation = useUploadUserAvatar()
  const uploadChannelAvatarMutation = useUploadChannelAvatar()

  const updateUserAvatar = (file: File) => updateAvatarMutation.mutateAsync({ avatarFile: file })

  const updateChannelAvatar = (channelHandle: string, avatarFile: File) =>
    uploadChannelAvatarMutation.mutateAsync({ channelHandle, avatarFile })

  useEffect(() => {
    if (errors.general || errors.userName || errors.profileName || errors.channelName || errors.channelHandle) {
      const jumpToFirstStep = errors.general || errors.userName || errors.profileName
      if (jumpToFirstStep) {
        setValues({
          ...values,
          channelName: '',
          channelHandle: '',
        })
      }
      if (jumpToFirstStep && errors.general) {
        showSnackBar({ message: errors.general })
      } else {
        setFieldErrors(errors)
      }
      stepHelpers.push(jumpToFirstStep ? 1 : 2)
      dispatch(resetSignUpErrors())
    }
  }, [errors, stepHelpers, step, dispatch, values, showSnackBar])

  const closeHandler = async () => {
    if (signingUp && step !== 3) {
      await logOutSideEffect(dispatch)
    } else {
      finishLogin()
    }
  }
  const finishLogin = () => {
    dispatch(cancelSignUp())
    history.push(Routes.Common.homePage)
  }

  const submitUserInfo = (newValues: typeof values) => {
    const { profileName } = { ...newValues }
    const userName = slugify(profileName, '_')
    setValues({ ...values, ...newValues, profileName, userName })
    stepHelpers.push(step + 1)
  }
  const submitChannelInfo = (newValues: typeof values) => {
    const { channelName, avatarFile, channelAvatar } = { ...values, ...newValues }
    const channelHandle = slugify(channelName, '_')
    setValues({ ...values, ...newValues, channelHandle })
    const registerPayload = { ...values, ...newValues, channelHandle }
    signUpMutation
      .mutateAsync(registerPayload)
      .then(
        () =>
          avatarFile &&
          updateUserAvatar(avatarFile).then(() => queryClient.invalidateQueries([UserService.getUserInfo.name]))
      )
      .then(() => channelAvatar && updateChannelAvatar(channelHandle, channelAvatar))
      .then(() => stepHelpers.push(step + 1))
      .catch(error => showSnackBar({ message: error?.message || error.error.response.data.error?.error || error }))
  }

  const handleBackClick = (newValues: typeof values) => {
    const { channelAvatar, channelName } = newValues
    const channelHandle = slugify(channelName, '_')
    setValues({ ...values, channelAvatar, channelHandle, channelName })
    stepHelpers.push(step - 1)
  }

  const TITLES: Record<number, string> = {
    1: 'Create profile',
    2: 'Create channel',
    3: `Welcome ${values.userName} 🥳`,
  }

  return (
    <Modal
      isOpen={signingUp}
      onClose={closeHandler}
      fullscreen={isMobile}
      shouldCloseOnOverlayClick={false}
      hideCloseButton
    >
      <div className='sign-up-container'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <Text size='h4' fontWeight='semiBold'>
            {TITLES[step]}
          </Text>

          <Button
            v2
            variant='primary'
            size='small'
            type='neutral'
            startIcon={<CloseFilledIcon width={20} height={20} />}
            onClick={closeHandler}
          />
        </div>
        {step !== 3 ? (
          <>
            <div className='sign-up-content'>
              <div hidden={step === 2}>
                <Step1
                  initialValues={values}
                  errors={fieldErrors}
                  onStep1Submit={submitUserInfo}
                  onAvatarChange={avatar => Promise.resolve(setValues({ ...values, avatarFile: avatar }))}
                />
              </div>
              <div hidden={step === 1}>
                <Step2
                  initialValues={values}
                  isSubmitting={isSubmitting}
                  errors={fieldErrors}
                  onBackClick={handleBackClick}
                  onStep2Submit={submitChannelInfo}
                  onChannelAvatarChange={avatar => Promise.resolve(setValues({ ...values, channelAvatar: avatar }))}
                />
              </div>
            </div>
            {/* <button className='logout-button' type='button' onClick={closeHandler}>
              <LogoutIcon />
              <Text size='small'>Logout</Text>
            </button> */}
          </>
        ) : (
          <>
            <Text className='welcome-text' type='sub' size='body-medium'>
              We're thrilled to have you join our community. Let's get started!
            </Text>
            <div className='action'>
              <Button v2 variant='primary' type='brand' className='continue-button' onClick={finishLogin}>
                Discover
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

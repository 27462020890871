import { Text } from '@ting/ting-ui-components'

export const NameRules = () => (
  <ul className='username-rules'>
    <li>
      <Text size='body-medium' type='sub' color='inherit'>
        Have to be unique
      </Text>
    </li>
    <li>
      <Text size='body-medium' type='sub' color='inherit'>
        Minimum 4 characters
      </Text>
    </li>
    <li>
      <Text size='body-medium' type='sub' color='inherit'>
        Can contain letters, numbers and underscore sign
      </Text>
    </li>
    <li>
      <Text size='body-medium' type='sub' color='inherit'>
        It’s possible to change this name after one year, choose wisely
      </Text>
    </li>
  </ul>
)
